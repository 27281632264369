import React, { useContext } from 'react'
import { useStyles } from './styles';
import { Empty } from 'antd';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { getHomePageUrl } from '../../ts/routing/routingConstants/AppUrls';
import { LocaleContext } from '../../ts/routing/LangRouter';


const NotFound = () => {
  const classes = useStyles()
  const { locale } = useContext(LocaleContext);

  return (
    <Box className={classes.container}>
      <Empty>
        <Box className={classes.top} >
          <Link to={getHomePageUrl(locale)} className={classes.goHome}>Go Home Page </Link>
        </Box>
      </Empty>
    </Box>
  )
};

export default NotFound;
