import React, { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { getHomePageUrl } from '../routingConstants/AppUrls'
import { LocaleContext } from '../LangRouter'
/* eslint-disable  no-unused-vars, no-debugger */

const PublicRouteGuard = ({ restricted, children, redirect }) => {
  const location = useLocation()
  const { locale } = useContext(LocaleContext)
  const { pathname } = useLocation()

  if (redirect) {
    return <Navigate replace to={redirect} />
  } else if (pathname === '/' || pathname === '/home') {
    return (
      <Navigate
        replace
        to={getHomePageUrl(locale)}
        state={{ from: location }}
      />
    )
  } else {
    return <>{children}</>
  }
}

export default PublicRouteGuard
PublicRouteGuard.propTypes = {
  restricted: PropTypes.any,
  children: PropTypes.any,
  redirect: PropTypes.any,
}
