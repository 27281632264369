import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background:
      "linear-gradient(109.51deg, #1A1C3A 0.56%, rgba(252, 252, 252, 0) 63.08%)",
  },
  goHome: {
    padding: "18px 48px",
    background: "#FCFCFC",
    border: "1px solid #1A1C3A",
    borderRadius: "2px 20px",
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "18px",
    lineheight: "21px",
    textTransform: "uppercase",
    gap: "10px",
    textDecoration: "none",
    color: "#1A1C3A",

    "&:hover": {
      color: "#1A1C3A",
      backgroundImage: `linear-gradient(90deg, rgba(26, 28, 58, 0.9), rgba(252, 252, 252, 0.8))`,
    },

    [theme.breakpoints.down("sm")]: {
      padding: "10px 12px",
      fontSize: "16px",
      lineHeight: "19px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "16px 36px",
      fontSize: "14px",
      lineHeight: "17px",
    },
  },
  top: {
    paddingTop: "10px",
  },
}));
