import React from 'react'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import moment from 'moment'
import { useStyles } from '../../pages/Appointment/details/styles'
/* eslint-disable  no-unused-vars, no-debugger */
const constructTimeRanges = (startTime, endTime) => {
  return `${startTime} - ${endTime}`
}

const ServiceTimePicker = ({
  errors,
  control,
  register,
  selectedDate,
  registeredAppointWithService,
}) => {
  const classes = useStyles()
  const usedTimeRanges = registeredAppointWithService.map((d) => {
    const startTime = moment(d.appointmentTime).format('HH:mm')
    const endTime = moment(d.appointmentTime)
      .add(d.durationInMinutes, 'minutes')
      .format('HH:mm')
    return constructTimeRanges(startTime, endTime)
  })
  const now = new Date()

  if (selectedDate.isSame(now, 'day') && now.getHours() > 18) {
    return null
  }

  const availableSlots = usedTimeRanges

  return (
    // <FormControl fullWidth style={{ border: '1px solid', borderRadius: '4px' }}>
    //   <InputLabel id="service-time-label">Time</InputLabel>
    <FormControl fullWidth className={classes.textfield}>
      <InputLabel htmlFor="Time" color="secondary">
        Time
      </InputLabel>
      <Controller
        name="time"
        control={control}
        render={({ field }) => (
          <>
            <Select
              {...register('time')}
              {...field}
              labelId="service-time-label"
              label="Time"
              IconComponent={WatchLaterIcon}
              MenuProps={{
                PaperProps: { sx: { maxHeight: 240 } },
              }}
              value={field?.value}
              onChange={(e) => field.onChange(e.target.value)}
            >
              {availableSlots.map((option) => (
                <MenuItem
                  key={option}
                  value={option}
                  style={{
                    background: option === field.value ? '#1A1C3A' : '',
                    color: option === field.value ? '#FCFCFC' : '#1A1C3A',
                  }}
                >
                  {option}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
      />
      {errors.time && (
        <FormHelperText style={{ color: 'red' }}>
          {errors.time.message}
        </FormHelperText>
      )}
    </FormControl>
  )
}

ServiceTimePicker.propTypes = {
  control: PropTypes.object.isRequired,
  time: PropTypes.array,
  duration: PropTypes.number,
  registeredAppointWithService: PropTypes.array,
  selectedDate: PropTypes.any,
  validate: PropTypes.any,
  errors: PropTypes.any,
  mode: PropTypes.any,
  register: PropTypes.any,
}

export default ServiceTimePicker
