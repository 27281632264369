import React, { useEffect, useState } from 'react'
import service1 from '../../assets/images/our_service_1.png'
import service2 from '../../assets/images/our_service_2.png'
import service3 from '../../assets/images/our_service_3.png'
import { useStyles } from './styles'
import { Box } from '@mui/material'
import ImgGallery from '../../components/ImgGallery'
import { AnimatedCarousel } from '../../components'
import { imageData } from '../../mocks/home'
import ReviewComponent from './Review'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

const Home = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [reviews, setReviews] = useState([])

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_KEY}coverbox/public/getAllCustomerSay`,
        )
        setReviews(response.data)
      } catch (error) {
        console.error('Error fetching reviews:', error)
      }
    }

    fetchReviews()
  }, [])

  // const fetchImageById = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_KEY}coverbox/public/getImageIdsByImageType`,
  //       {
  //         params: {
  //           imageType: 'PROCESSING',
  //         },
  //       },
  //     )

  //     const uniqueImageIds = Array.from(new Set(response.data))
  //     localStorage.setItem('processing', JSON.stringify(uniqueImageIds))
  //     setImageIds(uniqueImageIds)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // useEffect(() => {
  //   fetchImageById()
  // }, [])

  // useEffect(() => {
  //   const getImages = async () => {
  //     try {
  //       const newImageObjects = []
  //       for (const el of imageIds) {
  //         const res = await axios.get(
  //           `${process.env.REACT_APP_API_KEY}coverbox/public/files/other/image`,
  //           {
  //             params: {
  //               imageId: el,
  //             },
  //             responseType: 'blob',
  //           },
  //         )
  //         const imageBlob = res.data
  //         const imageUrl = URL.createObjectURL(imageBlob)
  //         newImageObjects.push({ id: el, src: imageUrl })
  //       }

  //       setImageObjects(newImageObjects)
  //     } catch (error) {
  //       console.error(error)
  //     }
  //   }

  //   getImages()
  // }, [imageIds])

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.padding}>
          <AnimatedCarousel
            data={imageData}
            btn_name="Contact"
            // content={t('home.animation_content')}
          />
        </Box>
        <Box className={classes.service_wrapper}>
          <Box className={classes.titleWrapper}>
            {/* Our */}
            {t('common.our')}
            <span className={classes.highlight}>
              {/* Services */}
              {t('our_services.services')}
            </span>
          </Box>
          <Box className={classes.serviceContainer}>
            <Box
              className={classes.service_card}
              sx={{
                backgroundImage: `url(${service1})`,
              }}
            >
              <span className={classes.service_text}>
                {t('our_services.first_service')}
              </span>
            </Box>
            <Box
              className={classes.service_card}
              sx={{ backgroundImage: `url(${service2})` }}
            >
              <span className={classes.service_text}>
                {t('our_services.second_service')}
              </span>
            </Box>
            <Box
              className={classes.service_card}
              sx={{ backgroundImage: `url(${service3})` }}
            >
              <span className={classes.service_text}>
                {t('our_services.third_service')}
              </span>
            </Box>
          </Box>
        </Box>
        <Box className={classes.processingWrapper}>
          <>
            <Box className={classes.titleWrapper}>
              {t('processing_time.processing')}
              <span className={classes.highlight}>
                {t('processing_time.time')}
              </span>
            </Box>
            <ImgGallery />
          </>
        </Box>

        {reviews.length > 0 && (
          <Box className={classes.customerWrapper}>
            <Box className={classes.customertitle}>
              {t('customers_say.customers')}
              <span className={classes.highlight}>
                {t('customers_say.say')}
              </span>
            </Box>
            <Box className={classes.review_container}>
              <ReviewComponent reviews={reviews} />
            </Box>
          </Box>
        )}
      </Box>
    </>
  )
}

export default Home
