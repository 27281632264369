import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "1077px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "475px",
      // padding: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "336px",
      // padding: "0px",
    },
  },
  title: {
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "22px",
    lineHeight: "26px",
    color: "#1A1C3A",
    [theme.breakpoints.down("lg")]: {
      padding: "10px",
    },
  },
  text_wrapper: {
    width: "440px",
    marginLeft: "15px",
    [theme.breakpoints.down("lg")]: {
      width: "600px",
    },

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  text: {
    fontFamily: "Rubik",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#1A1C3A",

    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      lineHeight: "19px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "19px",
      // width: '310px',
    },
  },
  cost: {
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "21px",
    textTransform: "uppercase",
    color: " #1A1C3A",
    marginTop: "10px",

    [theme.breakpoints.down("md")]: {
      fontWeight: "550",
      fontSize: "16px",
      lineHeight: "19px",
    },
  },
  label: {
    display: "flex",
    marginTop: "30px",
    alignItems: "flex-start",
    // width: "440px",
    // [theme.breakpoints.down("lg")]: {
    //   maxWidth: "440px",
    //   width: "100%",
    //   marginTop: "20px",
    // },
    [theme.breakpoints.down("md")]: {
      maxWidth: "340px",
      width: "100%",
      marginTop: "5px",

      // justifyContent: 'center',
      // alignItems: 'center',
    },
  },

  booking_title: {
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "24px",
    color: "#1A1C3A",
  },
  // datepicker: {
  "&.MuiStack-root": {
    background: "red!important",
    width: "100%!important",
  },
  // },
  booking_details: {
    display: "flex",
    alignItems: "center",
    columnGap: "20px",
    [theme.breakpoints.down("lg")]: {
      padding: "10px",
    },
  },

  car: {
    width: "300px",
    height: "200px",
    objectFit: "contain",

    [theme.breakpoints.down("md")]: {
      width: "170px",
      height: "95px",
    },
  },
  textfield: {
    // marginTop: "20px !important",

    "& label.Mui-focused": {
      color: "inherit",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "inherit",
        border: "1px solid black!important",
      },
      "&:hover fieldset": {
        borderColor: "inherit",
      },
      "&.Mui-focused fieldset": {
        borderColor: "inherit",
      },
      "&.Mui-error fieldset": {
        borderColor: "red !important",
      },
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: "unset",
    },
  },

  errorField: {
    marginTop: "20px !important",

    "& label.Mui-focused": {
      color: "red !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red !important",
      },
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: "unset",
    },
  },
  error: {
    color: "red !important",
  },
  // label: {
  //   color: "red!important",
  // },
  outlinedInput: {
    "&$error input": {
      borderColor: "red!important",
    },
    border: "1px solid black!important",
  },
  errorTextField: {
    marginTop: "20px !important",
    "& .MuiInputLabel-root": {
      color: "black!important",
    },
    "& .MuiInputLabel-shrink": {
      color: "black!important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid black!important",
      },
    },
    "&.Mui-error": {
      "& .MuiInputLabel-root": {
        color: "red!important",
      },
      "& .MuiInputLabel-shrink": {
        color: "red!important",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          border: "1px solid red!important",
        },
      },
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: "unset",
    },
  },

  checkbox_wrapper_empty: {
    height: "100%",
    width: "1100px",
    flexWrap: "wrap",
    display: "flex",
    justifyContent: "flex-start",
    rowGap: "20px",
    flexDirection: "column",
    overflowY: "auto",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      width: "100%",
      minHeight: "unset",
      height: "100%",
    },
  },

  checkbox_wrapper: {
    width: "1100px",
    flexWrap: "wrap",
    display: "flex",
    justifyContent: "flex-start",
    minHeight: "500px",
    rowGap: "20px",
    //flexDirection: "column",
    overflowY: "auto",

    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      width: "100%",
      minHeight: "unset",
      height: "100%",
    },
  },
  checkbox_container: {
    display: "flex",
    [theme.breakpoints.down("lg")]: {
      padding: "25px",
    },

    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      flexWrap: "wrap",
      // justifyContent: "flex-start",
      width: "100%",
      padding: "10px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "10px",
    },
  },
  checkbox: {
    display: "block",
    color: "white",

    [theme.breakpoints.down("lg")]: {
      padding: "0px!important",
    },
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "end",
  },
  dateTime_wrapper: {
    maxWidth: "571px",
    width: "100%",
    left: "195px",
    top: "923px",
    background: "#FCFCFC",
    padding: "60px",
    margin: "40px",
    [theme.breakpoints.down(500)]: {
      margin: "0px auto",
      maxWidth: "335px",
      padding: "30px 15px 30px",
    },
  },
  // textfield: {
  //   marginTop: "20px !important",
  //   "& .MuiInputLabel-root": { color: "black !important" },
  //   "& .MuiOutlinedInput-root": {
  //     "& > fieldset": { border: "1px solid black!important" },
  //   },
  //   [theme.breakpoints.down("lg")]: {
  //     marginTop: "unset",
  //   },
  // },

  name: {
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "20px",
    color: "#1A1C3A",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  },
}));

// import { makeStyles } from "@mui/styles";

// export const useStyles = makeStyles((theme) => ({
//   container: {
//     width: "100%",
//     maxWidth: "1077px",
//     [theme.breakpoints.down("md")]: {
//       maxWidth: "475px",
//       // padding: "10px",
//     },
//     [theme.breakpoints.down("sm")]: {
//       maxWidth: "336px",
//       // padding: "0px",
//     },
//   },
//   title: {
//     fontFamily: "Rubik",
//     fontWeight: "700",
//     fontSize: "22px",
//     lineHeight: "26px",
//     color: "#1A1C3A",
//     [theme.breakpoints.down("lg")]: {
//       padding: "10px",
//     },
//   },
//   text_wrapper: {
//     width: "440px",
//     marginLeft: "15px",
//   },
//   text: {
//     fontFamily: "Rubik",
//     fontWeight: "400",
//     fontSize: "18px",
//     lineHeight: "21px",
//     color: "#1A1C3A",

//     [theme.breakpoints.down("md")]: {
//       fontSize: "16px",
//       lineHeight: "19px",
//     },
//     [theme.breakpoints.down("sm")]: {
//       fontSize: "16px",
//       lineHeight: "19px",
//       // width: '310px',
//     },
//   },
//   cost: {
//     fontFamily: "Rubik",
//     fontWeight: "700",
//     fontSize: "18px",
//     lineHeight: "21px",
//     textTransform: "uppercase",
//     color: " #1A1C3A",
//     marginTop: "10px",

//     [theme.breakpoints.down("md")]: {
//       fontWeight: "500",
//       fontSize: "16px",
//       lineHeight: "19px",
//     },
//   },
//   label: {
//     display: "flex",
//     marginTop: "30px",
//     [theme.breakpoints.down("md")]: {
//       maxWidth: "340px",
//       width: "100%",
//       // justifyContent: 'center',
//       // alignItems: 'center',
//     },
//   },

//   booking_title: {
//     fontFamily: "Rubik",
//     fontWeight: "700",
//     fontSize: "20px",
//     lineHeight: "24px",
//     color: "#1A1C3A",
//   },
//   // datepicker: {
//   "&.MuiStack-root": {
//     background: "red!important",
//     width: "100%!important",
//   },
//   // },
//   booking_details: {
//     display: "flex",
//     alignItems: "center",
//     columnGap: "20px",
//     [theme.breakpoints.down("lg")]: {
//       padding: "10px",
//     },
//   },

//   car: {
//     [theme.breakpoints.down("md")]: {
//       width: "170px",
//       height: "95px",
//     },
//   },
//   checkbox_wrapper: {
//     width: "1100px",
//     flexWrap: "wrap",
//     display: "flex",
//     justifyContent: "flex-start",
//     height: "100%",
//     rowGap: "20px",
//     alignItems: "center",
//     [theme.breakpoints.down("lg")]: {
//       flexDirection: "column",
//       width: "100%",
//     },
//   },
//   checkbox_container: {
//     padding: "25px",
//     display: "flex",
//     [theme.breakpoints.down("lg")]: {
//       flexDirection: "column",
//       flexWrap: "wrap",
//     },
//     [theme.breakpoints.down("md")]: {
//       padding: "0px",
//     },
//   },
//   checkbox: {
//     display: "block",
//     color: "white",
//     [theme.breakpoints.down("lg")]: {
//       padding: "0px!important",
//     },
//   },
//   flex: {
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "space-between",
//     alignItems: "end",
//   },
//   dateTime_wrapper: {
//     maxWidth: "571px",
//     width: "100%",
//     left: "195px",
//     top: "923px",
//     background: "#FCFCFC",
//     padding: "60px",
//     margin: "40px",
//     [theme.breakpoints.down(500)]: {
//       margin: "0px auto",
//       maxWidth: "335px",
//       padding: "30px 15px 30px",
//     },
//   },
//   textfield: {
//     marginTop: "20px !important",
//     "& .MuiInputLabel-root": { color: "black !important" },
//     "& .MuiOutlinedInput-root": {
//       "& > fieldset": { border: "1px solid black!important" },
//     },
//     [theme.breakpoints.down("lg")]: {
//       marginTop: "unset",
//     },
//   },

//   name: {
//     fontFamily: "Rubik",
//     fontWeight: "700",
//     fontSize: "20px",
//     color: "#1A1C3A",
//   },
// }));
