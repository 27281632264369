import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import car from '../../../assets/images/tintedGlass/details_car.png'
import DatePicker from '../../../components/DateTimePicker/container/DatePicker'
import './styles.css'
import dateIcon from '../../../assets/icons/date.svg'
/* eslint-disable  no-unused-vars, no-debugger */

import { LocaleContext } from '../../../ts/routing/LangRouter'
import { Controller } from 'react-hook-form'
import { format } from 'date-fns'
import ServiceTimePicker from '../../../components/ServiceTimePicker'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
/* eslint-disable  no-unused-vars, no-debugger */

const BasicCheckbox = ({ service, checked, onChange }) => {
  return (
    <Checkbox
      sx={{ p: 0 }}
      checked={checked}
      onChange={(e) => onChange(service.id, e.target.checked)}
    />
  )
}

BasicCheckbox.propTypes = {
  service: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  control: PropTypes.any,
  setValue: PropTypes.any,
}

const Details = ({
  additionalServices,
  errors,
  onErrorsChange,
  serverError,
  setError,
  control,
  setValue,
  selectedServices,
  setSelectedServices,
  totalDuration,
  setTotalDuration,
  tintedGlass,
  tintGlassId,
  selectedDate,
  selectedTime,
  register,
  registeredAppoint,
  registeredAppointWithService,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { locale, setLocale } = useContext(LocaleContext)
  const [lang, setLang] = useState(locale)
  const [showDate, setShowDate] = useState(false)
  const location = useLocation()
  const { state } = location
  const [image, setImage] = useState('')

  useEffect(() => {
    if (
      serverError &&
      (serverError.startsWith('DISABLED IN SATURDAY AND SUNDAY') ||
        serverError.startsWith('Appointment already exists'))
    ) {
      setError('date', {
        type: 'custome',
        message: serverError,
      })
    }
  }, [serverError])

  useEffect(() => {
    if (
      serverError &&
      (serverError.startsWith('Appointment time is before date now') ||
        serverError.startsWith('Appointment time can not be before now'))
    ) {
      setError('time', {
        type: 'custome',
        message: serverError,
      })
    }
  }, [serverError])

  useEffect(() => {
    setLang(locale)
  }, [locale])

  useEffect(() => {
    onErrorsChange(errors)
  }, [errors, onErrorsChange])

  const handleShowDate = () => setShowDate((show) => !show)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleCheckboxChange = (serviceId, isChecked) => {
    let updatedServices

    if (isChecked) {
      updatedServices = [...selectedServices, serviceId]
      localStorage.getItem('selectedServices')
    } else {
      updatedServices = selectedServices.filter((id) => id !== serviceId)
      localStorage.removeItem('selectedServices')
    }
    setSelectedServices(updatedServices)

    let duration = updatedServices.reduce((total, serviceId) => {
      const service = additionalServices.find(
        (service) => service.id === serviceId,
      )
      return service ? total + service.durationInMinutes : total
    }, 0)

    setTotalDuration(duration)
  }

  useEffect(() => {
    const storedSelectedServices = localStorage.getItem('selectedServices')
    if (storedSelectedServices) {
      localStorage.removeItem('selectedServices')
      setSelectedServices([])
    }
  }, [])

  function hasTimeError() {
    if (
      (serverError &&
        serverError.startsWith('Appointment time is before date now')) ||
      serverError === 'Appointment time can not be before now'
    ) {
      return 'time error'
    }
  }

  function hasDateError() {
    if (
      (serverError && serverError.startsWith('Appointment already exists')) ||
      serverError === 'DISABLED IN SATURDAY AND SUNDAY'
    ) {
      return 'date error'
    }
  }

  useEffect(() => {
    const fetchImage = async (id) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_KEY}coverbox/public/files/tintglass/image`,
          {
            params: {
              tintGlassServiceId: id,
            },
            responseType: 'blob',
          },
        )

        const imageBlob = response.data
        const imageUrl = URL.createObjectURL(imageBlob)
        setImage(imageUrl)
      } catch (error) {
        console.error(error)
      }
    }
    fetchImage(tintGlassId ? tintGlassId : state?.tintedGlass?.id)
  }, [])

  const selectedTintGlass = tintedGlass?.find((item) => item.id == tintGlassId)
  function calculateDiscountPrice(originalPrice, discountPercentage) {
    const discountAmount = (originalPrice * discountPercentage) / 100
    const finalPrice = originalPrice - discountAmount

    return finalPrice % 1 === 0 ? finalPrice.toFixed(0) : finalPrice.toFixed(2)
  }

  return (
    <>
      <Box className={classes.container}>
        <p className={classes.title}>{t('appointment.booking_details')}</p>

        <Box className={classes.booking_details}>
          <img src={image || car} alt="car" className={classes.car} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              rowGap: '10px',
            }}
          >
            <Box className={classes.name}>
              {state?.model_en && lang == 'en'
                ? state?.model_en
                : state?.model_fr}
              {selectedTintGlass && lang == 'en'
                ? selectedTintGlass?.model_en
                : selectedTintGlass?.model_fr}
            </Box>
            <Box className={classes.cost}>
              {selectedTintGlass
                ? selectedTintGlass.discountPrice
                  ? calculateDiscountPrice(
                      selectedTintGlass.price,
                      selectedTintGlass.discountPrice,
                    )
                  : selectedTintGlass.price
                : state?.discountPrice
                ? state?.amount
                : state?.price}{' '}
              CHF
            </Box>
          </Box>
        </Box>
        <Box
          className={
            additionalServices.length > 2
              ? classes.checkbox_wrapper
              : classes.checkbox_wrapper_empty
          }
        >
          {additionalServices?.map((service) => (
            <Box className={classes.checkbox_container} key={service.id}>
              <label className={classes.label}>
                <BasicCheckbox
                  service={service}
                  checked={selectedServices.includes(service.id)}
                  onChange={handleCheckboxChange}
                  control={control}
                  setValue={setValue}
                />
                <Box className={classes.text_wrapper}>
                  <Box className={classes.text}>
                    {lang === 'en'
                      ? service.description_en
                      : service.description_fr}
                  </Box>
                  <Box className={classes.cost}>
                    {service
                      ? service?.discountPrice
                        ? calculateDiscountPrice(
                            service?.price,
                            service?.discountPrice,
                          )
                        : service?.price
                      : state?.discountPrice
                      ? state?.amount
                      : state?.price}{' '}
                    CHF
                    {/* {`${service.price} CHF`} */}
                  </Box>
                </Box>
              </label>
            </Box>
          ))}
        </Box>
      </Box>

      <Box className={classes.flex}>
        <Box className={classes.dateTime_wrapper}>
          <p className={classes.booking_title}>
            {t('appointment.booking_date')}
          </p>

          <Box sx={{ marginTop: '20px' }}>
            <FormControl fullWidth className={classes.textfield}>
              <InputLabel htmlFor="date" color="secondary">
                Date
              </InputLabel>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    variant="outlined"
                    required
                    className={classes.textfield}
                    error={errors.date ? true : false}
                    fullWidth
                    type="text"
                    color="secondary"
                    id="date"
                    inputProps={{ min: 0, readOnly: true }}
                    // inputProps={{ min: 0 }}
                    value={format(field?.value, 'dd/MM/yyyy')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowDate}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          <img src={dateIcon} alt="date" />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Date"
                    onWheel={(event) => event.target.blur()}
                  />
                )}
              />
            </FormControl>

            {hasDateError() && (
              <span className={classes.error}>{errors.date?.message}</span>
            )}
            <>
              {showDate && (
                <DatePicker
                  setValue={setValue}
                  handleShowDate={handleShowDate}
                />
              )}
            </>
          </Box>
          <Box
            sx={{
              marginTop: '20px',
              width: '100%',
            }}
          >
            <ServiceTimePicker
              errors={errors}
              register={register}
              control={control}
              duration={totalDuration}
              selectedDate={selectedDate}
              time={registeredAppoint}
              registeredAppointWithService={registeredAppointWithService}
            />
            {/* {errors && (
              <span className={classes.error}>{errors.time?.message}</span>
            )}
            <span style={{ color: 'red' }}>{validate}</span> */}
          </Box>
        </Box>
      </Box>
    </>
  )
}

Details.propTypes = {
  register: PropTypes.any,
  onErrorsChange: PropTypes.any,
  errors: PropTypes.any,
  handleSubmit: PropTypes.any,
  serverError: PropTypes.any,
  setError: PropTypes.func,
  control: PropTypes.any,
  setValue: PropTypes.func,
  validate: PropTypes.any,
  totalDuration: PropTypes.any,
  setTotalDuration: PropTypes.func,
  durationInMinutes: PropTypes.any,
  tintedGlass: PropTypes.any,
  tintGlassId: PropTypes.any,
  selectedDate: PropTypes.any,
  selectedTime: PropTypes.any,
  registeredAppoint: PropTypes.any,
  registeredAppointWithService: PropTypes.any,
  additionalServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      description_en: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      durationInMinutes: PropTypes.number.isRequired,
    }),
  ),
  selectedServices: PropTypes.any,
  setSelectedServices: PropTypes.func,
}

export default Details
