export function generateDays(date) {
  var day = 1;
  var dateArr = [];

  var month = date.getMonth() + 1;
  var firstDay = new Date(date.getFullYear(), date.getMonth());
  var startingDay = firstDay.getDay();
  var monthLength = getMonthLength(month, date.getFullYear());

  var nextDate = 1;
  var lastMonthLength = getMonthLength(month - 1, date.getFullYear());

  var dateRow = [];

  for (var i = 0; i < 9; i++) {
    dateRow = [];
    for (var j = 0; j <= 6; j++) {
      let dateCell = null;
      if (day <= monthLength && (i > 0 || j >= startingDay)) {
        dateCell = day;
        day++;
      }

      if (dateCell != null) {
        const dateObj = new Date(date.getFullYear(), month - 1, dateCell);
        const isWeekend = dateObj.getDay() === 0 || dateObj.getDay() === 6;
        const isValid = !isWeekend && dateObj >= new Date(); // Check if it's not a weekend and not before today
        dateRow.push({
          day: dateCell,
          date: dateObj,
          isValid: isValid,
          isWeekend: isWeekend,
        });
      } else if (dateCell == null && day <= 1) {
        dateRow.unshift({
          day: lastMonthLength,
          date: new Date(0),
          isValid: false,
        });
        lastMonthLength = lastMonthLength - 1;
      } else if (dateCell == null && day > 20) {
        dateRow.push({ day: nextDate, date: new Date(0), isValid: false });
        nextDate = nextDate + 1;
      }
    }
    if (day > monthLength) {
      dateArr.push(dateRow);
      break;
    } else {
      dateArr.push(dateRow);
    }
  }
  return dateArr;
}

function getMonthLength(month, year) {
  return new Date(year, month, 0).getDate();
}
