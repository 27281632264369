import React, { useEffect, useMemo } from 'react'
import { Box, Stack, Tab, Tabs } from '@mui/material'
import clsx from 'clsx'
import { useState } from 'react'
import { useStyles } from './styles'
import TabPanel from './TabPanel'
import Information from './information/Information'
import Confirmation from './confirmation/Confirmation'
import Details from './details/Details'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { yupResolver } from '@hookform/resolvers/yup'
import { appointmentSchema } from '../../utils/formValidation'
import moment from 'moment'
import { api } from '../../configs'
import { useLocation } from 'react-router-dom'
import Cookies from 'universal-cookie'
/* eslint-disable  no-unused-vars, no-debugger */
const Appointment = () => {
  const classes = useStyles()
  const [currentTab, setCurrentTab] = useState(0)
  const { t } = useTranslation()
  const [additionalService, setAdditionalService] = useState([])
  const [tintedGlass, setTintedGlass] = useState([])
  const [serverError, setServerError] = useState('')
  const [childErrors, setChildErrors] = useState(null)
  const [open, setOpen] = useState(false)
  const [validate, setValidate] = useState('')
  const [totalDuration, setTotalDuration] = useState(0)
  const [selectedServices, setSelectedServices] = useState([])
  const location = useLocation()
  const [specialCars, setSpecialCars] = useState([])
  const [registeredAppoint, setRegisteredAppoint] = useState([])
  const [
    registeredAppointWithService,
    setRegisteredAppointWithService,
  ] = useState([])

  const { state } = location

  const nextButtonClassName = clsx(
    classes.btn,
    currentTab === 0 ? classes.infoTab : '',
    currentTab === 1 ? classes.detailTab : '',
    currentTab === 2 ? classes.confirmTab : '',
  )
  const setTabValue = (idx) => {
    setCurrentTab(idx)
  }

  const handleChildErrorsChange = (errors) => {
    setChildErrors(errors)
  }
  const {
    register,
    handleSubmit,
    setError,
    watch,
    clearErrors,
    control,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      date: new Date(),
      time: '',
      durationInMinutes: 0,
      tintGlassId: '',
      additionalServiceIds: [],
      // frontPositionPercentage: 0,
      // backPositionPercentage: 0,
    },
    mode: 'all',
    resolver: yupResolver(appointmentSchema),
  })
  const dateWatcher = watch('date')
  const timeWatcher = watch('time')
  const durationInMinutes = watch('durationInMinutes')
  const firstName = watch('firstName')
  const lastName = watch('lastName')
  const email = watch('email')
  const phoneNumber = watch('phoneNumber')
  // const frontPositionPercentage = watch('frontPositionPercentage')
  // const backPositionPercentage = watch('backPositionPercentage')
  const tintGlassId = watch('tintGlassId')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_KEY}coverbox/public/getAllTintGlassServiceDTO`,
          {
            params: {
              page: 0,
              size: 500,
            },
          },
        )

        setSpecialCars(data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (
      serverError &&
      dateWatcher &&
      (serverError.startsWith('DISABLED IN SATURDAY AND SUNDAY') ||
        serverError.startsWith('Appointment already exists'))
    ) {
      clearErrors('date')
    }
  }, [dateWatcher, serverError])

  useEffect(() => {
    if (
      serverError &&
      timeWatcher &&
      serverError.startsWith('Appointment time is before date now')
    ) {
      clearErrors('time')
    }
  }, [timeWatcher, serverError])

  const selectedDate = useMemo(() => moment(dateWatcher, 'YYYY-MM-DD'), [
    dateWatcher,
  ])

  const selectedTime = useMemo(() => moment(timeWatcher, 'HH:mm'), [
    timeWatcher,
  ])
  const combinedDateTime = useMemo(() => {
    return selectedDate.set({
      hour: selectedTime.hour(),
      minute: selectedTime.minute(),
      second: 0,
    })
  }, [selectedDate, selectedTime])

  const cookies = new Cookies()
  const sendForm = async () => {
    try {
      // let appointmentTime = dateWatcher.setHours(timeWatcher.split(':')[0])
      // appointmentTime: moment(appointmentTime).format('YYYY-MM-DD HH:mm:ss'),
      const xsrfToken = cookies.get('XSRF-TOKEN')
      const requestBody = {
        customer: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          phoneNumber: phoneNumber?.replaceAll(/[+() -]/g, ''),
        },
        appointmentTime: combinedDateTime.format('YYYY-MM-DD HH:mm:ss'),
        durationInMinutes:
          totalDuration !== 0 ? totalDuration : durationInMinutes,
        tintGlassId: tintGlassId ? tintGlassId : state?.tintedGlass?.id,
        additionalServiceIds: selectedServices,
        // frontPositionPercentage: frontPositionPercentage,
        // backPositionPercentage: backPositionPercentage,
      }

      const response = await api.post(
        'coverbox/public/appointment/appoint',
        requestBody,
        {
          headers: {
            'X-XSRF-TOKEN': xsrfToken,
          },
        },
      )

      if (response.status) {
        setOpen(true)

        setSelectedServices([])
        setTotalDuration(0)
        localStorage.removeItem('selectedServices')
        reset({
          date: new Date(),
          time: '',
        })
      } else {
        console.log(response.status)
      }
    } catch (error) {
      if (error.response) {
        setServerError(error.response.data.message)
      } else {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    const getAdditionalService = () => {
      return axios.get(
        `${process.env.REACT_APP_API_KEY}coverbox/public/getAdditionalServiceDTO`,
        {
          params: {
            page: 0,
            size: 500,
          },
        },
      )
    }

    const getAdditionalServiceById = (additionalServiceId) => {
      return axios.get(
        `${process.env.REACT_APP_API_KEY}coverbox/public/getAdditionalServiceDTOById`,
        {
          params: {
            additionalServiceId,
          },
        },
      )
    }

    getAdditionalService()
      .then((response) => {
        const imageData = response.data
        localStorage.setItem('additionalService', JSON.stringify(imageData))
        const promises = imageData.map((item) =>
          getAdditionalServiceById(item.id),
        )
        return Promise.all(promises)
      })
      .then((responses) => {
        const imagesData = responses.map((response) => response.data)
        setAdditionalService(imagesData)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    const getAllTintGlassService = () => {
      return axios.get(
        `${process.env.REACT_APP_API_KEY}coverbox/public/getAllTintGlassServiceDTO`,
        {
          params: {
            page: 0,
            size: 500,
          },
        },
      )
    }

    const getTintGlassServiceDTOById = (tintGlassServiceId) => {
      return axios.get(
        `${process.env.REACT_APP_API_KEY}coverbox/public/getTintGlassServiceDTOById`,
        {
          params: {
            tintGlassServiceId,
          },
        },
      )
    }

    getAllTintGlassService()
      .then((response) => {
        const imageData = response.data
        localStorage.setItem('specialcars', JSON.stringify(imageData))
        const promises = imageData.map((item) =>
          getTintGlassServiceDTOById(item.id),
        )
        return Promise.all(promises)
      })
      .then((responses) => {
        const imagesData = responses.map((response) => response.data)
        setTintedGlass(imagesData)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    const getAllAppointmentsByDate = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_KEY}coverbox/public/appointment/getAllAppointmentsByDate`,
          {
            params: {
              year: selectedDate.format('YYYY'),
              month: selectedDate.format('MM'),
              day: selectedDate.format('DD'),
            },
          },
        )
        const data = response.data
        setRegisteredAppoint([...data])
      } catch (error) {
        console.log(error)
      }
    }

    getAllAppointmentsByDate()
  }, [selectedDate])

  useEffect(() => {
    const getAllAvailableAppointmentDates = async () => {
      try {
        const additionalServicesIds = selectedServices
          .map((service) => `additionalServicesIds=${service}`)
          .join('&')

        const url = `${
          process.env.REACT_APP_API_KEY
        }coverbox/public/appointment/getAllAvailableAppointmentDates?year=${selectedDate.format(
          'YYYY',
        )}&month=${selectedDate.format('MM')}&day=${selectedDate.format(
          'DD',
        )}&${additionalServicesIds}`

        const response = await axios.get(url)
        const data = response.data
        setRegisteredAppointWithService([...data])
      } catch (error) {
        console.log(error)
      }
    }

    getAllAvailableAppointmentDates()
  }, [selectedDate, selectedServices])

  const handleNext = async () => {
    if (currentTab === 0 && !errors.time) {
      setCurrentTab((prevTab) => prevTab + 1)
    } else if (currentTab === 1) {
      handleSubmit(() => setCurrentTab((prevTab) => prevTab + 1))()
    } else if (currentTab === 2) {
      handleSubmit(sendForm)()
    }
  }

  return (
    <Box className={clsx(classes.container, classes.appointment)}>
      <Box sx={{ display: 'contents' }}>
        <Tabs
          activekey={currentTab}
          value={currentTab}
          onChange={(e, idx) => setTabValue(idx)}
          aria-label="secondary tabs example"
          variant={'scrollable'}
          textColor="secondary"
          indicatorColor="secondary"
          className={classes.tabs}
          sx={{
            display: 'flex !important',
            justifyContent: 'center!important',
          }}
          TabIndicatorProps={{
            sx: { backgroundColor: 'unset !important' },
          }}
        >
          <Tab
            eventkey={0}
            label={t('appointment.details')}
            className={clsx(
              classes.tabTitle,
              classes.detailTab,
              currentTab === 0 ? classes.activeTab : classes.prevTab,
            )}
          />
          <Tab
            eventkey={1}
            label={t('appointment.information')}
            className={clsx(
              classes.tabTitle,
              classes.infoTab,
              currentTab === 1
                ? classes.activeTab
                : currentTab === 0
                ? classes.nextTab
                : classes.prevTab,
            )}
          />
          <Tab
            eventkey={2}
            label={t('appointment.confirmation')}
            className={clsx(
              classes.tabTitle,
              classes.confirmTab,
              currentTab === 2 ? classes.activeTab : classes.nextTab,
            )}
          />
        </Tabs>
        <Box>
          <TabPanel value={currentTab} index={0}>
            <Details
              setValue={setValue}
              setError={setError}
              additionalServiceIds={selectedServices}
              serverError={serverError}
              additionalServices={additionalService}
              tintedGlass={tintedGlass}
              tintGlassId={tintGlassId}
              errors={errors}
              register={register}
              handleNext={handleNext}
              handleSubmit={handleSubmit}
              onErrorsChange={handleChildErrorsChange}
              control={control}
              selectedServices={selectedServices}
              setSelectedServices={setSelectedServices}
              validate={validate}
              totalDuration={totalDuration}
              setTotalDuration={setTotalDuration}
              durationInMinutes={durationInMinutes}
              selectedDate={selectedDate}
              selectedTime={selectedTime}
              registeredAppoint={registeredAppoint}
              registeredAppointWithService={registeredAppointWithService}
            />
          </TabPanel>

          <TabPanel value={currentTab} index={1}>
            <Information
              setValue={setValue}
              specialCars={specialCars}
              errors={errors}
              register={register}
              handleSubmit={handleSubmit}
              control={control}
              onErrorsChange={handleChildErrorsChange}
            />
          </TabPanel>

          <TabPanel value={currentTab} index={2}>
            <Confirmation
              tintGlassId={tintGlassId}
              currentTab={currentTab}
              additionalServiceIds={selectedServices}
              additionalService={additionalService}
              tintedGlass={tintedGlass}
              sendForm={sendForm}
              open={open}
              setOpen={setOpen}
              setValue={setValue}
              email={email}
            />
          </TabPanel>
          <Stack
            gap={3}
            // direction={'horizontal'}
            className={classes.btn_wrapper}
            sx={{
              margin: '50px 0 50px 0',
              justifyContent: 'flex-end',
            }}
          >
            {currentTab !== 2 && (
              <button
                // disabled={childErrors && childErrors.length > 0}
                // disabled={[2].indexOf(state.confirmation) > -1}
                // disabled={currentTab === 1 }
                // disabled={Object.keys(errors).length > 0}
                onClick={handleNext}
                className={clsx(
                  t('form.next') === 'Next' ? classes.en_btn : classes.fr_btn,
                  classes.btn,
                  nextButtonClassName,
                )}
              >
                {t('form.next')}
              </button>
            )}
          </Stack>
        </Box>
      </Box>
    </Box>
  )
}

export default Appointment
