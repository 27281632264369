import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  hide: {
    width: "10%",
    background: "transparent",
    border: "transparent",
    cursor: "pointer",
  },
  btnWrapper: {
    display: "flex",
    position: "relative",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "row",
    },
  },
  degree: {
    width: "60px",
    height: "60px",
    // display: "none",
    position: "absolute",
    top: "40%",
    // left: "50%",
    // [theme.breakpoints.down("lg")]: {
    //   width: "42px",
    //   height: "42px",
    // },
    [theme.breakpoints.down("md")]: {
      width: "32px",
      height: "32px",
    },
  },
  hideDegree: {
    width: "60px",
    height: "60px",
    display: "none",
    position: "absolute",
    top: "40%",
    left: "50%",
  },
  img: {
    width: "60px",
    height: "60px",
    [theme.breakpoints.down("md")]: {
      width: "32px",
      height: "32px",
    },
  },
}));
