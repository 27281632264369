import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd';
import { Box } from '@mui/material';
// import { FallbackProps } from 'react-error-boundary';

const ErrorBoundaryFallback = ({ error, resetErrorBoundary }) => (
  < Box >
    <h3>Something went wrong:</h3>
    <pre>{error.message}</pre>
    <Button onClick={resetErrorBoundary} >Try Again</Button>
  </Box>
);

export default ErrorBoundaryFallback;
ErrorBoundaryFallback.propTypes = {
  error: PropTypes.any,
  resetErrorBoundary: PropTypes.any
}