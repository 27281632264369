import { makeStyles } from "@mui/styles";
import wrapping_bg from "../../assets/images/covering/wrapping_bg.svg";
// import partners_bg from '../../assets/images/covering/partners_bg.svg'

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "150px",
  },
  background: {
    backgroundImage: `url(${wrapping_bg})`,
    backgroundSize: "cover",
    backgroundPosition: "left",
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "-290px",

    [theme.breakpoints.down("lg")]: {
      backgroundPositionY: "30px",
      paddingBottom: "61px",
    },
  },
  slide: {
    // minHeight: '100vh',
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    // gap: '15px',
    // padding: '20px 9%',
    // paddingBottom: '70px',
  },
  wrapper: {
    display: "flex",
    // alignItems: 'start',
    alignItems: "center",
    textAlign: "center",
    // justifyContent: 'space-evenly',
    height: "100%",
    marginBottom: "118px",
    [theme.breakpoints.down(1300)]: {
      marginBottom: "0% !important",
    },
    [theme.breakpoints.down(1100)]: {
      marginBottom: "5%!important",
    },

    [theme.breakpoints.down("sm")]: {
      marginBottom: "10%!important",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "5%!important",
    },
  },

  textWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    // rowGap: '33px',
    rowGap: "30px",
    width: "40%",
    marginLeft: "auto",
    [theme.breakpoints.down("lg")]: {
      rowGap: "0px",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: "unset",
      rowGap: "5px",
    },
  },

  content: {
    width: "100%",
    height: "100%",
    maxWidth: "390px",
    fontFamily: "Rubik",
    fontWeight: "400",
    fontSize: "28px",
    lineHeight: "33px",
    textAlign: "center",
    color: "#1A1C3A",
    [theme.breakpoints.down("xl")]: {
      padding: "10px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
      lineHeight: "24px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "21px",
    },
  },

  btn: {
    padding: "18px",
    background: "#FCFCFC",
    border: "1px solid #1A1C3A",
    borderRadius: "2px 20px",
    gap: "10px",
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "21px",
    textTransform: "uppercase",
    color: "#1A1C3A",
    cursor: "pointer",
    "&:hover": {
      backgroundImage: `linear-gradient(90deg, rgba(26, 28, 58, 0.9), rgba(252, 252, 252, 0.8))`,
    },

    [theme.breakpoints.down("lg")]: {
      padding: "16px",
      fontSize: "14px",
      lineHeight: "17px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "10px",
      fontSize: "16px",
      lineHeight: "19px",
    },
  },

  contact: {
    textDecoration: "none",
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "21px",
    textTransform: "uppercase",
    color: "#1A1C3A",
    "&:hover": {
      color: "black",
    },
  },
  title: {
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "40px",
    lineHeight: "47px",
    textAlign: "center",
    color: "#000000",
    [theme.breakpoints.down(1040)]: {
      fontSize: "28px",
      lineHeight: "33px",
      margin: "0px",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "23px",
      lineheight: "26px",
      margin: "0px",
    },
  },
  titleWrapper: {
    fontFamily: "Rubik",
    fontWeight: "800",
    fontSize: "32px",
    lineHeight: "38px",
    color: "#1A1C3A",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
    },
  },
  highlight: {
    width: "175px",
    // height: '62px',
    background: "#E5CC82",
    borderRadius: "4px",
    padding: "12px",
    gap: "10px",
    marginLeft: "10px",
    marginRight: "10px",
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "33px",
    color: "#FCFCFC",
    "&:hover": {
      color: "#fff6f6de",
    },
    [theme.breakpoints.down("lg")]: {
      width: "156px",
      fontSize: "28px",
      // height: '42px',
    },
    [theme.breakpoints.down("md")]: {
      width: "120px",
      fontSize: "22px",
      padding: "8px",
    },
  },
  full_wrapping: {
    fontSize: "20px",
    lineheight: "24px",
    fontFamily: "Rubik",
    fontWeight: "400",
    textAlign: "center",
    color: "#1A1C3A",
    width: "100%",
    maxWidth: "442px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "321px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineheight: "21px",
    },
  },
  wrapping_1: {
    width: "100%",
    maxWidth: "60%",
    height: "650px",
    marhinLeft: "auto",

    [theme.breakpoints.down(1040)]: {
      height: "100%",
    },

    [theme.breakpoints.down("md")]: {
      width: "447px",
      // height: '300px',
      height: "100%",
    },
    [theme.breakpoints.down(400)]: {
      width: "179px",
      // height: '180px',
      height: "100%",
    },
  },
  wrapping_2: {
    width: "58%",
    height: "500px",
    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
    },
    [theme.breakpoints.down(400)]: {
      height: "100%",
      width: "100%",
    },
  },
  full_wrapping_container: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    margin: "61px 50px 119px",
    [theme.breakpoints.down("lg")]: {
      // margin: '0 50px 0px',
      margin: "20px 50px 20px",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      rowGap: "15px",
      margin: "40px 27px 20px",
    },
  },
  car_container: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    columnGap: "50px",
    height: "100%",
    width: "100%",
  },
  car: {
    width: "70%",
    // height: "600px",

    [theme.breakpoints.down(1024)]: {
      height: "100%",
    },
  },
  margin: {
    [theme.breakpoints.down("md")]: {
      margin: "0px 0 50px 0",
    },
  },
  moreColors: {
    marginTop: "20px",
    fontFamily: "Rubik",
    fontWeight: "500",
    fontSize: "20px",
    color: "#1A1C3A",
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  partners_container: {
    // backgroundImage: `url(${partners_bg})`,
    background:
      "linear-gradient(190.51deg, #1A1C3A 0.56%,rgba(252, 252, 252, 0)  63.08%)",
    paddingTop: "60px",
    paddingBottom: "50px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("md")]: {
      paddingTop: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "40px",
    },
  },
}));
