import { makeStyles } from "@mui/styles";
import background from "../../assets/images/background/home_bg.svg";
import review from "../../assets/images/background/customers_say.svg";
// import photo from "./photo.jpg";

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${background})`,
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "4500px",
    backgroundPositionY: "-65%",
    backgroundPositionX: "center",

    [theme.breakpoints.down(2000)]: {
      backgroundSize: "3000px",
      backgroundPosition: "top",
      backgroundPositionY: "-395px",
    },
    [theme.breakpoints.down(1900)]: {
      backgroundSize: "2600px",
      backgroundPositionY: "-20%",
      backgroundPositionX: "center",
    },
    [theme.breakpoints.down(1800)]: {
      backgroundSize: "2500px",
      backgroundPositionY: "-20%",
      backgroundPositionX: "center",
    },
    [theme.breakpoints.down(1700)]: {
      backgroundSize: "2500px",
      backgroundPositionY: "-28%",
      backgroundPositionX: "center",
    },
    [theme.breakpoints.down(1600)]: {
      backgroundSize: "2300px",
      backgroundPositionY: "-23%",
      backgroundPositionX: "center",
    },
    [theme.breakpoints.down(1500)]: {
      // backgroundSize: "2300px",
      backgroundPositionY: "-25%",
    },
    [theme.breakpoints.down("lg")]: {
      backgroundSize: "unset",
      // backgroundPositionX: "-547px",
      backgroundPositionY: "-320px",
    },

    [theme.breakpoints.down(1100)]: {
      backgroundSize: "unset",
      backgroundPositionY: "-380px",
    },

    [theme.breakpoints.down(900)]: {
      backgroundSize: "1270px",
      backgroundPosition: "top",
      backgroundPositionY: "-90px",
    },
    [theme.breakpoints.down("md")]: {
      backgroundSize: "764px",
    },
    [theme.breakpoints.down(600)]: {
      backgroundPositionY: "0px",
      backgroundSize: "794px",
    },
    [theme.breakpoints.down(540)]: {
      backgroundPositionY: "-42px",
      backgroundSize: "764px",
    },
  },
  padding: {
    [theme.breakpoints.down(1024)]: {
      paddingTop: "100px",
    },
  },
  serviceContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    margin: "0 4%",
    height: "100%",

    [theme.breakpoints.down("md")]: {
      margin: "-40px 4%",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
  },
  titleWrapper: {
    fontFamily: "Rubik",
    fontWeight: "800",
    fontSize: "32px",
    lineHeight: "38px",
    color: "#1A1C3A",
    margin: "70px 0  50px 0",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      margin: "50px 0 30px 0",
      // margin: 0,
      fontSize: "28px",
      lineHeight: "33px",
    },
    [theme.breakpoints.down(1000)]: {
      // margin: '00px 0 30px 0',
      // margin: "7%",
      // margin: "0px",
      fontSize: "28px",
      lineHeight: "33px",
    },
    [theme.breakpoints.down(900)]: {
      margin: "6%",
    },
    [theme.breakpoints.down(540)]: {
      fontSize: "22px",
      lineHeight: "26px",
      // margin: '0px',
    },

    [theme.breakpoints.down(414)]: {
      margin: "4%",
      marginBottom: "4%",
    },
  },

  customertitle: {
    fontFamily: "Rubik",
    fontWeight: "800",
    fontSize: "32px",
    lineHeight: "38px",
    color: "#1A1C3A",
    // margin: '85px 0  50px 0',
    paddingTop: "70px",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      // margin: '100px 0 30px 0',
      fontSize: "28px",
      lineHeight: "33px",
    },
    [theme.breakpoints.down(1000)]: {
      margin: "0",
      fontSize: "28px",
      lineHeight: "33px",
    },
    [theme.breakpoints.down(540)]: {
      fontSize: "22px",
      lineHeight: "26px",
      margin: "0px",
      paddingTop: "50px",
    },

    // [theme.breakpoints.down(375)]: {
    //   margin: '50px 0 30px 0',
    // },
  },

  customerWrapper: {
    textAlign: "center",
    backgroundImage: `url(${review})`,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "center",
    backgroundPositionY: "-230px",
    width: "100%",
    paddingBottom: "80px",
    [theme.breakpoints.down(2000)]: {
      backgroundSize: "2500px",
      backgroundPositionY: "-300px",
    },
    [theme.breakpoints.down(1920)]: {
      backgroundSize: "2400px",
      backgroundPositionY: "-300px",
    },
    [theme.breakpoints.down(1700)]: {
      backgroundSize: "2000px",
      backgroundPositionY: "-300px",
    },
    [theme.breakpoints.down(1300)]: {
      paddingBottom: "24px",
      backgroundSize: "1600px",
      backgroundPositionX: "-148px",
      backgroundPositionY: "-200px",
      // paddingBottom: "24px",
      // backgroundSize: "1898px",
      // backgroundPositionX: "-180px",
      // backgroundPositionY: "-318px",
    },
    [theme.breakpoints.down(1200)]: {
      // paddingBottom: "0px",
      backgroundSize: "1340px",
      backgroundPositionX: "-148px",
      backgroundPositionY: "-230px",
    },

    [theme.breakpoints.down(1024)]: {
      // paddingBottom: "0px",
      // backgroundSize: "1040px",
      // backgroundPositionX: "-125px",
      // backgroundPositionY: "-110px",
      // paddingBottom: "0px",
      backgroundSize: "1100px",
      backgroundPositionX: "-140px",
      backgroundPositionY: "-135px",
    },
    [theme.breakpoints.down(1000)]: {
      // paddingBottom: "0px",
      backgroundSize: "1200px",
      backgroundPositionX: "-142px",
      backgroundPositionY: "-175px",
    },
    [theme.breakpoints.down(850)]: {
      // paddingBottom: "0px",
      backgroundSize: "1200px",
      backgroundPositionX: "-230px",
      backgroundPositionY: "-175px",
    },
    [theme.breakpoints.down(800)]: {
      backgroundPositionX: "-190px",
      backgroundSize: "1100px",
      backgroundPositionY: "-145px",
    },
    [theme.breakpoints.down("md")]: {
      // backgroundPositionX: "70%",
      // backgroundSize: "cover",
      // backgroundPositionY: "-25px",
      // paddingBottom: "0px",
      backgroundSize: "800px",
      backgroundPositionX: "-168px",
      backgroundPositionY: "-10px",
    },

    [theme.breakpoints.down(600)]: {
      // paddingBottom: "0px",
      backgroundSize: "1000px",
      backgroundPositionX: "-330px",
      backgroundPositionY: "-109px",
    },
    [theme.breakpoints.down(540)]: {
      // backgroundPositionX: "-140px",
      // backgroundPositionY: "-32px",
      // paddingBottom: "0px",
      // backgroundSize: "625px",
      // paddingBottom: "0px",
      paddingBottom: "20px",

      backgroundSize: "955px",
      backgroundPositionX: "-442px",
      backgroundPositionY: "-110px",
    },

    [theme.breakpoints.down(414)]: {
      // paddingBottom: "0px",
      // backgroundSize: "880px",
      // backgroundPositionX: "-384px",
      // backgroundPositionY: "-88px",
    },
    [theme.breakpoints.down(380)]: {
      backgroundSize: "905px",
      backgroundPositionX: "-428px",
      backgroundPositionY: "-95px",
    },
  },

  highlight: {
    width: "163px",
    background: "#E5CC82",
    borderRadius: "4px",
    padding: "12px",
    gap: "10px",
    marginLeft: "10px",
    marginRight: "10px",
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "28px",
    lineHeight: "33px",
    color: "#FCFCFC",
    "&:hover": {
      color: "#fff6f6de",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "28px",
      lineHeight: "33px",
      padding: "8px",
      width: "138px",
    },
    [theme.breakpoints.down("md")]: {
      width: "112px",
      fontSize: "22px",
      lineHeight: "26px",
      padding: "8px",
    },
  },

  processingWrapper: {
    textAlign: "center",
    paddingTop: "180px",
    // backgroundImage: `url(${photo})`,

    [theme.breakpoints.down("lg")]: {
      paddingTop: "10%",
    },
    [theme.breakpoints.down(900)]: {
      paddingTop: "40px",
    },
    [theme.breakpoints.down(600)]: {
      paddingTop: "35px",
    },
    [theme.breakpoints.down(540)]: {
      paddingTop: "15px",
    },
    [theme.breakpoints.down(414)]: {
      paddingTop: "10%",
    },
    [theme.breakpoints.down(370)]: {
      paddingTop: "14%",
    },
  },

  service_text: {
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#FCFCFC",
    maxWidth: "270px",
    width: "100%",

    [theme.breakpoints.down("lg")]: {
      fontSize: "18px",
      lineHeight: "21px",
      maxWidth: "200px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      lineHeight: "19px",
      fontWeight: "500",
    },
  },

  service_card: {
    width: "429px",
    height: "460px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundPosition: "center",
    margin: "0 auto",
    backgroundRepeat: "no-repeat ",
    backgroundSize: "contain",
    [theme.breakpoints.down(900)]: {
      width: "220px",
      height: "220px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "125px",
      height: "130px",
    },
  },
  service_wrapper: {
    textAlign: "center",
    padding: "0px",
    margin: "0px",
    maxHeight: "460px",
    height: "100%",
    // marginTop: "150px",
    marginTop: "220px",

    [theme.breakpoints.down("lg")]: {
      paddingTop: "5%",
      marginTop: "0px",
    },

    [theme.breakpoints.down("md")]: {
      marginTop: "0px",
      paddingTop: "0%",
    },
  },
  // review_container: {
  //   minHeight: '500px',
  //   height: '100%',
  //   width: '100%',
  //   [theme.breakpoints.down('lg')]: {
  //     minHeight: 'unset',
  //   },
  // },

  review_container: {
    // margin: '0% 12% 100px',
    [theme.breakpoints.down("lg")]: {
      // margin: '2%',
      // paddingBottom: '50px',
    },
  },
}));
