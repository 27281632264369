import { makeStyles } from "@mui/styles";
import background from "../../assets/images/background/contact_bg_lg.png";

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${background})`,
    backgroundRepeat: "no-repeat",
    background:
      "linear-gradient(109.51deg, #1A1C3A 0.56%, rgba(252, 252, 252, 0) 63.08%)",
    height: "100%",
    marginTop: "100px",
  },
  titleWrapper: {
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "38px",
    color: "#1A1C3A",
    padding: "50px 0 50px",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      // margin: "40px 0 10.95px 0",
      fontSize: "28px",
      lineHeight: "33px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
      lineHeight: "26px",
      margin: "0",
      padding: "40px 0 20px",
    },
  },
  // textfield: {
  //   marginTop: "20px !important",
  //   "& .MuiInputLabel-root": { color: "black !important" },
  //   "& .MuiOutlinedInput-root": {
  //     "& > fieldset": { border: "1px solid black!important" },
  //   },
  //   [theme.breakpoints.down("lg")]: {
  //     marginTop: "unset",
  //   },
  // },
  highlight: {
    width: "163px",
    background: "#E5CC82",
    borderRadius: "4px",
    padding: "12px",
    gap: "10px",
    marginLeft: "10px",
    marginRight: "10px",
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "28px",
    lineHeight: "33px",
    color: "#FCFCFC",
    "&:hover": {
      color: "#fff6f6de",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "28px",
      lineHeight: "33px",
      width: "60px",
      height: "57px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
      lineHeight: "26px",
      width: "45px",
      height: "42px",
      padding: "8px",
    },
  },

  contactWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: "1146px",
    width: "100%",
    margin: "0 auto",
    marginBottom: "50px",
    [theme.breakpoints.down(1280)]: {
      flexWrap: "wrap",
      justifyContent: "space-evenly",
      rowGap: "20px",
      width: "60%",
      columnGap: "100px",
      marginBottom: "20px",
    },
    [theme.breakpoints.down(1100)]: {
      flexWrap: "wrap",
      justifyContent: "space-evenly",
      rowGap: "20px",
      width: "100%",
      columnGap: "25%",
    },

    [theme.breakpoints.down(600)]: {
      flexWrap: "wrap",
      justifyContent: "space-evenly",
      width: "unset",
      columnGap: "5%",
    },
  },

  form: {
    display: "flex",
    // alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    margin: "0 auto",
    maxWidth: "595px",
    width: "100%",
    [theme.breakpoints.down(800)]: {
      width: "90%",
    },
  },
  flex: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: "18px",
  },
  img: {
    width: "40px",
    [theme.breakpoints.down("md")]: {
      width: "35px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "28px",
    },
  },
  btn: {
    padding: "18px 48px",
    background: "#FCFCFC",
    border: "1px solid #1A1C3A",
    borderRadius: "2px 20px",
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "21px",
    textTransform: "uppercase",
    color: "#1A1C3A",
    margin: "8%",

    transition: ".5s ease-in-out",
    "&:hover": {
      color: "rgba(26, 28, 58, 1)",
      backgroundImage: `linear-gradient(90deg, rgba(26, 28, 58, 0.9), rgba(252, 252, 252, 0.8))`,
      // backgroundColor: "rgba(26, 28, 58, 1)",
      // borderColor: "rgba(26, 28, 58, 1)",
      // background: "hsba(236, 55%, 23%, 1)",
      // "linear-gradient(109.51deg, #1A1C3A 0.56%, rgba(252, 252, 252, 0) 63.08%)",
    },

    [theme.breakpoints.down("lg")]: {
      padding: "16px 36px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "14px 24px",
    },
  },
  text: {
    fontFamily: "Rubik",
    fontWeight: "400",
    fontSize: "22px",
    lineHeight: "26px",
    color: "#000000",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "19px",
      lineHeight: "23.7px",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "21px",
    },
  },
  location: {
    [theme.breakpoints.down(1280)]: {
      width: "170px",
      order: 3,
    },
  },
  time: {
    [theme.breakpoints.down(1280)]: {
      width: "170px",
      order: 4,
    },
  },
  margin: {
    marginTop: "20px !important",
  },
  call: {
    [theme.breakpoints.down(1280)]: { order: 1 },
  },
  // location: {
  //   [theme.breakpoints.down('md')]: { order: 3 },
  // },
  email: {
    [theme.breakpoints.down(1280)]: { order: 2 },
  },
  // time: {
  //   [theme.breakpoints.down('md')]: { order: 0 },
  // },
  //   errorTextField: {
  //     marginTop: "20px !important",
  //     "& .MuiInputLabel-root": { color: "black !important" },
  //     // "& .MuiOutlinedInput-root": {
  //     //   "& fieldset": {
  //     //     border: "1px solid red!important",
  //     //   },
  //     // },
  //     "& .MuiInputLabel-shrink": {
  //       color: "red!important",
  //     },

  //     [theme.breakpoints.down("lg")]: {
  //       marginTop: "unset",
  //     },
  //   },
  textfield: {
    marginTop: "20px !important",

    "& label.Mui-focused": {
      color: "inherit",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "inherit",
        border: "1px solid black!important",
      },
      "&:hover fieldset": {
        borderColor: "inherit",
      },
      "&.Mui-focused fieldset": {
        borderColor: "inherit",
      },
      "&.Mui-error fieldset": {
        borderColor: theme.palette.error.main,
      },
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: "unset",
    },
  },

  errorField: {
    marginTop: "20px !important",

    "& label.Mui-focused": {
      color: "red !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red !important",
      },
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: "unset",
    },
  },
  error: {
    color: "red",
  },
  label: {
    color: "red!important",
  },
  outlinedInput: {
    "&$error input": {
      borderColor: "red!important",
    },
    border: "1px solid black!important",
  },
  errorTextField: {
    marginTop: "20px !important",
    "& .MuiInputLabel-root": {
      color: "black!important",
    },
    "& .MuiInputLabel-shrink": {
      color: "black!important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid black!important",
      },
    },
    "&.Mui-error": {
      "& .MuiInputLabel-root": {
        color: "red!important",
      },
      "& .MuiInputLabel-shrink": {
        color: "red!important",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          border: "1px solid red!important",
        },
      },
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: "unset",
    },
  },
}));
