import React from 'react'
import { Box } from '@mui/material'
import { slidesData } from '../../mocks/gallery'
import GalleryList from './GalleryList'
import { useStyles } from './styles'
import ImageCarousel from '../../components/ImageCarousel'

const Gallery = () => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box
        className={classes.margin}
        style={{
          marginTop: '150px',
        }}
      >
        <ImageCarousel slides={slidesData} />
      </Box>
      <GalleryList />
    </Box>
  )
}

export default Gallery
