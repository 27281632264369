import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

const GalleryList = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [imageIds, setImageIds] = useState([])
  const [gallery, setGallery] = useState('')
  const [visible, setVisible] = useState(6)

  const fetchImageById = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}coverbox/public/getImageIdsByImageType`,
        {
          params: {
            imageType: 'OUR_GALLERY',
          },
        },
      )

      const imageIds = response.data
      localStorage.setItem('gallery', JSON.stringify(imageIds))
      setImageIds(imageIds)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchImageById()
  }, [])

  useEffect(() => {
    const getImages = async () => {
      try {
        imageIds.map(async (el) => {
          const res = await axios.get(
            `${process.env.REACT_APP_API_KEY}coverbox/public/files/other/image`,
            {
              params: {
                imageId: el,
              },
              responseType: 'blob',
            },
          )
          const imageBlob = res.data
          const imageUrl = URL.createObjectURL(imageBlob)
          // setGallery([...imageUrl])
          setGallery((prev) => {
            return [...prev, imageUrl]
          })
        })
      } catch (error) {
        console.error(error)
      }
    }

    getImages()
  }, [imageIds])

  const loadMoreImages = () => {
    setVisible((prevVisibleImages) =>
      Math.min(prevVisibleImages + 6, gallery.length),
    )
  }

  return (
    <>
      {imageIds.length > 0 && (
        <>
          <Box className={classes.test}>
            <Box className={classes.titleWrapper}>
              {t('common.our')}
              <span className={classes.highlight}>{t('common.gallery')}</span>
            </Box>
          </Box>
          <Box className={classes.galleryWrapper}>
            {gallery &&
              gallery.slice(0, visible).map((el) => {
                return (
                  <img src={el} key={el} alt="" className={classes.image} />
                )
              })}
            {visible < gallery.length && (
              <div className={classes.btnWrapper}>
                <button className={classes.seeMore} onClick={loadMoreImages}>
                  See More
                </button>
              </div>
            )}
          </Box>
        </>
      )}
    </>
  )
}

export default GalleryList
