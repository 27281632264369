import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import { initialState } from './initialState'
// import auth from './auth/reducer'
import gallery from './gallery/reducer'
import datepicker from './datepicker/reducer'

const rootReducer = combineReducers({
  // auth,
  gallery,
  datepicker,
})

export default createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(thunk),
    // window.__REDUX_DEVTOOLS_EXTENSION__ &&
    //   window.__REDUX_DEVTOOLS_EXTENSION__(),
  ),
)
