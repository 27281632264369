import { makeStyles } from "@mui/styles";
import benefit_1 from "../../assets/images/tintedGlass/benefit_1.svg";
import benefit_2 from "../../assets/images/tintedGlass/benefit_2.svg";
import background from "../../assets/images/background/tinted_glass.png";

export const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPositionY: "400px",

    [theme.breakpoints.down("lg")]: {
      backgroundSize: "auto",
      backgroundPositionY: "initial",
    },
  },
  serviceContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    // margin: '0 4%',

    [theme.breakpoints.down("lg")]: {
      columnGap: "4%",
    },
  },
  titleWrapper: {
    fontFamily: "Rubik",
    fontWeight: "800",
    fontSize: "32px",
    lineHeight: "38px",
    color: "#1A1C3A",
    margin: "112px 0  50px 0",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      margin: "50px 0 30px 0",
      fontSize: "28px",
    },
    [theme.breakpoints.down("md")]: {
      // margin: '40px 0 15.95px 0',
      fontSize: "22px",
    },
  },
  partnersTitle: {
    fontFamily: "Rubik",
    fontWeight: "800",
    fontSize: "32px",
    lineHeight: "38px",
    color: "#1A1C3A",
    padding: "112px 0  50px 0",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      padding: "50px 0 30px 0",
      fontSize: "28px",
    },
    [theme.breakpoints.down("md")]: {
      // padding: '40px 0 15.95px 0',
      fontSize: "22px",
    },
  },
  highlight: {
    width: "163px",
    // height: '62px',
    background: "#E5CC82",
    borderRadius: "4px",
    padding: "12px",
    gap: "10px",
    marginLeft: "10px",
    marginRight: "10px",
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "28px",
    lineHeight: "33px",
    color: "#FCFCFC",
    "&:hover": {
      color: "#fff6f6de",
    },
    [theme.breakpoints.down("lg")]: {
      width: "70px",
      height: "42px",
      fontSize: "28px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
      padding: "8px",
    },
  },
  service_card: {
    width: "540px",
    height: "500px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundPosition: "center",
    // margin: '0 auto',
    backgroundRepeat: "no-repeat ",
    backgroundSize: "contain",

    [theme.breakpoints.down("lg")]: {
      width: "322px",
      height: "300px",
    },
    [theme.breakpoints.down("md")]: {
      width: "144px",
      height: "134px",
    },
  },
  service_text: {
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#FCFCFC",
    maxWidth: "270px",
    width: "100%",

    [theme.breakpoints.down("lg")]: {
      fontSize: "18px",
      lineheight: "21px",
    },
    [theme.breakpoints.down("md")]: {
      fotSize: "16px",
      lineHeight: "19px",
      fontWeight: "500",
    },
  },
  bookAppointment: {
    textDecoration: "none",
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "21px",
    textTransform: "uppercase",
    color: "#1A1C3A",
    cursor: "pointer",
    "&:hover": {
      color: "black",
    },

    [theme.breakpoints.down("lg")]: {
      fontSize: "16px",
      lineHeight: "19px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      lineHeight: "17px",
    },
  },
  text: {
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },

  first_benefit: {
    backgroundImage: `url(${benefit_1})`,
    [theme.breakpoints.down("lg")]: {
      marginLeft: "4%",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "4%",
    },
  },
  second_benefit: {
    backgroundImage: `url(${benefit_2})`,

    [theme.breakpoints.down("lg")]: {
      marginRight: "4%",
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: "4%",
    },
  },
  btn_box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    cursor: "pointer",
  },
  button: {
    padding: "18px 48px",
    // width: '310px',
    background: "#FCFCFC",
    border: "1px solid #1A1C3A",
    borderRadius: " 2px 20px",
    cursor: "pointer",
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "21px",
    textTransform: "uppercase",
    color: "#1A1C3A",

    "&:hover": {
      backgroundImage: `linear-gradient(90deg, rgba(26, 28, 58, 0.9), rgba(252, 252, 252, 0.8))`,
    },
    [theme.breakpoints.down("lg")]: {
      padding: "16px 36px",
      // width: '262px',
    },
    [theme.breakpoints.down("md")]: {
      padding: "14px 24px",
      // width: '215px',
    },
  },

  hide: {
    width: "12%",
    background: "transparent",
    border: "transparent",
  },

  car_container: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  percent_container: {
    display: "flex",
    alignItems: "start",
    columnGap: "40px",
    flexWrap: "wrap",
    width: "20%",
    [theme.breakpoints.down("lg")]: {
      display: "flex",
      width: "unset",
      justifyContent: "center",
      flexDirection: "column",
      alignContent: "flex-start",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      justifyContent: "flex-start",
      paddingLeft: "5%",
      paddingTop: "50px",
      // paddingBottom: "50px",
    },
  },
  percent_wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    rowGap: "10px",

    [theme.breakpoints.down("lg")]: {
      flexDirection: "row",
      marginBottom: "38px",
      columnGap: "15px",
    },
    [theme.breakpoints.down("md")]: {
      // width: '231px',
      // flexWrap: "wrap",
      // columnGap: '15px',
    },
  },
  font: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "22px",
    lineHeight: "26px",
    textAlign: "center",
    color: "#1a1c3a",
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {},
  },
  percent: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "20px",
    textAlign: "center",
    color: "#1a1c3a",
    background: "#fcfcfc",
    border: "1px solid #1a1c3a",
    borderRadius: "8px 2px",
    maxWidth: "60px",
    width: "100%",
    height: "42px",
    cursor: "pointer",
    marginTop: "10px",
    "&:hover": {
      backgroundImage: `linear-gradient(90deg, rgba(26, 28, 58, 0.9), rgba(252, 252, 252, 0.8))`,
    },
    "&:focus": {
      background: "#1A1C3A",
      color: "white",
      border: "2px solid white",
    },
    [theme.breakpoints.down("lg")]: {
      minWidth: "56px",
      height: "37px",
      fontSize: "18px",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "45px",
      height: "32px",
      fontSize: "16px",
    },
  },

  first_column: {
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down("lg")]: {
      flexDirection: "row",
      columnGap: "15px",
      marginLeft: "10px",
    },
    [theme.breakpoints.down("md")]: {
      columnGap: "15px",
    },
    [theme.breakpoints.down(450)]: {
      // paddingLeft: "10px",
    },
    [theme.breakpoints.down(400)]: {
      paddingLeft: "0px",
    },
  },
  second_column: {
    display: "flex",
    flexDirection: "column",
    // maxWidth: "60px",
    alignItems: "center",
    width: "100%",

    [theme.breakpoints.down("lg")]: {
      flexDirection: "row",
      columnGap: "15px",
      width: "fit-content",
    },
    // [theme.breakpoints.down(600)]: {
    //   flexDirection: "row",
    //   justifyContent: "center",
    //   columnGap: "15px",
    //   marginLeft: "17%",
    // },

    [theme.breakpoints.down(540)]: {
      flexDirection: "row",
      justifyContent: "center",
      columnGap: "15px",
      marginLeft: "80px",
    },

    [theme.breakpoints.down(500)]: {
      flexDirection: "row",
      justifyContent: "center",
      columnGap: "15px",
      marginLeft: "20%",
    },
    [theme.breakpoints.down(420)]: {
      flexDirection: "row",
      justifyContent: "center",
      columnGap: "15px",
      marginLeft: "21%",
    },

    [theme.breakpoints.down(400)]: {
      flexDirection: "row",
      justifyContent: "center",
      columnGap: "15px",
      marginLeft: "22%",
    },
    [theme.breakpoints.down(380)]: {
      flexDirection: "row",
      justifyContent: "center",
      columnGap: "15px",
      marginLeft: "23%",
    },
  },

  back_wrapper: {
    [theme.breakpoints.down("lg")]: {
      // justifyContent: 'center',
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      flexWrap: "wrap",
      justifyContent: "flex-start",
    },
  },
  back_percent: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "space-evenly",
      columnGap: "10px",
    },
    [theme.breakpoints.down("md")]: {
      width: "210px",
      display: "flex",
      justifyContent: "space-evenly",
      flexWrap: "wrap",
    },
  },

  custom_component: {
    [theme.breakpoints.down("lg")]: {
      width: "600px",
      height: "310px",
    },
    [theme.breakpoints.down("md")]: {
      width: "335px",
      height: "160px",
    },
  },
}));
