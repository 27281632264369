import React from 'react'
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    data-tid="simple-tabpanel"
    {...other}>
    {value === index && <Box>{children}</Box>}
  </div>
);

export default TabPanel;

TabPanel.propTypes = {
  children: PropTypes.any,
  value: PropTypes.any,
  index: PropTypes.any,
  inputProps: PropTypes.any
};