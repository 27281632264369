import React, { createContext, useEffect, useRef, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import App from '../../App'
import LoadingIcon from '../components/shared/LoadingIcon'
import { allRoutes } from './routingConstants/RoutesConfig'
import { getDefaultLanguage } from './routingConstants/RoutingHelpers'
import { isAuthenticated } from '../constants/Helpers'
import { getHomePageUrl, getBasePageUrl } from './routingConstants/AppUrls'
import { NotFound } from '../../pages'

export const LocaleContext = createContext({
  locale: '',
  setLocale: () => {},
})

const LangRouter = () => {
  const { i18n } = useTranslation()
  const { pathname, search, hash } = useLocation()
  const navigate = useNavigate()
  const availableLocales = ['en', 'fr']
  const defaultLocale =
    getDefaultLanguage() === 'en' || getDefaultLanguage() === 'fr'
      ? getDefaultLanguage()
      : 'en'
  const pathnameLocale = pathname.substring(1, 3).toLowerCase()
  const [locale, setLocale] = useState(defaultLocale)
  const loaderTimerRef = useRef()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    loaderTimerRef.current = setTimeout(() => {
      setIsLoading(false)
      clearTimeout(loaderTimerRef.current)
    }, 300)
  }, [])

  useEffect(() => {
    if (availableLocales.includes(pathnameLocale)) {
      updateLocale(pathnameLocale)
    } else if (pathname === '/') {
      updateLocale(defaultLocale)
    }
  }, [pathname])

  useEffect(() => {
    let lang = defaultLocale

    if (availableLocales.includes(pathnameLocale)) {
      lang = pathnameLocale
      setLanguageHandler(lang)
    } else if (pathname === '/') {
      setLanguageHandler(lang)
    }
  }, [locale])

  const setLanguageHandler = (lang) => {
    document.documentElement.setAttribute('lang', lang)

    if (lang === 'en') {
      i18n.changeLanguage('en-US')
    } else {
      i18n.changeLanguage('fr-FR')
    }
  }

  const updateLocale = (newLocale) => {
    const newPath = `/${newLocale}` + pathname.substring(3)

    if (locale !== newLocale) {
      if (
        newPath === `/${newLocale}/` ||
        newPath === `/${newLocale}` ||
        pathname === '/'
      ) {
        navigate(getHomePageUrl(newLocale))
      } else {
        navigate(`${newPath}${hash}${search}`)
      }
      setLocale(newLocale)
    } else if (
      newPath === `/${newLocale}/` ||
      newPath === `/${newLocale}` ||
      pathname === '/'
    ) {
      if (isAuthenticated()) {
        navigate(getHomePageUrl(newLocale))
      } else {
        navigate(getBasePageUrl(newLocale))
      }
    }
  }

  if (isLoading) {
    return (
      <div className="loader-wrapper">
        <LoadingIcon />
      </div>
    )
  }
  return (
    <LocaleContext.Provider value={{ locale, setLocale: updateLocale }}>
      <Routes>
        <Route path={`/${locale}`} element={<App />}>
          {allRoutes.map((route, routeIndex) => (
            <Route
              key={routeIndex}
              path={route.path(locale)}
              element={route.element}
            >
              {route.children &&
                route.children.map((childRoute, childIndex) => (
                  <Route
                    key={childIndex}
                    path={childRoute.path(locale)}
                    element={childRoute.element}
                  />
                ))}
            </Route>
          ))}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </LocaleContext.Provider>
  )
}

export default LangRouter
