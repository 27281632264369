import { Box } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStyles } from './styles'

const Image = ({ partnerId }) => {
  const classes = useStyles()
  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    const getImages = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_KEY}coverbox/public/files/partner/image`,
          {
            params: { partnerId },
            responseType: 'blob',
          },
        )
        const image = URL.createObjectURL(res.data)
        setImageUrl(image)
      } catch (error) {
        console.error(error)
      }
    }
    getImages()
  }, [partnerId])

  return (
    <Box className={classes.container}>
      <img
        src={imageUrl}
        alt={`Image ${partnerId}`}
        className={classes.image}
      />
    </Box>
  )
}

export default Image

Image.propTypes = {
  partnerId: PropTypes.string,
}
