import React from 'react'
import { Slide } from 'react-slideshow-image'
import PropTypes from 'prop-types'
import 'react-slideshow-image/dist/styles.css'
import { Image } from '../../../components'

const Partners = ({ partners }) => {
  let partnersLength = 3
  const shouldShowIndicators = partners.length > 3
  const shouldShowButtons = partners.length > 3

  if (partners.length === 1) {
    partnersLength = 1
  } else if (partners.length === 2) {
    partnersLength = 2
  }

  return (
    <>
      {partners.length > 0 && (
        <Slide
          slidesToScroll={partnersLength}
          slidesToShow={partnersLength}
          arrows={shouldShowButtons}
          autoplay={true}
          // responsive={responsiveSettings}
          indicators={
            shouldShowIndicators
              ? () => <div className="indicator"></div>
              : null
          }
          scale={1.4}
        >
          {partners.map((partner) => {
            return <Image key={partner} partnerId={partner} />
          })}
        </Slide>
      )}
    </>
  )
}
export default Partners
Partners.propTypes = {
  partners: PropTypes.any,
}
