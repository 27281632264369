import palette from './palette'

export default {
  h1: {
    fontSize: '70px',
    lineHeight: '54px',
    fontWeight: '700',
    fontFamily: 'Poppins',
    color: palette.custom.red,
  },
}
