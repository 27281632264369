import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import PropTypes from "prop-types";
import "./carousel.css";

function ImageCarousel({ slides }) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <Carousel
      autoPlay={true}
      activeIndex={index}
      onSelect={handleSelect}
      showthumbs="false"
      nextIcon={
        <span
          aria-hidden="true"
          className="carousel-control-next-icon changed"
        />
      }
    >
      {slides.map((slide) => (
        <Carousel.Item key={slide?.id}>
          <img className="d-block w-100" src={slide?.image} alt="First slide" />
          <Carousel.Caption>
            <div className="pageName">{slide?.title}</div>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default ImageCarousel;
ImageCarousel.propTypes = {
  slides: PropTypes.any,
};
