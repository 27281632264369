import { makeStyles } from "@mui/styles";
import background from "../../assets/images/background/appointment.svg";

export const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: "flex-end",
    display: "flex",
    width: "100%",
    marginRight: "38%",
    [theme.breakpoints.down("md")]: {
      // marginTop: '100px',
    },
  },
  appointment: {
    backgroundImage: `url(${background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "5%",
    backgroundPositionX: "center",

    // backgroundPosition: "center",
    [theme.breakpoints.down(1300)]: {
      paddingTop: "8%",
      backgroundSize: "cover",
      backgroundPosition: "bottom",
      // backgroundPositionX: "-1000px",
    },
    // [theme.breakpoints.down(1024)]: {
    //   backgroundPosition: "center",
    //   paddingTop: "%",
    // },
    [theme.breakpoints.down(900)]: {
      backgroundPosition: "center",
      paddingTop: "12%",
      backgroundSize: "cover",
      // backgroundPositionX: "-1040px",
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: "0%",
      backgroundSize: "cover",
      backgroundPosition: "bottom center",
      backgroundPositionY: "-10px",
      // backgroundPositionY: "-38px",
      // backgroundPositionX: "-1740px",
    },
    [theme.breakpoints.down(540)]: {
      paddingTop: "20px",
      backgroundSize: "cover",
      backgroundPosition: "bottom center",
      backgroundPositionY: "-15px",
      // backgroundPositionX: "-1200px",

      // backgroundPositionY: "5px",
    },
  },

  btnContainer: {
    display: "flex",
    justifyContent: "space-between",
    columnGap: "10px",
    fontSize: "14px",
    height: "40px",
    [theme.breakpoints.down("md")]: {
      height: "35px !important",
    },
  },
  dialog: {
    overflowY: "hidden",
    maxWidth: "unset !important",
  },

  textAttribute: {
    fontSize: "14px",
    fontWeight: "500",
    color: "white",
    textTransform: "capitalize",
    "&:hover": {
      opacity: 0.9,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "12px !important",
    },
  },
  backBtn: {
    marginLeft: "auto !important",
    marginRight: "10px !important",
    background: "#f5f8fa !important",
  },
  cancel: {
    float: "right !important",
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "16px",
    marginTop: "auto",
  },
  header: {
    height: "60px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0px 20px",
    cursor: "pointer",
    position: "sticky",
  },

  MuiTabs: {
    styleOverrides: {
      root: {
        flexContainer: {
          // display: "flex !important",
          // justifyContent: "center!important",
          // justifyContent: 'space-around !important',
          // alignItems: 'center !important',
          // width: '100%',
          // marginBottom: '40px !important',
          // maxWidth: 'unset !important',
        },
      },
    },
  },
  current: {
    // color: 'white !important',
    background: "#FCFCFC !important",
    // color: `${theme.palette.custom.red} !important`,
  },
  previous: {
    background: "#E5CC82 !important",
    // width: '250px',
    // color: 'white !important',
    color: `${theme.palette.custom.blue} !important`,
  },
  wrap: {
    maxWidth: "880px !important",
    width: "100% !important",
    minHeight: "300px !important",
    maxHeight: "620px !important",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },

  actions: {
    display: "flex",
    flexShrink: 0,
  },
  tabTitle: {
    fontfamily: "Rubik",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#1A1C3A",

    [theme.breakpoints.down("lg")]: {
      fontSize: "20px",
      lineHeight: "24px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      lineHeight: "19px",
    },
  },
  detailTab: {
    borderRadius: "0px 0px 0px 20px !important",
    border: "1px solid #1A1C3A !important",
    // width: '290px',
    color: "black!important",
    [theme.breakpoints.down("lg")]: {
      // width: '221px',
    },
    [theme.breakpoints.down(500)]: {
      // width: '125px',
    },
  },
  infoTab: {
    border: "1px solid !important",
    // width: '290px',
    color: "black!important",
    [theme.breakpoints.down("lg")]: {
      // width: '221px',
    },
    [theme.breakpoints.down(500)]: {
      // width: '125px',
    },
  },
  confirmTab: {
    borderRadius: "0px 20px 0px 0px !important",
    border: "1px solid #1A1C3A !important",
    // width: '290px',
    color: "black!important",
    [theme.breakpoints.down("lg")]: {
      borderRadius: "0px 0px 20px 0px!important",
      // width: '221px',
    },
    [theme.breakpoints.down("md")]: {
      borderRadius: "0px 0px 20px 0px!important",
      // width: '125px',
    },
  },

  btn_wrapper: {
    display: "flex!important",
    flexDirection: "row!important",
  },

  btn: {
    width: "100%",
    maxWidth: "145px",
    padding: "18px !important",
    background: "#FCFCFC!important",
    border: "1px solid #1A1C3A!important",
    borderRadius: " 2px 20px!important",
    fontFamily: "Rubik!important",
    fontWeight: "700!important",
    fontSize: "18px!important",
    lineHeight: "21px!important",
    textTransform: "uppercase!important",
    color: "#1A1C3A!important",

    "&:hover": {
      backgroundImage: `linear-gradient(90deg, rgba(26, 28, 58, 0.9), rgba(252, 252, 252, 0.8)) !important`,
    },
    [theme.breakpoints.down("lg")]: {
      padding: "16px !important",
      maxWidth: "115px !important",
    },
    [theme.breakpoints.down(500)]: {
      padding: "14px !important",
      maxWidth: "86px !important",
    },
  },

  en_btn: {
    maxWidth: "145px!important",
  },

  fr_btn: {
    maxWidth: "215px!important",
  },

  tabs: {
    // width: '100%',
    flexShrink: 0,
    overflowX: "auto !important",
    overflowY: "hidden !important",
    marginBottom: "4% !important",
    paddingTop: "10px !important",

    "& .MuiTab-root": {
      width: "290px",
      textTransform: "capitalize!important",
      [theme.breakpoints.down("lg")]: {
        width: "221px",
      },
      [theme.breakpoints.down(600)]: {
        width: "125px",
      },
      [theme.breakpoints.down("md")]: {
        width: "106px",
        height: "19px",
      },
    },
    [theme.breakpoints.down(600)]: {
      paddingTop: "0px !important",
    },
    [theme.breakpoints.down(540)]: {
      paddingTop: "10px !important",
    },
  },
  tabPrevious: {
    background: "#e5cc82!important",
  },
  tabCurrent: {
    background: "#FCFCFC!important",
  },
  tabNext: {
    // background: "#1A1C3A !important",
  },
  activeTab: {
    background: "#FCFCFC !important",
  },
  prevTab: {
    background: "#e5cc82!important",
  },
  nextTab: {
    background: "#unset !important",
  },
}));
