import React from 'react'
import { useContext } from 'react';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { Context } from '../../container/DatePicker';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


function MonthNav() {
    let { date, setDate } = useContext(Context);
    const cal_months = ['Jan', 'Feb', 'Mar', 'Apr',
        'May', 'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec']

    let selectedNav = cal_months[date.dateTime.getMonth()];

    function handleClick() {
        let month = date.isMonthsList ? false : true;

        setDate(
            {
                dateTime: date.dateTime,
                isYearsList: false,
                isMonthsList: month,
                isDaysList: date.isYearsList == false && month == false ? true : false,
            }
        )
    }

    const style = {
        transform: date.isMonthsList ? 'rotate(180deg)' : '',
        transition: 'transform 150ms ease', // smooth transition
    }

    function setMonth(e, param) {
        e.stopPropagation();
        let getNextMonth = param == 'prev' ? date.dateTime.getMonth() - 1 : date.dateTime.getMonth() + 1;

        return setDate(
            {
                dateTime: new Date(date.dateTime.setMonth(getNextMonth)),
                isYearsList: false,
                isMonthsList: false,
                isDaysList: true
            }
        )


    }

    return (
        <div className='navWrapper'>
            <FaChevronLeft onClick={((e) => setMonth(e, 'prev'))} />
            <div className="navValue" onClick={handleClick}>
                <span>{selectedNav}</span>
                <div className='arrow' style={style}><ArrowDropDownIcon /></div>
            </div>
            <FaChevronRight onClick={((e) => setMonth(e, 'next'))} />
        </div>
    );
}

export default MonthNav;