import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    background:
      "linear-gradient(55.51deg, #1A1C3A -211.44%, rgba(252, 252, 252, 0) 54.08%)",
    height: "100%",
    paddingTop: "50px",
    [theme.breakpoints.down("md")]: {
      paddingTop: "50px",
    },
    [theme.breakpoints.down(600)]: {
      paddingTop: "0px",
    },
    [theme.breakpoints.down(540)]: {
      paddingTop: "50px",
    },
  },
  storyTitle: {
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "38px",
    color: "#1A1C3A",
    textAlign: "center",
    marginBottom: "4%",
    [theme.breakpoints.down("lg")]: {
      width: "50%",
      float: "right",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      fontSize: "28px",
      lineHeight: "33px",
      paddingTop: "50px",
    },

    [theme.breakpoints.down("md")]: {
      width: "50%",
      float: "right",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      fontSize: "22px",
      paddingTop: "0",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "5%",
      paddingTop: "0",
    },
  },
  valueTitle: {
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "38px",
    color: "#1A1C3A",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
    },
  },
  highlight: {
    // width: "163px",
    background: "#E5CC82",
    borderRadius: "4px",
    padding: "12px",
    marginLeft: "20px",
    marginRight: "10px",
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "28px",
    lineHeight: "33px",
    color: "#FCFCFC",
    cursor: "pointer",
    "&:hover": {
      color: "#fff6f6de",
    },

    [theme.breakpoints.down("lg")]: {
      fontSize: "28px",
      // width: "103px",
      height: "57px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
      lineHeight: "26px",
      // width: "78px",
      height: "42px",
      padding: "8px",
    },
  },
  storyContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  valueContainer: {
    height: "100%",
    width: "100%",
    paddingTop: "40px",
    paddingBottom: "100px",
    [theme.breakpoints.down("md")]: {
      paddingTop: "30px",
    },
  },
  story: {
    [theme.breakpoints.down(500)]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  },
}));
