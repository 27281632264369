import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      marginBottom: theme.spacing(2),
    },
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "yellow !important",
  },
  grid: {
    width: "100%",
    maxWidth: "940px",
    background: "#FCFCFC",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    columnGap: "25px",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "664px",
      padding: "20px",
    },

    [theme.breakpoints.down("md")]: {
      maxWidth: "335px",
      padding: "20px",
    },
  },
  title: {
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "22px",
    lineHeight: "26px",
    color: "#1A1C3A",
  },
  name: {
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "24px",
    color: "#1A1C3A",
  },
  cost: {
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "21px",
    textTransform: "uppercase",
    color: " #1A1C3A",
  },
  // textfield: {
  //   // width: "429px!important",
  //   // height: "56px",
  //   marginTop: "25px !important",
  //   "& .MuiInputLabel-root": { color: "black !important" },
  //   "& .MuiOutlinedInput-root": {
  //     "& > fieldset": { border: "1px solid black!important" },
  //   },
  //   [theme.breakpoints.down("lg")]: {
  //     marginTop: "unset",
  //   },
  // },
  padding: {
    paddingBottom: "20px",
    [theme.breakpoints.down("lg")]: {
      paddingBottom: "unset",
    },
  },
  bottom: {
    marginBottom: "20px",
  },
  root: {
    borderColor: "orange!impotrant",
  },

  textfield: {
    marginTop: "20px !important",

    "& label.Mui-focused": {
      color: "inherit",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "inherit",
        border: "1px solid black!important",
      },
      "&:hover fieldset": {
        borderColor: "inherit",
      },
      "&.Mui-focused fieldset": {
        borderColor: "inherit",
      },
      "&.Mui-error fieldset": {
        borderColor: "red !important",
      },
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: "unset",
    },
  },
  percentageField: {
    // marginTop: "20px !important",
    "& label.Mui-focused": {
      color: "inherit",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "inherit",
        border: "1px solid black!important",
      },
      "&:hover fieldset": {
        borderColor: "inherit",
      },
      "&.Mui-focused fieldset": {
        borderColor: "inherit",
      },
      "&.Mui-error fieldset": {
        borderColor: "red !important",
      },
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: "20px !important",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "20px !important",
    },
  },

  marginTop: {
    [theme.breakpoints.down("md")]: {
      marginTop: "20px !important",
    },
  },

  errorField: {
    marginTop: "20px !important",

    "& label.Mui-focused": {
      color: "red !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red !important",
      },
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: "unset",
    },
  },
  error: {
    color: "red !important",
  },
  label: {
    color: "red!important",
  },
  outlinedInput: {
    "&$error input": {
      borderColor: "red!important",
    },
    border: "1px solid black!important",
  },
  errorTextField: {
    marginTop: "20px !important",
    "& .MuiInputLabel-root": {
      color: "black!important",
    },
    "& .MuiInputLabel-shrink": {
      color: "black!important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid black!important",
      },
    },
    "&.Mui-error": {
      "& .MuiInputLabel-root": {
        color: "red!important",
      },
      "& .MuiInputLabel-shrink": {
        color: "red!important",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          border: "1px solid red!important",
        },
      },
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: "unset",
    },
  },

  select: {
    "& .MuiSelect-icon": {
      color: "#E5CC82",
    },
  },

  carModelField: {
    "& label.Mui-focused": {
      color: "inherit",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "inherit",
        border: "1px solid black!important",
      },
      "&:hover fieldset": {
        borderColor: "inherit",
      },
      "&.Mui-focused fieldset": {
        borderColor: "inherit",
      },
      "&.Mui-error fieldset": {
        borderColor: "red !important",
      },
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: "18px !important",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "15px !important",
    },
  },
}));
