import React from 'react'
import { Box } from '@mui/material'
// import profile from '../../../assets/images/story.svg'
import person from '../../../assets/images/person.png'

import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'

const Story = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={classes.container}>
      <Box className={classes.profileWrapper}>
        {/* <img className={classes.profile} src={profile} alt="profile" /> */}
        <div className={classes.profile_container}>
          <img className={classes.image} src={person} />
        </div>
        <div className={classes.name}> Arsen Jzmachyan</div>
      </Box>
      <Box className={classes.wrapper}>
        <p> {t('about_us.first_content')}</p>
        <p> {t('about_us.second_content')}</p>
        <p> {t('about_us.third_content')}</p>
        <p> {t('about_us.fourth_content')}</p>
        <p> {t('about_us.fifth_content')}</p>
      </Box>
    </Box>
  )
}

export default Story
