import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  tintedRow: {
    display: "flex",
    flexWrap: "wrap",
  },
  tintedColumn: {
    flex: "25%",
    maxWidth: "100%",

    [theme.breakpoints.down(800)]: {
      flex: "50%",
      maxWidth: "50%",
      height: "100%",
    },
  },
  tintedColumnImg: {
    verticalAlign: "middle",
    width: "100%",
  },
  width_50: {
    width: "50%",
  },
  width_100: {
    width: "100%",
  },
  currentIndexMax: {
    width: "100%",
    height: "740px",
    [theme.breakpoints.down("lg")]: {
      height: "350px",
    },
    [theme.breakpoints.down("md")]: {
      height: "150px",
    },
  },
  currentIndexMin: {
    width: "50%",
    height: "370px",
    [theme.breakpoints.down("lg")]: {
      height: "200px",
    },
    [theme.breakpoints.down("md")]: {
      height: "100px",
    },
  },
  percent_100: {
    width: "100%",
    height: "100%",
  },
}));
