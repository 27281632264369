import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './styles.css'
import left from '../../assets/icons/rotate_left.svg'
import right from '../../assets/icons/rotate_right.svg'

import Car1 from './Car1'
import Car2 from './Car2'
import Car3 from './Car3'
import Car4 from './Car4'
import Car5 from './Car5'
import Car6 from './Car6'
import Car7 from './Car7'
import Car8 from './Car8'
import Car9 from './Car9'
import Car10 from './Car10'
import Car11 from './Car11'
import Car12 from './Car12'
import Car13 from './Car13'
import Car14 from './Car14'
import Car15 from './Car15'
import Car16 from './Car16'
import Car17 from './Car17'
import Car18 from './Car18'
import Car19 from './Car19'
import Car20 from './Car20'
import Car21 from './Car21'
import Car22 from './Car22'
import Car23 from './Car23'
import Car24 from './Car24'
import { useStyles } from './styles'
import { Box } from '@mui/material'
/* eslint-disable  no-unused-vars, no-debugger */

function CustomSVG({ frontOpacity, backOpacity }) {
  const [dragging, setDragging] = useState(false)
  const [dragStart, setDragStart] = useState(1)
  const [imageIndex, setImageIndex] = useState(0)
  const [dragStartIndex, setDragStartIndex] = useState(0)
  const classes = useStyles()
  let pixelsPerDegree = 3
  const [currentCarIndex, setCurrentCarIndex] = useState(0)
  const [isImageHidden, setIsImageHidden] = useState(false)

  const cars = [
    <Car1 key="car1" frontOpacity={frontOpacity} backOpacity={backOpacity} />,
    <Car2 key="car2" frontOpacity={frontOpacity} backOpacity={backOpacity} />,
    <Car3 key="car3" frontOpacity={frontOpacity} backOpacity={backOpacity} />,
    <Car4 key="car4" frontOpacity={frontOpacity} backOpacity={backOpacity} />,
    <Car5 key="car5" frontOpacity={frontOpacity} backOpacity={backOpacity} />,
    <Car6 key="car6" frontOpacity={frontOpacity} backOpacity={backOpacity} />,
    <Car7 key="car7" frontOpacity={frontOpacity} backOpacity={backOpacity} />,
    <Car8 key="car8" frontOpacity={frontOpacity} backOpacity={backOpacity} />,
    <Car9 key="car9" frontOpacity={frontOpacity} backOpacity={backOpacity} />,
    <Car10 key="car10" frontOpacity={frontOpacity} backOpacity={backOpacity} />,
    <Car11 key="car11" frontOpacity={frontOpacity} backOpacity={backOpacity} />,
    <Car12 key="car12" frontOpacity={frontOpacity} backOpacity={backOpacity} />,
    <Car13 key="car13" frontOpacity={frontOpacity} backOpacity={backOpacity} />,
    <Car14 key="car14" frontOpacity={frontOpacity} backOpacity={backOpacity} />,
    <Car15 key="car15" frontOpacity={frontOpacity} backOpacity={backOpacity} />,
    <Car16 key="car16" frontOpacity={frontOpacity} backOpacity={backOpacity} />,
    <Car17 key="car17" frontOpacity={frontOpacity} backOpacity={backOpacity} />,
    <Car18 key="car18" frontOpacity={frontOpacity} backOpacity={backOpacity} />,
    <Car19 key="car19" frontOpacity={frontOpacity} backOpacity={backOpacity} />,
    <Car20 key="car20" frontOpacity={frontOpacity} backOpacity={backOpacity} />,
    <Car21 key="car21" frontOpacity={frontOpacity} backOpacity={backOpacity} />,
    <Car22 key="car22" frontOpacity={frontOpacity} backOpacity={backOpacity} />,
    <Car23 key="car23" frontOpacity={frontOpacity} backOpacity={backOpacity} />,
    <Car24 key="car24" frontOpacity={frontOpacity} backOpacity={backOpacity} />,
  ]

  const handleClick = () => {
    setIsImageHidden(true)
    setTimeout(() => {
      setIsImageHidden(false)
    }, 200)
  }

  const handlePrevClick = () => {
    setCurrentCarIndex((currentCarIndex - 1 + cars.length) % cars.length)
    handleClick()
  }

  const handleNextClick = () => {
    setCurrentCarIndex((currentCarIndex + 1) % cars.length)
    handleClick()
  }

  const currentCar = cars[currentCarIndex]

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove, false)
    document.addEventListener('mouseup', handleMouseUp, false)
    return () => {
      document.removeEventListener('mousemove', handleMouseMove, false)
      document.removeEventListener('mouseup', handleMouseUp, false)
    }
  }, [])

  const handleMouseDown = (e) => {
    e.persist()
    setDragging(true)
    setDragStart(e.screenX)
    setDragStartIndex(imageIndex)
  }

  const handleMouseUp = () => {
    setDragging(false)
  }

  const updateImageIndex = (currentPosition) => {
    let numImages = 24
    const pixelsPerImage = pixelsPerDegree * (360 / numImages)
    const dx = (currentPosition - dragStart) / pixelsPerImage
    let index = Math.floor(dx) % numImages

    if (index < 0) {
      index = numImages + index - 1
    }
    index = (index + dragStartIndex) % numImages
    if (index !== imageIndex) {
      setImageIndex(index)
    }
  }

  const handleMouseMove = (e) => {
    if (dragging) {
      updateImageIndex(e.screenX)
    }
  }

  const preventDragHandler = (e) => {
    e.preventDefault()
  }

  return (
    <Box className={classes.btnWrapper}>
      {currentCar}
      {isImageHidden ? (
        <div className={classes.hideDegree}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            className={classes.img}
          >
            <img
              alt="360 degree"
              src={right}
              onClick={handlePrevClick}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            className={classes.img}
          >
            <img
              alt="360 degree"
              src={left}
              onClick={handleNextClick}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </div>
      ) : (
        <div
          className={classes.degree}
          style={{
            width: '100%',
            height: '22%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            onClick={handlePrevClick}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '500px',
              cursor: 'pointer',
            }}
            className={classes.img}
          >
            <img src={right} alt="360 degree" style={{ cursor: 'pointer' }} />
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '500px',
              cursor: 'pointer',
            }}
            className={classes.img}
            onClick={handleNextClick}
          >
            <img src={left} alt="360 degree" style={{ cursor: 'pointer' }} />
          </div>
        </div>
      )}
    </Box>
  )
}

export default CustomSVG
CustomSVG.propTypes = {
  backOpacity: PropTypes.string,
  frontOpacity: PropTypes.string,
}
//  <Box className={classes.btnWrapper}>
//     <button className={classes.hide} onClick={handlePrevClick}></button>
//     {currentCar}
//     {isImageHidden ? (
//       <div className={classes.hideDegree}>
//         <img
//           alt="360 degree"
//           src={right}
//           onClick={handlePrevClick}
//           style={{ cursor: 'pointer' }}
//         />
//         <img
//           alt="360 degree"
//           src={left}
//           onClick={handleNextClick}
//           style={{ cursor: 'pointer' }}
//         />
//       </div>
//     ) : (
//       <div className={classes.degree}>
//         <img
//           src={right}
//           alt="360 degree"
//           onClick={handlePrevClick}
//           style={{ cursor: 'pointer' }}
//         />
//         <img
//           src={left}
//           alt="360 degree"
//           onClick={handleNextClick}
//           style={{ cursor: 'pointer' }}
//         />
//       </div>
//     )}
//     <button className={classes.hide} onClick={handleNextClick}></button>
//   </Box>
