import React from 'react'
import PropTypes from 'prop-types'
// import './styles.css'

const RadioButton = ({ changed, id, isSelected, label, value }) => {
  return (
    <div className="RadioButton">
      <input
        id={id}
        onChange={changed}
        value={value}
        type="radio"
        checked={isSelected}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}
export default RadioButton

RadioButton.propTypes = {
  changed: PropTypes.any,
  id: PropTypes.any,
  isSelected: PropTypes.any,
  label: PropTypes.any,
  value: PropTypes.any,
}
