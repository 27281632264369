import React from 'react'
import { useStyles } from './styles'
import { Box } from '@mui/material'
import cars from '../../../assets/images/values/cars.svg'
import clients from '../../../assets/images/values/clients.svg'
import experience from '../../../assets/images/values/experience.svg'

const Values = () => {
    const classes = useStyles()
    return (
        <Box className={classes.wrapper}>
            <img src={cars} alt="cars" className={classes.cars} />
            <img src={clients} alt="clients" className={classes.clients} />
            <img src={experience} alt="experience" className={classes.experience} />
        </Box>
    )
}

export default Values