// import React, { useContext, useEffect, useState } from 'react'
// import { Box } from '@mui/material'
// import axios from 'axios'
// import { LocaleContext } from '../../../ts/routing/LangRouter'
// import { useTranslation } from 'react-i18next'
// import { getAppointmentPageUrl } from '../../../ts/routing/routingConstants/AppUrls'
// import PropTypes from 'prop-types'
// import { useStyles } from './styles'
// import { useNavigate } from 'react-router-dom'

// function calculateDiscountPrice(originalPrice, discountPercentage) {
//   const discountAmount = (originalPrice * discountPercentage) / 100
//   const finalPrice = originalPrice - discountAmount
//   if (finalPrice % 1 === 0) {
//     return finalPrice.toFixed(0)
//   } else {
//     return finalPrice.toFixed(2)
//   }
// }
// const SpecialCars = ({ images, setImages }) => {
//   const classes = useStyles()
//   const { locale } = useContext(LocaleContext)
//   const { t } = useTranslation()
//   const [imageIds, setImageIds] = useState([])
//   const [loading, setLoading] = useState(true)
//   const [lang, setLang] = useState(locale)
//   const navigate = useNavigate()

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_KEY}coverbox/public/getAllTintGlassServiceDTO`,
//         {
//           params: {
//             page: 0,
//             size: 500,
//           },
//         },
//       )

//       const imageData = response.data
//       setImageIds(imageData)
//       setLoading(false)
//     } catch (error) {
//       console.log(error)
//     }
//   }
//   useEffect(() => {
//     fetchData()
//   }, [])

//   useEffect(() => {
//     setLang(locale)
//   }, [locale])

//   useEffect(() => {
//     const fetchImage = async (id) => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_API_KEY}coverbox/public/files/tintglass/image`,
//           {
//             params: {
//               tintGlassServiceId: id,
//             },
//             responseType: 'blob',
//           },
//         )

//         const imageBlob = response.data
//         const imageUrl = URL.createObjectURL(imageBlob)
//         const imageInfo = imageIds.find((item) => item.id === id)
//         const {
//           model_en,
//           model_fr,
//           discountPrice,
//           price,
//           isSpecial,
//         } = imageInfo

//         if (!isSpecial) {
//           return // Skip images that are not special
//         }

//         setImages((prevImages) => [
//           ...prevImages,
//           { id, url: imageUrl, model_en, model_fr, discountPrice, price },
//         ])
//       } catch (error) {
//         console.error(error)
//       }
//     }

//     const fetchImages = async () => {
//       for (const idObj of imageIds) {
//         await fetchImage(idObj.id)
//       }
//     }

//     if (imageIds.length > 0) {
//       fetchImages()
//     }
//   }, [imageIds])

//   if (loading) {
//     return <div>Loading...</div>
//   }

//   return (
//     <Box className={classes.container}>
//       {images.map((imageObj) => (
//         <Box className={classes.wrapper} key={imageObj.id}>
//           <Box className={classes.box}>
//             <span>{`${imageObj.discountPrice}%`}</span>
//           </Box>
//           <img
//             className={classes.car}
//             src={imageObj.url}
//             alt={`Car Window ${imageObj.id}`}
//           />
//           <Box className={classes.title}>
//             {lang === 'en' ? imageObj?.model_en : imageObj?.model_fr}
//           </Box>
//           <Box className={classes.discountedPrice}>
//             {`${imageObj.price} CHF`}
//           </Box>
//           <Box className={classes.newPrice}>
//             {imageObj.discountPrice
//               ? `${calculateDiscountPrice(
//                   imageObj.price,
//                   imageObj.discountPrice,
//                 )} CHF`
//               : `${imageObj.price} CHF`}
//           </Box>
//           <button
//             className={classes.button}
//             onClick={() =>
//               navigate(getAppointmentPageUrl(locale), {
//                 state: {
//                   tintedGlass: imageIds?.find((item) => item.id == imageObj.id),
//                   model_en: imageObj?.model_en,
//                   model_fr: imageObj.model_fr,
//                   price: imageObj.price,
//                   discountPrice: imageObj.discountPrice,
//                   isSpecial: imageObj.isSpecial,
//                   amount: calculateDiscountPrice(
//                     imageObj.price,
//                     imageObj.discountPrice,
//                   ),
//                 },
//               })
//             }
//           >
//             {t('common.book_appointment')}
//           </button>
//         </Box>
//       ))}
//     </Box>
//   )
// }

// export default SpecialCars

// SpecialCars.propTypes = {
//   images: PropTypes.any,
//   setImages: PropTypes.any,
// }

import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { LocaleContext } from '../../../ts/routing/LangRouter'
import PropTypes from 'prop-types'
import { getAppointmentPageUrl } from '../../../ts/routing/routingConstants/AppUrls'
import { useTranslation } from 'react-i18next'
import { useStyles } from './styles'

const calculateDiscountPrice = (originalPrice, discountPercentage) => {
  const discountAmount = (originalPrice * discountPercentage) / 100
  const finalPrice = originalPrice - discountAmount

  return finalPrice % 1 === 0 ? finalPrice.toFixed(0) : finalPrice.toFixed(2)
}

const CarWindow = ({ images, setImages }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { locale } = useContext(LocaleContext)
  const { t } = useTranslation()

  const [imageIds, setImageIds] = useState([])
  const [loading, setLoading] = useState(true)
  const [lang, setLang] = useState(locale)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_KEY}coverbox/public/getAllTintGlassServiceDTO`,
          {
            params: {
              page: 0,
              size: 500,
            },
          },
        )

        const imageData = response.data
        setImageIds(imageData)
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    setLang(locale)
  }, [locale])

  const fetchImage = useCallback(
    async (id) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_KEY}coverbox/public/files/tintglass/image`,
          {
            params: {
              tintGlassServiceId: id,
            },
            responseType: 'blob',
          },
        )

        const imageBlob = response.data
        const imageUrl = URL.createObjectURL(imageBlob)
        const imageInfo = imageIds.find((item) => item.id === id)

        const {
          model_en,
          model_fr,
          discountPrice,
          price,
          isSpecial,
        } = imageInfo

        if (!isSpecial) {
          return
        }

        setImages((prevImages) => [
          ...prevImages,
          {
            id,
            url: imageUrl,
            model_en,
            model_fr,
            discountPrice,
            price,
            isSpecial,
          },
        ])
      } catch (error) {
        console.error(error)
      }
    },
    [imageIds, setImages],
  )

  const fetchImages = useCallback(async () => {
    for (const idObj of imageIds) {
      await fetchImage(idObj.id)
    }
  }, [imageIds, fetchImage])

  useEffect(() => {
    if (imageIds.length > 0) {
      fetchImages()
    }
  }, [fetchImages, imageIds])

  const memoizedImages = useMemo(
    () =>
      images.map((imageObj) => (
        <Box className={classes.wrapper} key={imageObj.id}>
          <Box className={classes.box}>
            <span>{`${imageObj.discountPrice}%`}</span>
          </Box>
          <img
            className={classes.car}
            src={imageObj.url}
            alt={`Car Window ${imageObj.id}`}
          />
          <Box className={classes.title}>
            {lang === 'en' ? imageObj?.model_en : imageObj?.model_fr}
          </Box>
          <Box className={classes.discountedPrice}>
            {`${imageObj.price} CHF`}
          </Box>
          <Box className={classes.newPrice}>
            {imageObj.discountPrice
              ? `${calculateDiscountPrice(
                  imageObj.price,
                  imageObj.discountPrice,
                )} CHF`
              : `${imageObj.price} CHF`}
          </Box>
          <button
            className={classes.button}
            onClick={() =>
              navigate(getAppointmentPageUrl(locale), {
                state: {
                  tintedGlass: imageIds?.find((item) => item.id == imageObj.id),
                  model_en: imageObj?.model_en,
                  model_fr: imageObj.model_fr,
                  price: imageObj.price,
                  discountPrice: imageObj.discountPrice,
                  isSpecial: imageObj.isSpecial,
                  amount: calculateDiscountPrice(
                    imageObj.price,
                    imageObj.discountPrice,
                  ),
                },
              })
            }
          >
            {t('common.book_appointment')}
          </button>
        </Box>
      )),
    [images, classes.wrapper],
  )

  if (loading) {
    return <div>Loading...</div>
  }

  return <Box className={classes.container}>{memoizedImages}</Box>
}

export default CarWindow

CarWindow.propTypes = {
  images: PropTypes.array,
  setImages: PropTypes.func,
}
