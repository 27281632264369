import ferrari from "../assets/images/home/ferrari.svg";
import img3 from "../assets/images/home/3.png";
import feerrari from "../assets/images/home/1.png";

export const imageData = [
  {
    id: "1",
    alt: "ferrari",
    url: ferrari,
  },
  {
    id: "2",
    alt: "tesla",
    url: img3,
  },
  {
    id: "3",
    alt: "bmw",
    url: feerrari,
  },
];
