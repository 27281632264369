import limousine from '../assets/images/tintedGlass/car_window/limousine.png'
import mercedes from '../assets/images/tintedGlass/car_window/mercedes.png'
import Break from '../assets/images/tintedGlass/car_window/break.svg'
import coupe from '../assets/images/tintedGlass/car_window/coupe.png'
import mpv from '../assets/images/tintedGlass/car_window/mpv.svg'
import suv from '../assets/images/tintedGlass/car_window/suv.svg'
import tesla from '../assets/images/tintedGlass/car_window/tesla.png'
import mercedes_content from '../assets/images/tintedGlass/car_window/mercedes_content.svg'
import landRover from '../assets/images/tintedGlass/car_window/landRover.png'
import citadine from '../assets/images/tintedGlass/car_window/citadine.svg'
import limousine_content from '../assets/images/tintedGlass/car_window/limousine_content.svg'
import break_content from '../assets/images/tintedGlass/car_window/break_content.svg'
import coupe_content from '../assets/images/tintedGlass/car_window/coupe_content.svg'
import mpv_content from '../assets/images/tintedGlass/car_window/mpv_content.svg'
import suv_content from '../assets/images/tintedGlass/car_window/suv_content.svg'
import tesla_content from '../assets/images/tintedGlass/car_window/tesla_content.svg'
import land_content from '../assets/images/tintedGlass/car_window/land_content.svg'

import slide_1 from '../assets/images/tintedGlass/slide_1.png'
import slide_2 from '../assets/images/tintedGlass/slide_2.svg'
import slide_3 from '../assets/images/tintedGlass/slide_3.svg'

export const carWindow = [
  {
    id: '1',
    car: limousine,
    alt: 'limousine',
    title: 'limousine',
    discountedPrice: '399EUR',
    newPrice: '199EUR',
    content: limousine_content,
  },
  {
    id: '2',
    car: coupe,
    alt: 'coupe',
    title: 'coupe',
    discountedPrice: '399EUR',
    newPrice: '199EUR',
    content: coupe_content,
  },
  {
    id: '3',
    car: citadine,
    alt: 'citadine',
    title: 'citadine',
    discountedPrice: '399EUR',
    newPrice: '199EUR',
    content: limousine_content,
  },
  {
    id: '4',
    car: mpv,
    alt: 'mpv',
    title: 'mpv',
    discountedPrice: '399EUR',
    newPrice: '199EUR',
    content: mpv_content,
  },
  {
    id: '5',
    car: suv,
    alt: 'suv',
    title: 'suv',
    discountedPrice: '399EUR',
    newPrice: '199EUR',
    content: suv_content,
  },
  {
    id: '6',
    car: Break,
    alt: 'break',
    title: 'break',
    discountedPrice: '399EUR',
    newPrice: '199EUR',
    content: break_content,
  },
]

export const specialCars = [
  {
    id: '1',
    car: tesla,
    alt: 'tesla',
    title: 'tesla',
    discountedPrice: '399EUR',
    newPrice: '199EUR',
    content: tesla_content,
  },
  {
    id: '2',
    car: mercedes,
    alt: 'mercedes',
    title: 'mercedes',
    discountedPrice: '399EUR',
    newPrice: '199EUR',
    content: mercedes_content,
  },
  {
    id: '3',
    car: landRover,
    alt: 'land rover',
    title: 'land rover',
    discountedPrice: '399EUR',
    newPrice: '199EUR',
    content: land_content,
  },
]

export const slidesData = [
  {
    id: '1',
    alt: 'slide_1',
    image: slide_1,
    title: 'Tinted Glass',
  },
  {
    id: '2',
    alt: 'slide_2',
    image: slide_2,
    title: 'Tinted Glass',
  },
  {
    id: '3',
    alt: 'slide_3',
    image: slide_3,
    title: 'Tinted Glass',
  },
]
