import React from 'react'
import { Box } from '@mui/material'
import { useStyles } from './styles'
import Values from './Values'
import Story from './Story'
import { useTranslation } from 'react-i18next'

const AboutUs = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={classes.container}>
      <Box className={classes.story}>
        <Box className={classes.storyTitle}>
          {t('about_us.our')}
          <span className={classes.highlight}>{t('about_us.story')}</span>
        </Box>
        <Box className={classes.storyContainer}>
          <Story />
        </Box>
      </Box>
      <Box sx={{ marginTop: '20px' }}>
        <Box className={classes.valueTitle}>
          {t('about_us.our')}
          {/* Our */}
          <span className={classes.highlight}>
            {t('common.values')}
            {/* Values */}
          </span>
        </Box>
        <Box className={classes.valueContainer}>
          <Values />
        </Box>
      </Box>
    </Box>
  )
}

export default AboutUs
