import { handleActions } from 'redux-actions'
import { initialState } from '../initialState'
import {
  FETCH_GALLERY_FAIL,
  FETCH_GALLERY_START,
  FETCH_GALLERY_SUCCESS,
} from './actionType'

const initial = initialState.gallery

const reducer = handleActions(
  {
    [FETCH_GALLERY_START]: (state) => {
      return {
        ...state,
        isLoad: true,
      }
    },
    [FETCH_GALLERY_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        data: payload,
        isLoad: false,
      }
    },
    [FETCH_GALLERY_FAIL]: (state) => {
      return {
        ...state,
        isLoad: false,
      }
    },
  },

  initial,
)

export default reducer
