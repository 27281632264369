import React, { useState } from 'react'
import { Box } from '@mui/material'

import { useStyles } from './styles'
import CustomSVG from '../../components/CustomSvg'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

const RotateImage = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const frontPercentages = [
    {
      label: '100%',
      opacity: '0',
      text: t('carRotate.front_100'),
    },
    {
      label: '70%',
      opacity: '0.3',
      text: t('carRotate.front_70'),
    },
    {
      label: '50%',
      opacity: '0.5',
      text: t('carRotate.front_50'),
    },
  ]
  const backPercentages = [
    {
      label: '100%',
      opacity: '0',
      text: t('carRotate.back_100'),
    },
    {
      label: '70%',
      opacity: '0.3',
      text: t('carRotate.back_70'),
    },
    {
      label: '50%',
      opacity: '0.5',
      text: t('carRotate.back_50'),
    },
    {
      label: '35%',
      opacity: '0.65',
      text: t('carRotate.back_35'),
    },
    {
      label: '20%',
      opacity: '0.8',
      text: t('carRotate.back_20'),
    },
    {
      label: '15%',
      opacity: '0.85',
      text: t('carRotate.back_15'),
    },
    {
      label: '5%',
      opacity: '0.95',
      text: t('carRotate.back_5'),
    },
  ]

  const [frontOpacity, setFrontOpacity] = useState('0')
  const [backOpacity, setBackOpacity] = useState('0')

  const handleFrontOpacityChange = (opacity) => {
    setFrontOpacity(opacity)
  }

  const handleBackOpacityChange = (opacity) => {
    setBackOpacity(opacity)
  }

  return (
    <>
      <div className={classes.container}>
        <div className={classes.column}>
          {frontOpacity === '0' && backOpacity === '0' ? (
            <p className={classes.text}>
              {' '}
              {
                frontPercentages.find((item) => item.opacity === frontOpacity)
                  ?.text
              }
            </p>
          ) : (
            (frontOpacity !== '0' || backOpacity !== '0') && (
              <p className={classes.text}>
                {
                  frontPercentages.find((item) => item.opacity === frontOpacity)
                    ?.text
                }
              </p>
            )
          )}
        </div>
        <div className={classes.column}>
          {(frontOpacity !== '0' || backOpacity !== '0') && (
            <p className={classes.text}>
              {
                backPercentages.find((item) => item.opacity === backOpacity)
                  ?.text
              }
            </p>
          )}
        </div>
      </div>

      <Box className={classes.car_container} sx={{ display: 'flex' }}>
        <CustomSVG frontOpacity={frontOpacity} backOpacity={backOpacity} />
        <Box className={classes.percent_container}>
          <Box className={classes.percent_wrapper}>
            <Box className={classes.font}>{t('tinted_glass.front')}</Box>
            <button
              className={classes.percent}
              onClick={() => handleFrontOpacityChange('0')}
            >
              100%
            </button>
            <button
              className={classes.percent}
              onClick={() => handleFrontOpacityChange('0.3')}
            >
              70%
            </button>
            <button
              className={classes.percent}
              onClick={() => handleFrontOpacityChange('0.5')}
            >
              50%
            </button>
          </Box>
          <Box className={clsx(classes.percent_wrapper, classes.back_wrapper)}>
            <Box className={classes.font}>{t('tinted_glass.back')}</Box>
            <Box className={classes.first_column}>
              <button
                className={classes.percent}
                onClick={() => handleBackOpacityChange('0')}
              >
                100%
              </button>
              <button
                className={classes.percent}
                onClick={() => handleBackOpacityChange('0.3')}
              >
                70%
              </button>
              <button
                className={classes.percent}
                onClick={() => handleBackOpacityChange('0.5')}
              >
                50%
              </button>
            </Box>
            <Box className={classes.second_column}>
              <button
                className={classes.percent}
                onClick={() => handleBackOpacityChange('0.65')}
              >
                35%
              </button>
              <button
                className={classes.percent}
                onClick={() => handleBackOpacityChange('0.8')}
              >
                20%
              </button>

              <button
                className={classes.percent}
                onClick={() => handleBackOpacityChange('0.85')}
              >
                15%
              </button>
              <button
                className={classes.percent}
                onClick={() => handleBackOpacityChange('0.95')}
              >
                5%
              </button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default RotateImage
