import { v4 as uuidv4 } from 'uuid'
import { generateDays } from './services/generateDaysService'
import React, { useContext } from 'react'
import { Context } from '../../container/DatePicker'

function DayPicker() {
  let { date, setDate } = useContext(Context)
  const weekDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

  let monthDays = generateDays(new Date(date.dateTime))

  const setDay = (e, day) => {
    if (day.isValid && !day.isWeekend) {
      setDate({
        dateTime: new Date(day.date),
        isDaysList: true,
        isMonthsList: false,
        isYearsList: false,
      })
    }
  }

  return (
    <div className="dayPicker-container">
      <table>
        <tbody>
          <tr>
            {weekDays.map((day) => {
              return (
                <td key={day} className="day">
                  {day}
                </td>
              )
            })}
          </tr>
          {monthDays.map((week) => {
            {
              return (
                <tr key={'tr' + uuidv4()}>
                  {week.map((day) => {
                    return (
                      <td
                        key={'td' + uuidv4()}
                        className="day"
                        style={{
                          color: day.isValid ? '#000' : '#c6c6d1',
                          cursor: 'pointer',
                        }}
                        onClick={(e) => setDay(e, day)}
                      >
                        <span
                          key={'span' + uuidv4()}
                          className="daySpan"
                          style={{
                            width: '40px',
                            height: '40px',
                            borderRadius: '100px',
                            cursor:
                              !day.isWeekend && day.date >= new Date() // Check if it's not a weekend and not before today
                                ? 'pointer'
                                : 'default',
                            color: day.isWeekend
                              ? 'grey' // Weekend days in grey
                              : day.date >= new Date()
                              ? 'black' // Weekdays in black
                              : 'grey', // Days before today in grey
                            background:
                              day.date.getTime() === date.dateTime.getTime()
                                ? '#1A1C3A' // Blue background for the selected day
                                : 'transparent', // Transparent background for other days
                          }}
                        >
                          <span
                            style={{
                              color:
                                day.date.getTime() === date.dateTime.getTime()
                                  ? 'white'
                                  : 'inherit',
                            }}
                          >
                            {day.day}
                          </span>
                        </span>
                      </td>
                    )
                  })}
                </tr>
              )
            }
          })}
        </tbody>
      </table>
    </div>
  )
}

export default DayPicker
