import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    marginTop: "8%",
    marginBottom: "3%",
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: { marginTop: "20%", marginBottom: "7%" },
    [theme.breakpoints.down(600)]: { marginTop: "15%" },
  },
  title: {
    margin: "30px",
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "22px",
    lineHeight: "26px",
    color: "#E5CC82",

    [theme.breakpoints.down("lg")]: {
      fontsize: "20px",
      lineHeight: "24px",
      margin: "5%",
    },
    [theme.breakpoints.down("md")]: {
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "19px",
      paddingLeft: "40px",
      margin: "0",
    },
  },

  profile: {
    position: "absolute",
    left: "-25%",
    top: "-33%",
    maxWidth: "170px",
    maxHeight: "170px",
    width: "100%",
    height: "100%",
    borderRadius: "50%",

    border: "1px solid #E5CC82",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down("lg")]: {
      left: "-30%",
      top: "-30%",
      maxWidth: "100px",
      maxHeight: "100px",
    },
    [theme.breakpoints.down(1100)]: {
      left: "-25%",
    },

    [theme.breakpoints.down("md")]: {
      maxWidth: "70px",
      maxHeight: "70px",
      left: "-30%",
      top: "-20%",
    },
    [theme.breakpoints.down(540)]: {
      left: "-20%",
      top: "-30%",
    },
  },

  profile_inner: {
    maxWidth: "150px",
    maxHeight: "150px",
    width: "100%",
    height: "100%",
    border: "1px solid #E5CC82",
    position: "relative",
    borderRadius: "50%",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "80px",
      maxHeight: "80px",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "60px",
      maxHeight: "60px",
    },
  },
  card: {
    width: "318px",
    height: "240px",
    background: "#ffffff",
    border: " 1px solid #e5cc82",
    borderRadius: "2px 20px",
    overflow: "auto",
    [theme.breakpoints.down("lg")]: { maxWidth: "265px", maxHeight: "146px" },
    [theme.breakpoints.down("md")]: { maxWidth: "130px", maxHeight: "100px" },
  },

  review: {
    fontFamily: "Rubik",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "28px",
    padding: "5px",
    "&::before": {
      content: '"“ "',
      color: "#E5CC82",
    },

    "&::after": {
      content: '" ”"',
      color: "#E5CC82",
    },
    [theme.breakpoints.down(600)]: {
      fontWeight: "300",
      fontSize: "14px",
      lineHeight: "17px",
      // margin: '0',
      padding: "10px 12px",
    },
  },
  next: {
    border: "none",
    background: "#FCFCFC",
    borderRadius: "4px",
    right: "40%",
    width: "42px",
    height: "42px",

    [theme.breakpoints.down("lg")]: {
      width: "32px",
      height: "32px",
    },
    [theme.breakpoints.down("md")]: {
      width: "24px",
      height: "24px",
    },
  },
  prev: {
    border: "none",
    background: "#FCFCFC",
    borderRadius: "4px",
    left: "40%",
    width: "42px",
    height: "42px",

    [theme.breakpoints.down("lg")]: {
      width: "32px",
      height: "32px",
    },
    [theme.breakpoints.down("md")]: {
      width: "24px",
      height: "24px",
    },
  },
  icon: {
    [theme.breakpoints.down("lg")]: {
      width: "10.85px",
      height: "18.95px",
    },
    [theme.breakpoints.down("md")]: {
      width: "9.04px",
      height: "15.79px",
    },
  },

  active: {
    background: "#1A1C3A",
    border: "0.3px solid #1A1C3A",
    borderRadius: "4px",
    width: "30px",
    height: "3px",
    [theme.breakpoints.down("lg")]: {
      width: "20px",
    },
    [theme.breakpoints.down("md")]: {
      width: "15px",
    },
  },
  inactive: {
    border: "0.3px solid #1A1C3A",
    borderRadius: "4px",
    height: "3px",
  },
  indicator: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    position: "relative",
    width: "auto",
    height: "20px",
    margin: "10px",
    columnGap: "10px",
  },

  dot: {
    width: "20px",
    height: "3px",
    borderRadius: "4px",
    marginLeft: "5px",
    cursor: "pointer",
    [theme.breakpoints.down("lg")]: {
      width: "15px",
    },
    [theme.breakpoints.down("md")]: {
      width: "10px",
    },
  },
}));
