import React, { useContext, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useStyles } from './styles'
import { ResponsiveDialog } from '../../../components'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { LocaleContext } from '../../../ts/routing/LangRouter'
/* eslint-disable  no-unused-vars, no-debugger */

const Confirmation = ({
  tintedGlass,
  tintGlassId,
  email,
  sendForm,
  open,
  setOpen,
  additionalService,
  setValue,
  additionalServiceIds,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const location = useLocation()
  const { locale, setLocale } = useContext(LocaleContext)
  const [lang, setLang] = useState(locale)

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    setLang(locale)
  }, [locale])

  const allServiceIds = additionalService.map((el) => el.id)

  const selectedServiceIds = additionalServiceIds
  const serviceDescription = selectedServiceIds.map((id) => {
    const selectedService = additionalService.find(
      (service) => service.id === id,
    )
    return (
      <div key={id}>
        {selectedService ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              overflow: 'hidden', // Add this to hide overflowing text
            }}
          >
            <span
              style={{
                whiteSpace: 'nowrap', // Prevent text from wrapping
                textOverflow: 'ellipsis',
                overflow: 'hidden', // Hide overflowing text with ellipsis
              }}
              className={classes.additionalServices}
            >
              {selectedService?.description_en}{' '}
            </span>
            <Box className={classes.bold_title}>
              {selectedService?.discountPrice
                ? calculateDiscountPrice(
                    selectedService?.price,
                    selectedService?.discountPrice,
                  )
                : selectedService?.price}{' '}
              CHF
              {/* {selectedService?.price} CHF */}
            </Box>
          </Box>
        ) : (
          ''
        )}
      </div>
    )
  })

  const tintglassPrice = location?.state?.discountPrice
    ? Number(location?.state?.amount)
    : Number(location?.state?.price)
  const areAllSelectedServicesIncluded = selectedServiceIds.every((id) =>
    allServiceIds.includes(id),
  )

  let totalAdditionalPrice = 0

  // if (areAllSelectedServicesIncluded) {
  //   additionalService.forEach((service) => {
  //     if (selectedServiceIds.includes(service.id)) {
  //       totalAdditionalPrice += Number(service?.price)
  //       return totalAdditionalPrice
  //     }
  //   })
  // }
  if (areAllSelectedServicesIncluded) {
    additionalService.forEach((service) => {
      if (selectedServiceIds.includes(service.id)) {
        const servicePrice = service?.discountPrice
          ? calculateDiscountPrice(service?.price, service?.discountPrice)
          : service?.price

        totalAdditionalPrice += Number(servicePrice)
        return totalAdditionalPrice
      }
    })
  }

  const selectedTintGlass = tintedGlass?.find((item) => item.id == tintGlassId)

  function calculateDiscountPrice(originalPrice, discountPercentage) {
    const discountAmount = (originalPrice * discountPercentage) / 100
    const finalAmount = originalPrice - discountAmount

    return finalAmount || 0
  }
  const finalPrice =
    (location?.state
      ? tintglassPrice
      : selectedTintGlass?.discountPrice
      ? calculateDiscountPrice(
          selectedTintGlass?.price,
          selectedTintGlass?.discountPrice,
        )
      : selectedTintGlass?.price || 0) + Number(totalAdditionalPrice)

  useEffect(() => {
    setValue('email', email)
  }, [email, setValue])

  return (
    <Box className={classes.container}>
      <Box className={classes.padding}>
        <Box className={classes.wrapper}>
          <Box sx={{ fontSize: '18px' }}>
            <span className={classes.bold_title}>Service:</span>
            <span className={classes.left}>Tint Glass</span>
          </Box>
          <Box className={classes.bold_title}>
            {location?.state
              ? location?.state?.discountPrice
                ? Number(location?.state?.amount || 0)
                : Number(location?.state?.price || 0)
              : selectedTintGlass?.discountPrice
              ? calculateDiscountPrice(
                  selectedTintGlass?.price,
                  selectedTintGlass?.discountPrice,
                )
              : selectedTintGlass?.price || 0}{' '}
            CHF
          </Box>
        </Box>
        <Box className={classes.wrapper}>
          <Box
            sx={{
              fontSize: '18px',
              lineHeight: 'initial',
              display: 'flex',
              width: '100%',
            }}
            className={classes.service_wrapper}
          >
            <span className={classes.bold_title} style={{ width: '100%' }}>
              {' '}
              {t('appointment.additional_service')}:
            </span>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              {serviceDescription}
            </Box>
          </Box>
        </Box>
        <Box className={clsx(classes.bold_title, classes.flex)}>
          <Box>Total:</Box>
          <Box>{Number(finalPrice)} CHF</Box>
        </Box>

        {/* {serverError && <span className={classes.error}>{serverError}</span>} */}
      </Box>
      <Box className={classes.button_box}>
        <button onClick={sendForm} className={classes.btn}>
          {t('common.confirm')}
        </button>
      </Box>
      {open && (
        <ResponsiveDialog
          label={t('dialog.title')}
          onClose={handleClose}
          open={open}
          content={t(
            'Your appointment is confirmed! A confirmation e-mail has been sent to {{email}}.',
            { email: email },
          )}
        />
      )}
    </Box>
  )
}

export default Confirmation

Confirmation.propTypes = {
  state: PropTypes.any,
  email: PropTypes.any,
  tintedGlass: PropTypes.any,
  sendForm: PropTypes.any,
  open: PropTypes.any,
  setOpen: PropTypes.any,
  additionalService: PropTypes.any,
  setValue: PropTypes.any,
  additionalServiceIds: PropTypes.any,
  tintGlassId: PropTypes.any,
}
