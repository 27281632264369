import { Box } from '@mui/material'
import React from 'react'
import invisible from '../../assets/images/protection/invisible.svg'
import ultimate from '../../assets/images/protection/ultimate.svg'
import quality from '../../assets/images/protection/quality.svg'
import { useStyles } from './styles'


const Values = () => {
    const classes = useStyles()
    return (
        <Box sx={{
            display: "flex", justifyContent: 'space-around', width: '100%', height: '100%',
            alignItems: 'baseline'
        }}>
            <img src={invisible} alt="invisible" className={classes.invisible} />
            <img src={ultimate} alt="ultimate" className={classes.ultimate} />
            <img src={quality} alt="quality" className={classes.quality} />
        </Box>
    )
}

export default Values