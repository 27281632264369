import React, { useContext, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useStyles } from './styles'
import Values from './Values'
import bmw from '../../assets/images/protection/bmw.svg'
import ferrari from '../../assets/images/protection/ferrari.png'
import printer from '../../assets/images/protection/printer.svg'
import Partners from './Partners'
import ImageCarousel from '../../components/ImageCarousel'
import clsx from 'clsx'
import { slidesData } from '../../mocks/protection'
import { LocaleContext } from '../../ts/routing/LangRouter'
import { Link } from 'react-router-dom'
import { getContactPageUrl } from '../../ts/routing/routingConstants/AppUrls'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

const Protection = () => {
  const classes = useStyles()
  const { locale } = useContext(LocaleContext)
  const { t } = useTranslation()
  const [partnerIds, setPartnerIds] = useState([])

  const fetchImageById = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}coverbox/public/getPartnerImagesByImageType`,
        {
          params: {
            imageType: 'PROCESSING',
          },
        },
      )

      const partners = response.data
      localStorage.setItem('protectionPartnets', JSON.stringify(partnerIds))
      setPartnerIds(partners)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchImageById()
  }, [])

  return (
    <div
      style={{
        height: '100%',
        marginTop: '150px',
      }}
    >
      <ImageCarousel slides={slidesData} />
      <Box className={classes.background}>
        <Box className={clsx(classes.titleWrap, classes.margin)}>
          {/* Paint Protective */}
          {t('ppf_protection.paint_protective')}
          <span className={classes.highlight}>
            {t('ppf_protection.film')}
            {/* Film */}
          </span>
        </Box>
        <Box className={classes.wrapper_first}>
          <img className={classes.protective_img1} src={bmw} alt="bmw" />
          <Box className={classes.textWrapper}>
            <Box className={classes.protective_content}>
              {t('ppf_protection.first_content')}
              {/* Virtual invisibility is just one of the many benefits of this
              durable body protection film. */}
            </Box>
            <Link to={getContactPageUrl(locale)} className={classes.contact}>
              <button className={classes.btn}>Contact</button>
            </Link>
          </Box>
        </Box>

        <Box className={classes.second_wrapper}>
          <Box className={classes.textWrapper}>
            <Box className={classes.content}>
              {t('ppf_protection.second_content')}
            </Box>
            <Link to={getContactPageUrl(locale)} className={classes.contact}>
              <button className={classes.btn}>Contact</button>
            </Link>
          </Box>
          <img
            src={ferrari}
            alt="ferrari"
            className={classes.protective_img2}
          />
        </Box>
        <Box className={classes.titleWrapper}>
          {t('common.our')}
          {/* Our */}
          <span className={classes.highlight}>
            {/* Values */}
            {t('common.values')}
          </span>
        </Box>
        <Box className={classes.value_container}>
          <Values />
        </Box>

        <Box className={classes.computer_cut}>
          <img className={classes.printer} src={printer} alt="" />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              rowGap: '40px',
              flexDirection: 'column',
            }}
          >
            <Box className={classes.titleWrap}>
              {t('computer_cut.computer')}
              {/* Computer */}
              <span className={classes.highlight}>
                {t('computer_cut.cut')}
                {/* Cut */}
              </span>
            </Box>
            <Box className={classes.computer_content}>
              {t('computer_cut.content')}
            </Box>
          </Box>
        </Box>
        {partnerIds.length > 0 && (
          <>
            <Box className={classes.titleWrapper}>
              {t('common.our')}
              <span className={classes.highlight}>{t('common.partners')}</span>
            </Box>
            <Box className={classes.protectionPartners}>
              <Partners partners={partnerIds} />
            </Box>
          </>
        )}
      </Box>
    </div>
  )
}

export default Protection
