import React, { useContext } from 'react'
import { calculateYearsList } from './services/generateYearService'
import { Context } from '../../container/DatePicker'

function YearPicker() {
  let { date, setDate } = useContext(Context)
  let yearsList = calculateYearsList(
    'param',
    new Date(date.dateTime).getFullYear(),
  )

  const setYear = (e, year) => {
    return setDate({
      dateTime: new Date(date.dateTime.setFullYear(year)),
      isDaysList: true,
      isMonthsList: false,
      isYearsList: false,
    })
  }

  return (
    <div className="year-grid-container">
      {yearsList.map((year) => {
        return (
          <span
            className="year-grid-item"
            key={year}
            onClick={(e) => setYear(e, year)}
            style={{
              'background': year == date.dateTime.getFullYear() ? '#1A1C3A' : '',
              'color': year == date.dateTime.getFullYear() ? '#FCFCFC' : '#1A1C3A'
            }}
          >
            {year}
          </span>
        )
      })}
    </div>
  )
}

export default YearPicker
