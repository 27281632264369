import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  gallery_wrapper: {
    display: "flex",
    flexWrap: "wrap",
  },

  gallery_column: {
    // -ms-flex: 25%,
    flex: "25%",
    maxWidth: "100%",
    [theme.breakpoints.down(800)]: {
      flex: "50%",
      maxWidth: "50%",
    },
  },

  width_50: {
    width: "50%",
  },
  width_100: {
    width: "100%",
  },

  gallery_container: {
    [theme.breakpoints.down(800)]: {
      marginTop: "30px",
    },
  },

  minHeight: {
    height: "370px",
    [theme.breakpoints.down("lg")]: {
      height: "190px",
    },
    [theme.breakpoints.down("md")]: {
      height: "95px",
    },
  },

  maxHeight: {
    height: "720px",
    [theme.breakpoints.down("lg")]: {
      height: "380px",
    },
    [theme.breakpoints.down("md")]: {
      height: "190px",
    },
  },
}));
