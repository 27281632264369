import React, { useState } from 'react'
import { Box, TextField } from '@mui/material'
import call from '../../assets/icons/call.svg'
import email from '../../assets/icons/email.svg'
import location from '../../assets/icons/location.svg'
import time from '../../assets/icons/time.svg'
import { useStyles } from './styles'
import clsx from 'clsx'
import contact_lg from '../../assets/images/background/contact_bg_lg.png'
import contact_md from '../../assets/images/background/contact_bg_md.png'
import contact_sm from '../../assets/images/background/contact_bg_sm.png'
import { useTranslation } from 'react-i18next'
import { ResponsiveDialog } from '../../components'
import { contactSchema } from '../../utils/formValidation'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { api } from '../../configs'
import Cookies from 'universal-cookie'
/* eslint-disable  no-unused-vars, no-debugger */

const Contact = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      phoneNumber: '',
      email: '',
      carModel: '',
      message: '',
    },
    mode: 'all',
    resolver: yupResolver(contactSchema),
  })

  const name = watch('name')
  const phoneNumber = watch('phoneNumber')
  const mail = watch('email')
  const carModel = watch('carModel')
  const message = watch('message')

  const handleClose = () => {
    setOpen(false)
  }

  const cookies = new Cookies()

  const onSubmit = async () => {
    try {
      const state = {
        name: name,
        phoneNumber: phoneNumber,
        email: mail,
        carModel: carModel,
        message: message,
      }
      const xsrfToken = cookies.get('XSRF-TOKEN')
      const response = await api.post('coverbox/public/contact', state, {
        headers: {
          'X-XSRF-TOKEN': xsrfToken,
        },
      })

      if (response.status === 200) {
        setOpen(true)
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // setServerError('Invalid username or password.')
      } else {
        console.log(error)
      }
    }
  }
  return (
    <Box
      className={classes.container}
      sx={{
        backgroundImage: {
          xs: `url(${contact_sm})`,
          md: `url(${contact_md})`,
          lg: `url(${contact_lg})`,
        },
        backgroundPosition: {
          xs: 'left 100% bottom 12%',
          md: 'left 100% bottom 10%',
          lg: 'left 100% bottom 4%',
        },
      }}
    >
      <Box className={classes.titleWrapper}>
        {t('contact.contact')}
        <span className={classes.highlight}>{t('contact.us')}</span>
      </Box>
      <Box className={classes.contactWrapper}>
        <Box className={clsx(classes.flex, classes.call)}>
          <img src={call} alt="call" className={classes.img} />
          <span className={classes.text}> +4 176 463 78 30 </span>
        </Box>
        <Box className={clsx(classes.flex, classes.location)}>
          <img src={location} alt="location" className={classes.img} />
          <span className={clsx(classes.text, classes.location)}>
            Switzerland, Nyon city
          </span>
        </Box>
        <Box className={clsx(classes.flex, classes.email)}>
          <img src={email} alt="email" className={classes.img} />
          <span className={classes.text}> info@arscustom.ch </span>
        </Box>
        <Box className={clsx(classes.flex, classes.time)}>
          <img src={time} alt="time" className={classes.img} />
          <span className={clsx(classes.text, classes.time)}>
            {t('contact.hour')}
          </span>
        </Box>
      </Box>

      <Box className={classes.form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label={t('form.first_name')}
            type="text"
            fullWidth
            {...register('name')}
            error={!!errors.name}
            helperText={errors.name?.message}
            className={classes.textfield}
          />

          <TextField
            label={t('form.phone_number')}
            type="number"
            fullWidth
            {...register('phoneNumber')}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber?.message}
            className={classes.textfield}
          />

          <TextField
            label={t('form.mail')}
            type="text"
            fullWidth
            {...register('email')}
            error={!!errors.email}
            helperText={errors.email?.message}
            className={classes.textfield}
          />

          <TextField
            label={t('form.car_model')}
            type="text"
            fullWidth
            {...register('carModel')}
            error={!!errors.carModel}
            helperText={errors.carModel?.message}
            className={classes.textfield}
          />

          <TextField
            label={t('form.message')}
            type="text"
            fullWidth
            multiline
            rows={3}
            {...register('message')}
            error={!!errors.message}
            helperText={errors.message?.message}
            className={classes.textfield}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button type="submit" className={classes.btn}>
              {t('common.confirm')}
            </button>
          </Box>
        </form>
      </Box>

      {open && (
        <ResponsiveDialog
          label="Thank you"
          onClose={handleClose}
          onconfirm={open}
          content={t(
            'Your message was sent! Our agent will contact to you as soon as possible.',
          )}
          // content={t(
          //   'Your appointment is confirmed! A confirmation e-mail has been sent to {{email}}.',
          //   { email: state.email },
          // )}
        />
      )}
    </Box>
  )
}

export default Contact
