import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { useStyles } from './styles'
import prev from '../../../assets/icons/left.png'
import next from '../../../assets/icons/right.png'
import './review.css'
import profile from '../../../assets/images/profile_blanl.png'

const ReviewComponent = ({ reviews }) => {
  // const [reviews, setReviews] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const classes = useStyles()

  const handleClickPrev = () => {
    setCurrentIndex((currentIndex - 2 + reviews.length) % reviews.length)
  }

  const handleClickNext = () => {
    setCurrentIndex((currentIndex + 2) % reviews.length)
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
        {reviews.length > 0 &&
          reviews.slice(currentIndex, currentIndex + 2).map((review, index) => (
            <Box key={index} className={classes.wrapper}>
              <Box className={classes.profile}>
                <img
                  className={classes.profile_inner}
                  src={`${process.env.REACT_APP_API_KEY}coverbox/public/files/customersay/image?customerSayId=${review.id}`}
                  alt={review.name}
                  // className={classes.profile}
                  onError={(e) => {
                    e.target.src = profile
                  }}
                />
              </Box>
              <Box className={classes.card}>
                <Box className={classes.title}>{review.name}</Box>
                <Box className={classes.review}>{review.comment}</Box>
              </Box>
            </Box>
          ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <button onClick={handleClickPrev} className={classes.prev}>
          <img src={prev} alt="prev" className={classes.icon} />
        </button>
        <Box className={classes.indicator}>
          {[...Array(Math.ceil(reviews.length / 2))].map((_, index) => (
            <span
              key={index}
              className={
                currentIndex === index * 2 ? classes.active : classes.inactive
              }
              onClick={() => setCurrentIndex(index * 2)}
            >
              <Box className={classes.dot}></Box>
            </span>
          ))}
        </Box>
        <button onClick={handleClickNext} className={classes.next}>
          <img src={next} alt="next" className={classes.icon} />
        </button>
      </Box>
    </Box>
  )
}

ReviewComponent.propTypes = {
  slides: PropTypes.any,
  reviews: PropTypes.any,
}

export default ReviewComponent
