import React from 'react'
import Home from '../../../pages/Home'
import TintedGlass from '../../../pages/TintedGlass'
import AboutUs from '../../../pages/AboutUs'
import Covering from '../../../pages/Covering'
import Protection from '../../../pages/Protection'
import Contact from '../../../pages/Contact'
import Gallery from '../../../pages/Gallery'

import {
  getAboutUsPageUrl,
  getCoveringPageUrl,
  getProtectionPageUrl,
  getTintedGlassPageUrl,
  getGalleryPageUrl,
  getContactPageUrl,
  getHomePageUrl,
  getBasePageUrl,
  getAppointmentPageUrl,
  getRatesAppointPageUrl,
  getSpecialCarAppointPageUrl,
} from './AppUrls'

import PublicRouteGuard from '../guards/PublicRouteGuard'
import Appointment from '../../../pages/Appointment'

const publicRoutes = [
  {
    element: (
      <PublicRouteGuard>
        <TintedGlass />
      </PublicRouteGuard>
    ),
    path: (locale) => getTintedGlassPageUrl(locale),
  },
  {
    element: (
      <PublicRouteGuard>
        <Appointment />
      </PublicRouteGuard>
    ),
    path: (locale) => getAppointmentPageUrl(locale),
  },

  {
    element: (
      <PublicRouteGuard>
        <Appointment />
      </PublicRouteGuard>
    ),
    path: (locale) => getSpecialCarAppointPageUrl(locale),
  },

  {
    element: (
      <PublicRouteGuard>
        <Appointment />
      </PublicRouteGuard>
    ),
    path: (locale) => getRatesAppointPageUrl(locale),
  },
  {
    element: (
      <PublicRouteGuard>
        <Protection />
      </PublicRouteGuard>
    ),
    path: (locale) => getProtectionPageUrl(locale),
  },
  {
    element: (
      <PublicRouteGuard>
        <Covering />
      </PublicRouteGuard>
    ),
    path: (locale) => getCoveringPageUrl(locale),
  },
  {
    element: (
      <PublicRouteGuard>
        <Gallery />
      </PublicRouteGuard>
    ),
    path: (locale) => getGalleryPageUrl(locale),
  },
  {
    element: (
      <PublicRouteGuard>
        <AboutUs />
      </PublicRouteGuard>
    ),
    path: (locale) => getAboutUsPageUrl(locale),
  },
  {
    element: (
      <PublicRouteGuard>
        <Contact />
      </PublicRouteGuard>
    ),
    path: (locale) => getContactPageUrl(locale),
  },
]

const privateRoutes = [
  {
    element: (
      <PublicRouteGuard>
        <Home />
      </PublicRouteGuard>
    ),
    path: (locale) => getBasePageUrl(locale),
  },
  {
    element: (
      <PublicRouteGuard>
        <Home />
      </PublicRouteGuard>
    ),
    path: (locale) => getHomePageUrl(locale),
  },
]

export const allRoutes = [...publicRoutes, ...privateRoutes]

export const headerPublicRoutes = (t) => [
  {
    label: t('header.tintedGlass'),
    path: (locale) => getTintedGlassPageUrl(locale),
  },
  {
    label: t('header.protection'),
    path: (locale) => getProtectionPageUrl(locale),
  },
  {
    label: t('header.covering'),
    path: (locale) => getCoveringPageUrl(locale),
  },
  {
    label: t('header.gallery'),
    path: (locale) => getGalleryPageUrl(locale),
  },
  {
    label: t('header.aboutUs'),
    path: (locale) => getAboutUsPageUrl(locale),
  },
  {
    label: t('header.contact'),
    path: (locale) => getContactPageUrl(locale),
  },
]

export const headerPrivateRoutes = (t) => [
  {
    label: t('header.tintedGlass'),
    path: (locale) => getTintedGlassPageUrl(locale),
  },
  {
    label: t('header.contact'),
    path: (locale) => getContactPageUrl(locale),
  },
  {
    label: t('header.protection'),
    path: (locale) => getProtectionPageUrl(locale),
    // permissions: 'customerCare',
  },
  {
    label: t('header.gallery'),
    path: (locale) => getGalleryPageUrl(locale),
    // permissions: 'customerCare',
  },
]

export const headerAuthRoutes = (t) => [
  {
    label: t('header.login'),
    path: (locale) => getBasePageUrl(locale),
  },
]
