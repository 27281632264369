import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    // justifyContent: "space-evenly",
    flexWrap: "wrap",
    columnGap: "50px",
    rowGap: "70px",
    alignItems: "self-end",
    padding: "5%",
    justifyContent: "space-between",

    [theme.breakpoints.down("md")]: {
      justifyContent: "space-evenly",
    },
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "25%",
    rowGap: "20px",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.down(1280)]: {
      width: "40%",
    },
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down(600)]: {
      width: "100%",
      margin: "5%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "5%",
    },
  },
  box: {
    width: "42px",
    height: "34px",
    background: "rgba(252, 252, 252, 0.3)",
    border: " 0.2px solid #1A1C3A",
    borderRadius: "20px 2px",
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "16px",
    color: "#1A1C3A",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "auto",
  },
  btn_box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  button: {
    padding: "18px 48px",
    background: "#FCFCFC",
    border: "1px solid #1A1C3A",
    borderRadius: " 2px 20px",
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "21px",
    textTransform: "uppercase",
    color: "#1A1C3A",
    "&:hover": {
      backgroundImage: `linear-gradient(90deg, rgba(26, 28, 58, 0.9), rgba(252, 252, 252, 0.8))`,
    },
    [theme.breakpoints.down("lg")]: {
      padding: "16px 36px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "14px 24px",
    },
  },
  bookAppointment: {
    textDecoration: "none",
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "21px",
    textTransform: "uppercase",
    color: "#1A1C3A",
    cursor: "pointer",

    "&:hover": {
      color: "black",
    },

    [theme.breakpoints.down("lg")]: {
      fontSize: "16px",
      lineHeight: "19px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      lineHeight: "17px",
    },
  },
  car: {
    width: "100%",
    height: "100%",
    maxWidth: "400px",
    maxHeight: "180px",
  },
  content: {
    maxHeight: "99px",
    height: "100%",
  },
  title: {
    fontFamily: "Rubik",
    fontWeight: "800",
    fontSize: "24px",
    lineHeight: "28px",
    textTransform: "uppercase",
    color: "#1A1C3A",
    textAlign: "center",

    [theme.breakpoints.down("lg")]: {
      fontSize: "22px",
      lineheight: "26px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
      lineheight: "24px",
    },
  },
  discountedPrice: {
    fontFamily: "Rubik",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "24px",
    textDecorationLine: "line-through",
    color: "#C51A20",

    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
      lineheight: "21px",
    },
  },
  newPrice: {
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "24px",
    color: "#1A1C3A",

    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
      lineheight: "21px",
    },
  },
}));
