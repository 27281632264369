import React, { useContext } from 'react'
import { Box } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { useStyles } from './styles'
// import websoft from '../../assets/images/websoft.svg'
import { useTranslation } from 'react-i18next'
import { LocaleContext } from '../../ts/routing/LangRouter'
import { headerPublicRoutes } from '../../ts/routing/routingConstants/RoutesConfig'
import RestrictedSection from '../../ts/routing/routingComponents/RestrictedSection'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
/* eslint-disable  no-unused-vars, no-debugger */

const Footer = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { locale, setLocale } = useContext(LocaleContext)

  const renderLink = ({ to, label, key, permissions }) => {
    const link = (
      <NavLink
        key={permissions ? undefined : key}
        to={to}
        activeClassName={classes.active_link}
        className={(navData) =>
          navData.isActive ? classes.active_link : classes.link
        }
        onClick={() => window.scrollTo(0, 0)}
      >
        {label}
      </NavLink>
    )
    if (permissions) {
      return (
        <RestrictedSection key={key} requiredPermissions={permissions}>
          {link}
        </RestrictedSection>
      )
    }
    return link
  }

  const renderLinks = (list) => {
    return list.map((el, i) =>
      renderLink({
        to: el.path(locale),
        label: el.label,
        key: i,
        permissions: el.permissions,
      }),
    )
  }

  return (
    <>
      <Box className={classes.background}>
        <Box className={classes.container}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              flexDirection: 'column',
              height: '50%',
            }}
          >
            <Box className={classes.icon_wrapper}>
              <FacebookIcon className={classes.facebook} />
              <InstagramIcon className={classes.instagram} />
            </Box>
            <Box className={classes.navbar}>
              <Box className={classes.link_wrapper}>
                {renderLinks(headerPublicRoutes(t))}
              </Box>
            </Box>
          </Box>
          <Box className={classes.contact}>
            <Box sx={{ width: { xs: '0%', sm: '0%', md: '35%' } }}></Box>
            <Box
              sx={{
                width: { xs: '100%', sm: '100%', md: '100%' },
                textAlign: 'center',
              }}
            >
              © ARS CUSTOM 2023, All Rights Reserved
            </Box>
            <Box
              sx={{
                width: { xs: '100%', sm: '100%', md: '33%' },
                textAlign: { xs: 'center', md: 'end' },
              }}
              className={classes.paddingRight}
            >
              {' '}
              {/* <img src={websoft} alt="" /> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Footer
