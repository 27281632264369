import React, { useContext, useEffect, useState } from 'react'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import close from '../../../assets/icons/close.svg'
import logo from '../../../assets/images/logo.svg'
import menu from '../../../assets/icons/burger_menu.svg'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { LocaleContext } from '../../routing/LangRouter'
import RestrictedSection from '../../routing/routingComponents/RestrictedSection'
import { headerPublicRoutes } from '../../routing/routingConstants/RoutesConfig'
import {
  MenuItem,
  Select,
  Slide,
  Toolbar,
  useScrollTrigger,
} from '@mui/material'
import arrow from '../../../assets/icons/right-arrow.svg'
import menuBg from '../../../assets/images/background/mobile_menu_bg.svg'
import './styles.css'
import { api } from '../../../configs'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const Header = ({ window }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { locale, setLocale } = useContext(LocaleContext)
  const { pathname } = useLocation()
  const [lang, setLang] = useState(locale)
  const trigger = useScrollTrigger()
  const [mobileOpen, setMobileOpen] = useState(false)
  const [imgSrc, setImgSrc] = useState('')

  useEffect(() => {
    setLang(locale)
  }, [locale])

  useEffect(() => {
    api
      .get('coverbox/public/files/logo/image', {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'image/png' })
        const imageUrl = URL.createObjectURL(blob)
        setImgSrc(imageUrl)
      })
      .catch((error) => {
        console.log('Error fetching image:', error)
      })
  }, [])

  const languageHandler = ({ target: { value } }) => {
    setLocale(value)
    setLang(value)
  }

  // const handleOptionChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }

  const mobileLink = ({ to, label, key, permissions }) => {
    const link = (
      <NavLink
        key={permissions ? undefined : key}
        to={to}
        activeclassname="active-menu-link"
        className={(navData) =>
          navData.isActive ? 'active-menu-link' : 'menu-link'
        }
      >
        {label}
      </NavLink>
    )
    if (permissions) {
      return (
        <RestrictedSection key={key} requiredPermissions={permissions}>
          {link}
        </RestrictedSection>
      )
    }
    return link
  }

  const renderMobileLinks = (list) => {
    return list.map((el, i) =>
      mobileLink({
        to: el.path(locale),
        label: el.label,
        key: i,
        permissions: el.permissions,
      }),
    )
  }

  const renderLink = ({ to, label, key, permissions }) => {
    const link = (
      <NavLink
        key={permissions ? undefined : key}
        to={to}
        activeclassname="active-link"
        className={(navData) => (navData.isActive ? 'active-link' : 'nav-link')}
      >
        {label}
      </NavLink>
    )
    if (permissions) {
      return (
        <RestrictedSection key={key} requiredPermissions={permissions}>
          {link}
        </RestrictedSection>
      )
    }
    return link
  }

  const renderLinks = (list) => {
    return list.map((el, i) =>
      renderLink({
        to: el.path(locale),
        label: el.label,
        key: i,
        permissions: el.permissions,
      }),
    )
  }

  const drawer = (
    <Box
      sx={{
        backgroundImage: `url(${menuBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 0px center',
      }}
    >
      <Box
        sx={{
          background:
            'linear-gradient(109.51deg, #1A1C3A 0.56%, rgba(252, 252, 252, 0) 63.08%)',
          opacity: '0.4',
          width: '100%',
        }}
      >
        <Box sx={{ marginTop: '32px', marginRight: '32px', direction: 'rtl' }}>
          <img
            onClick={handleDrawerToggle}
            src={close}
            alt="close"
            className={classes.close}
          />
        </Box>

        <Box onClick={handleDrawerToggle} className={classes.menu_wrapper}>
          {renderMobileLinks(headerPublicRoutes(t))}
          <Box>
            <Select
              IconComponent={KeyboardArrowDownIcon}
              className={classes.select}
              onChange={languageHandler}
              defaultValue={lang}
              MenuProps={{
                classes: { paper: classes.paper },

                anchorOrigin: {
                  vertical: 0,
                  horizontal: 0,
                },
                transformOrigin: {
                  vertical: 0,
                  horizontal: 0,
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem
                className={classes.menuItem}
                classes={{ selected: classes.selected }}
                value="en"
              >
                En
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                classes={{ selected: classes.selected }}
                value="fr"
              >
                Fr
              </MenuItem>
            </Select>
          </Box>
        </Box>
      </Box>
    </Box>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  const getPath = (path) => {
    switch (path) {
      case `/${lang}/`:
        return null
      case `/${lang}/ppf-protection`:
        return t('header.protection')
      case `/${lang}/about-us`:
        return t('header.aboutUs')
      case `/${lang}/covering`:
        return t('header.covering')
      case `/${lang}/gallery`:
        return t('header.gallery')
      case `/${lang}/contact`:
        return t('header.contact')
      case `/${lang}/tinted-glass`:
        return t('header.tintedGlass')
      case `/${lang}/tinted-glass/appointment`:
        return (
          <>
            <Link
              to={`/${lang}/tinted-glass`}
              className={classes.textDecoration}
            >
              {t('header.tintedGlass')} {/* Tinted Glass */}
            </Link>
            <img src={arrow} alt="" className={classes.icon} />
            {t('header.book_appointment')}
          </>
        )
      default:
        return path
    }
  }

  return (
    <Box>
      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar
          component="nav"
          sx={{
            marginLeft: '150px',
            background: '#1A1C3A',
            height: '99px',
            justifyContent: 'space-around',
          }}
        >
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <img
              src={logo || imgSrc}
              alt="ARS CUSTOM"
              className={classes.logo}
              onClick={() => navigate(`/${lang}/home`)}
            />
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ marginLeft: 'auto', mr: 2, display: { lg: 'none' } }}
            >
              <img
                src={menu}
                alt="menu"
                style={{ width: '42px', height: '42px' }}
              />
            </IconButton>
            <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
              <nav className={classes.header_container}>
                {renderLinks(headerPublicRoutes(t))}
              </nav>
              <Box
                sx={{
                  width: '100%',
                  border: '1px solid #FCFCFC',
                  display: 'flex',
                  position: 'relative',
                  bottom: '1px',
                }}
              ></Box>
            </Box>
            <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
              <Select
                IconComponent={KeyboardArrowDownIcon}
                className={classes.select}
                onChange={languageHandler}
                defaultValue={lang}
                MenuProps={{
                  classes: { paper: classes.paper },
                  anchorOrigin: {
                    vertical: 0,
                    horizontal: 0,
                  },
                  transformOrigin: {
                    vertical: 0,
                    horizontal: 0,
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem
                  className={classes.menuItem}
                  classes={{ selected: classes.selected }}
                  value="en"
                >
                  En
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  classes={{ selected: classes.selected }}
                  value="fr"
                >
                  Fr
                </MenuItem>
              </Select>
            </Box>
          </Toolbar>
        </AppBar>
      </Slide>
      <Box component="nav">
        <Drawer
          anchor="right"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: '100%',
              background: '#1A1C3A',
              //  minWidth: drawerWidth,
              // background: 'linear-gradient(109.51deg, #1A1C3A 0.56%, rgba(252, 252, 252, 0) 63.08%)',
              // opacity: '0.4'
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      {pathname == `/${lang}/` || pathname == `/${lang}/home` ? null : (
        <Box className={classes.routes}>
          <Box
            className={classes.navigation}
            onClick={() => navigate(`/${lang}/home`)}
          >
            {t('header.home')} {/* Home */}
          </Box>
          <img src={arrow} alt="" className={classes.icon} />
          <Box className={classes.navigation}>{getPath(pathname)}</Box>
        </Box>
      )}
    </Box>
  )
}

export default Header

Header.propTypes = {
  window: PropTypes.func,
  path: PropTypes.any,
  setPath: PropTypes.func,
}
