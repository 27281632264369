import React, { useEffect, useState } from 'react'
import '../styles.css'
import { Grid } from '@mui/material'
import { useStyles } from './styles'
import axios from 'axios'

const ImgGallery = () => {
  const classes = useStyles()
  const [imageIds, setImageIds] = useState([])
  const [imageObjects, setImageObjects] = useState([])

  const fetchImageById = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}coverbox/public/getImageIdsByImageType`,
        {
          params: {
            imageType: 'COVERING',
          },
        },
      )

      const uniqueImageIds = Array.from(new Set(response.data))
      localStorage.setItem('covering', JSON.stringify(uniqueImageIds))
      setImageIds(uniqueImageIds)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchImageById()
  }, [])

  useEffect(() => {
    const getImages = async () => {
      try {
        const newImageObjects = []
        for (const el of imageIds) {
          const res = await axios.get(
            `${process.env.REACT_APP_API_KEY}coverbox/public/files/other/image`,
            {
              params: {
                imageId: el,
              },
              responseType: 'blob',
            },
          )
          const imageBlob = res.data
          const imageUrl = URL.createObjectURL(imageBlob)
          newImageObjects.push({ id: el, src: imageUrl })
        }

        setImageObjects(newImageObjects)
      } catch (error) {
        console.error(error)
      }
    }

    getImages()
  }, [imageIds])

  const imagePairs = imageObjects

  const groupIntoPairs = (array, groupSize) => {
    const pairs = []
    for (let i = 0; i < array.length; i += groupSize) {
      pairs.push(array.slice(i, i + groupSize))
    }
    return pairs
  }

  const pairs = groupIntoPairs(imagePairs, 2)

  return (
    <Grid container>
      {pairs.map((pair, index) => {
        let height1 = index % 2 === 0 ? classes.minHeight : classes.maxHeight
        let height2 = index % 2 === 0 ? classes.maxHeight : classes.minHeight

        return (
          <Grid key={index} item sm={6}>
            <img
              src={pair[0].src}
              style={{
                width: '100%',
                objectFit: 'cover',
                height: `${height1}px`,
              }}
            />
            {pair.length > 1 && (
              <img
                src={pair[1].src}
                style={{
                  width: '100%',
                  objectFit: 'cover',
                  height: `${height2}px`,
                }}
              />
            )}
          </Grid>
        )
      })}
    </Grid>
  )
}

export default ImgGallery
