import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import axios from 'axios'
import { useStyles } from './styles'
/* eslint-disable  no-unused-vars, no-debugger */

const ImgGallery = () => {
  const [imageIds, setImageIds] = useState([])
  const [imageObjects, setImageObjects] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const classes = useStyles()

  const fetchImageById = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}coverbox/public/getImageIdsByImageType`,
        {
          params: {
            imageType: 'PROCESSING',
          },
        },
      )

      const uniqueImageIds = Array.from(new Set(response.data))
      localStorage.setItem('processing', JSON.stringify(uniqueImageIds))
      setImageIds(uniqueImageIds)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchImageById()
  }, [])

  useEffect(() => {
    const getImages = async () => {
      try {
        const newImageObjects = []
        for (const el of imageIds) {
          const res = await axios.get(
            `${process.env.REACT_APP_API_KEY}coverbox/public/files/other/image`,
            {
              params: {
                imageId: el,
              },
              responseType: 'blob',
            },
          )
          const imageBlob = res.data
          const imageUrl = URL.createObjectURL(imageBlob)
          newImageObjects.push({ id: el, src: imageUrl })
        }

        setImageObjects(newImageObjects)
      } catch (error) {
        console.error(error)
      }
    }

    getImages()
  }, [imageIds])
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box
          sx={{
            width: '50%',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
          }}
        >
          {imageObjects[currentIndex] && (
            <Box className={classes.currentIndexMin}>
              <img
                className={classes.percent_100}
                src={imageObjects[currentIndex].src}
                alt={`Image ${imageObjects[currentIndex].id}`}
              />
            </Box>
          )}
          {imageObjects[currentIndex + 1] && (
            <Box className={classes.currentIndexMin}>
              <img
                className={classes.percent_100}
                src={imageObjects[currentIndex + 1].src}
                alt={`Image ${imageObjects[currentIndex + 1].id}`}
              />
            </Box>
          )}
          {imageObjects[currentIndex + 2] && (
            <Box className={classes.currentIndexMax}>
              <img
                className={classes.percent_100}
                src={imageObjects[currentIndex + 2].src}
                alt={`Image ${imageObjects[currentIndex + 2].id}`}
              />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            width: '50%',
            height: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
          }}
        >
          {imageObjects[currentIndex + 3] && (
            <Box className={classes.currentIndexMax}>
              <img
                className={classes.percent_100}
                src={imageObjects[currentIndex + 3].src}
                alt={`Image ${imageObjects[currentIndex + 3].id}`}
              />
            </Box>
          )}
          {imageObjects[currentIndex + 4] && (
            <Box className={classes.currentIndexMin}>
              <img
                className={classes.percent_100}
                src={imageObjects[currentIndex + 4].src}
                alt={`Image ${imageObjects[currentIndex + 4].id}`}
              />
            </Box>
          )}
          {imageObjects[currentIndex + 5] && (
            <Box className={classes.currentIndexMin}>
              <img
                className={classes.percent_100}
                src={imageObjects[currentIndex + 5].src}
                alt={`Image ${imageObjects[currentIndex + 5].id}`}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box
          sx={{
            width: '50%',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
          }}
        >
          {imageObjects[currentIndex + 6] && (
            <Box className={classes.currentIndexMin}>
              <img
                className={classes.percent_100}
                src={imageObjects[currentIndex + 6].src}
                alt={`Image ${imageObjects[currentIndex + 6].id}`}
              />
            </Box>
          )}
          {imageObjects[currentIndex + 7] && (
            <Box className={classes.currentIndexMin}>
              <img
                className={classes.percent_100}
                src={imageObjects[currentIndex + 7].src}
                alt={`Image ${imageObjects[currentIndex + 7].id}`}
              />
            </Box>
          )}
          {imageObjects[currentIndex + 8] && (
            <Box className={classes.currentIndexMax}>
              <img
                className={classes.percent_100}
                src={imageObjects[currentIndex + 8].src}
                alt={`Image ${imageObjects[currentIndex + 8].id}`}
              />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            width: '50%',
            height: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
          }}
        >
          {imageObjects[currentIndex + 9] && (
            <Box className={classes.currentIndexMax}>
              <img
                className={classes.percent_100}
                src={imageObjects[currentIndex + 9].src}
                alt={`Image ${imageObjects[currentIndex + 9].id}`}
              />
            </Box>
          )}
          {imageObjects[currentIndex + 10] && (
            <Box className={classes.currentIndexMin}>
              <img
                className={classes.percent_100}
                src={imageObjects[currentIndex + 10].src}
                alt={`Image ${imageObjects[currentIndex + 10].id}`}
              />
            </Box>
          )}
          {imageObjects[currentIndex + 11] && (
            <Box
              sx={{
                width: '100%',
                height: '370px',
              }}
            >
              <img
                className={classes.percent_100}
                src={imageObjects[currentIndex + 11].src}
                alt={`Image ${imageObjects[currentIndex + 11].id}`}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default ImgGallery
