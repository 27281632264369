import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useStyles } from './styles'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
// import { backPercentage, frontPercentage } from '../../../utils/selectOptions'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Controller } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { LocaleContext } from '../../../ts/routing/LangRouter'
import { PatternFormat } from 'react-number-format'

// if (onChange) onChange(event.target.value?.replaceAll(/[+() -]/g, ''));

const Information = ({
  errors,
  register,
  onErrorsChange,
  control,
  specialCars,
  setValue,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const location = useLocation()
  const { state } = location
  const { locale } = useContext(LocaleContext)
  const [lang, setLang] = useState(locale)

  useEffect(() => {
    onErrorsChange(errors)
  }, [errors, onErrorsChange])

  useEffect(() => {
    setLang(locale)
  }, [locale])

  const handlePhoneChange = (formattedValue) => {
    if (typeof formattedValue === 'string') {
      setValue('phoneNumber', formattedValue)
    }
  }

  return (
    <Box className={classes.bottom}>
      <Grid container spacing={{ xs: 1, md: 1 }} className={classes.grid}>
        <Grid item lg={5.5} md={12} className={classes.padding}>
          <TextField
            label={t('form.first_name')}
            type="text"
            fullWidth
            {...register('firstName')}
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
            className={classes.textfield}
          />
          {/* <TextField
            label={t('form.phone')}
            type="number"
            fullWidth
            {...register('phoneNumber')}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber?.message}
            className={classes.textfield}
          /> */}

          <Controller
            name="phoneNumber" // make sure this matches the name in register
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                label={t('form.phone')}
                type="tel"
                fullWidth
                {...field}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber?.message}
                className={classes.textfield}
                InputProps={{
                  inputComponent: PatternFormat,
                  inputProps: {
                    type: 'tel',
                    format: '+41 ## ### ## ##',
                    mask: '_',
                    onValueChange: handlePhoneChange,
                  },
                }}
              />
            )}
          />

          {/* <FormControl
            className={classes.percentageField}
            fullWidth
            error={!!errors.backPositionPercentage}
          >
            <InputLabel id={t('form.back_percentage')} color="secondary">
              {t('form.back_percentage')}
            </InputLabel>
            <Controller
              name="backPositionPercentage"
              control={control}
              render={({ field }) => (
                <Select
                  IconComponent={KeyboardArrowDownIcon}
                  className={classes.select}
                  {...field}
                  labelId={t('form.back_percentage')}
                  label={t('form.back_percentage')}
                  variant="outlined"
                  color="secondary"
                >
                  {Object.entries(backPercentage).map(([key, value]) => (
                    <MenuItem key={key} value={value == 100 ? 0 : value}>
                      {value}%
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors.backPositionPercentage && (
              <FormHelperText>
                {errors.backPositionPercentage.message}
              </FormHelperText>
            )}
          </FormControl> */}
        </Grid>

        <Grid item lg={5.5} md={12} className={classes.padding}>
          <TextField
            label={t('form.last_name')}
            type="text"
            fullWidth
            {...register('lastName')}
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
            className={classes.textfield}
          />
          <TextField
            label={t('form.mail')}
            fullWidth
            type="email"
            {...register('email')}
            error={!!errors.email}
            helperText={errors.email?.message}
            className={classes.textfield}
          />
          {/* <FormControl
            className={classes.percentageField}
            fullWidth
            error={!!errors.frontPositionPercentage}
          >
            <InputLabel id={t('form.front_percentage')} color="secondary">
              {t('form.front_percentage')}
            </InputLabel>
            <Controller
              name="frontPositionPercentage"
              control={control}
              render={({ field }) => (
                <Select
                  IconComponent={KeyboardArrowDownIcon}
                  className={classes.select}
                  {...field}
                  labelId={t('form.front_percentage')}
                  label={t('form.front_percentage')}
                  variant="outlined"
                  color="secondary"
                >
                  {Object.entries(frontPercentage).map(([key, value]) => (
                    <MenuItem key={key} value={value == 100 ? 0 : value}>
                      {value}%
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors.frontPositionPercentage && (
              <FormHelperText>
                {errors.frontPositionPercentage.message}
              </FormHelperText>
            )}
          </FormControl> */}
        </Grid>

        <Grid item lg={6} md={12} sm={12} xs={12} className={classes.padding}>
          <FormControl
            className={classes.percentageField}
            fullWidth
            error={state ? null : !!errors.tintGlassId}
          >
            <InputLabel id={t('form.car_model')} color="secondary">
              {t('form.car_model')}
            </InputLabel>
            <Controller
              name="tintGlassId"
              control={control}
              render={({ field }) => (
                <Select
                  IconComponent={KeyboardArrowDownIcon}
                  className={classes.select}
                  {...field}
                  labelId={t('form.car_model')}
                  label={t('form.car_model')}
                  variant="outlined"
                  color="secondary"
                  value={
                    field.value ||
                    (lang === 'en' ? state?.model_en : state?.model_fr)
                  }
                  onChange={(event) => {
                    field.onChange(event.target.value)
                  }}
                >
                  {state ? (
                    <MenuItem value={state.tintedGlass.id}>
                      {lang === 'en' ? state?.model_en : state?.model_fr}
                    </MenuItem>
                  ) : (
                    Object.entries(specialCars).map(([key, value]) => (
                      <MenuItem key={key} value={value.id}>
                        {lang === 'en' ? value?.model_en : value?.model_fr}
                      </MenuItem>
                    ))
                  )}
                </Select>
              )}
            />
            {errors.tintGlassId && (
              <FormHelperText>
                {state ? null : errors.tintGlassId.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Information

Information.propTypes = {
  errors: PropTypes.any,
  register: PropTypes.any,
  control: PropTypes.any,
  onErrorsChange: PropTypes.any,
  handleSubmit: PropTypes.any,
  specialCars: PropTypes.any,
  setValue: PropTypes.any,
}
