import React, { forwardRef, useContext } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import checkbox from '../../assets/icons/checkbox.svg'
import Grow from '@mui/material/Grow'
import PropTypes from 'prop-types'
import './styles.css'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { getHomePageUrl } from '../../ts/routing/routingConstants/AppUrls'
import { LocaleContext } from '../../ts/routing/LangRouter'
import { useStyles } from './styles'
/* eslint-disable  no-unused-vars, no-debugger */

const Transition = forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />
})

export default function ResponsiveDialog({
  dataId,
  label,
  content,
  onClose,
  // onConfirm,
}) {
  const { t } = useTranslation()
  const { locale } = useContext(LocaleContext)
  const classes = useStyles()

  const handleClose = () => {
    if (onClose) onClose(dataId)
  }

  return (
    <>
      <Dialog
        sx={{
          height: 'auto !important',
        }}
        open={true}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <div className={classes.label}>
            <img src={checkbox} alt="checkbox" />
            {label}
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.content}>
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0 0 60px',
          }}
        >
          <button className={classes.btn}>
            <Link className={classes.link} to={getHomePageUrl(locale)}>
              {t('dialog.button')}
            </Link>
          </button>
        </DialogActions>
      </Dialog>
    </>
  )
}
ResponsiveDialog.propTypes = {
  content: PropTypes.any,
  dataId: PropTypes.any,
  label: PropTypes.any,
  onClose: PropTypes.any,
  onConfirm: PropTypes.any,
}
