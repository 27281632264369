import * as Yup from "yup";

export const contactSchema = Yup.object().shape({
  name: Yup.string().required("Please enter your name"),
  phoneNumber: Yup.string().required("Please enter your phone number"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter your email"),
  carModel: Yup.string().required("Please enter your car model"),
  message: Yup.string().required("Please enter your message"),
});

export const appointmentSchema = Yup.object().shape({
  firstName: Yup.string().required("Please enter your first name"),
  lastName: Yup.string().required("Please enter your last name"),
  date: Yup.string().required(
    "Appointment already exists or  you cannot select dates on Saturdays and Sundays"
  ),
  time: Yup.string().required("Please select appointment time"),

  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter your email"),
  phoneNumber: Yup.string().required("Please enter your phone number"),
  tintGlassId: Yup.string().required("Please enter your car model"),
});
