import React from "react";
import { Slide } from "react-slideshow-image";
import PropTypes from "prop-types";
import "react-slideshow-image/dist/styles.css";
import "./slider.css";
import Image from "../Image";

const Slider = ({ partners }) => {
  let partnersLength = 3;
  const shouldShowIndicators = partners.length > 3;
  const shouldShowButtons = partners.length > 3;
  if (partners.length === 1) {
    partnersLength = 1;
  } else if (partners.length === 2) {
    partnersLength = 2;
  }
  return (
    <div>
      {partners.length > 0 && (
        <Slide
          slidesToScroll={partnersLength}
          slidesToShow={partnersLength}
          autoplay={true}
          // responsive={responsiveSettings}
          arrows={shouldShowButtons}
          indicators={
            shouldShowIndicators
              ? () => <div className="indicator"></div>
              : null
          }
          scale={1.4}
        >
          {partners.map((partner) => {
            return <Image key={partner} partnerId={partner} />;
          })}
        </Slide>
      )}
    </div>
  );
};

export default Slider;

Slider.propTypes = {
  partners: PropTypes.any,
};
