export const getHomePageUrl = (locale) => `/${locale}/home`;

export const getGalleryPageUrl = (locale) => `/${locale}/gallery`;

export const getTintedGlassPageUrl = (locale) => `/${locale}/tinted-glass`;

export const getAppointmentPageUrl = (locale) =>
  `${getTintedGlassPageUrl(locale)}/appointment`;

export const getRatesPageUrl = (locale) => `/${locale}/rates`;

export const getSpecialCarsPageUrl = (locale) => `/${locale}/specialcar`;

export const getRatesAppointPageUrl = (locale) =>
  `${getRatesPageUrl(locale)}/appointment`;

export const getSpecialCarAppointPageUrl = (locale) =>
  `${getSpecialCarsPageUrl(locale)}/appointment`;

export const getContactPageUrl = (locale) => `/${locale}/contact`;

export const getProtectionPageUrl = (locale) => `/${locale}/ppf-protection`;

export const getBasePageUrl = (locale) => `/${locale}/`;

export const getAboutUsPageUrl = (locale) => `/${locale}/about-us`;

export const getCoveringPageUrl = (locale) => `/${locale}/covering`;
