import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  paper: {
    background: "#FCFCFC!important",
    borderRadius: "4px!important",
    minWidth: "55px!important",
    height: "66px!important",
    // "&.MuiSelect-select": {
    //   padding: "0px!important",
    // },
    "&.MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    // .MuiInputBase-root.MuiOutlinedInput-root.MuiSelect-root {
    //   padding: 0;
    // }

    // "&.MuiSelect-select": {
    //   "&.MuiInputBase-input": {
    //     "&.MuiOutlinedInput-input": {
    //       [theme.breakpoints.down(1024)]: {
    //         padding: "0px!important",
    //       },
    //     },
    //   },
    // },
  },

  select: {
    color: "#E5CC82!important",
    border: "none!important",
    padding: "0px!important",
    paddingRight: "2px!important",
    "& .MuiSelect-icon": {
      color: "#E5CC82", // Set the desired color
    },

    "&.MuiSvgIcon-root": {
      backgrIoundmage: `url('../../../assets/icons/close.svg')!important`,
      backgroundRepeat: "no-repeat !important",
      color: "transparent !important",
    },
    "&:active": {
      color: "#E5CC82!important",
    },

    // '& .MuiSelect-select': {
    //   paddingRight: '0px',
    // },
  },

  mobilePaper: {
    // top: '55%!important',

    background: "#FCFCFC!important",
    borderRadius: "4px!important",
    width: "51px!important",
  },
  menuItem: {
    fontWeight: "400!important",
    fontSize: "14px!important",
    lineHeight: "18px!important",
    border: "none!important",
    // width: "fit-content!important",
    fontFamily: "Jacques Francois!important",
    marginLeft: "5px!important",
    marginRight: "5px!important",
    width: "30px!important",
    margin: "0 auto !important",
    display: "flex !important",
    justifyContent: "center!important",
    textTransform: "uppercase",
    "&::after": {
      content: '" \\276F"',
      color: "black",
      transform: "rotate(90deg)",
      marginLeft: "5px",
    },
  },
  selected: {
    color: "#E5CC82!important",
    borderBottom: "1px solid #E5CC82!important",
    // width: "fit-content",
    fontFamily: "Jacques Francois",
    width: "30px!important",
    margin: "0 auto !important",
    display: "flex !important",
    justifyContent: "center!important",
    textTransform: "uppercase",
    marginLeft: "5px!important",
    marginRight: "5px!important",
    "&::after": {
      content: '" \\276F"',
      transform: "rotate(90deg)",
      color: "#E5CC82",
      marginLeft: "5px",
    },
  },

  navWrapper: {
    margin: "0 auto",
  },
  logo: {
    width: "90px",
    height: "54px",
    cursor: "pointer",
  },
  menuBg: { width: "240px", height: "160px" },
  navigation: {
    fontFamily: "Jacques Francois",
    fontWeight: "400",
    fontSize: "18px",
    color: "#1A1C3A",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
    },
  },

  textDecoration: {
    textDecoration: "none",
    color: "#1A1C3A",
    cursor: "pointer",
    "&:hover": {
      color: "#1A1C3A",
    },
  },
  navItems: {
    textDecoration: "none",
    color: "#FCFCFC",
    fontFamily: "Jacques Francois",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "24px",
    width: "100%",
  },
  close: {
    width: "18.67px",
    height: "18.67px",
    cursor: "pointer",
  },
  tabContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },

  tab: {
    zIndex: "99999!important",
    fontFamily: "Jacques Francois",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "24px",
    color: "#FCFCFC",
    width: "150px",
    // width: '100%',
  },
  option: {
    background: "unset !important",
  },
  active: {
    color: "#E5CC82",
    zIndex: "99999!important",
    fontFamily: "Jacques Francois",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "24px",
    padding: "10px",
  },
  pending: {
    zIndex: "99999!important",
    fontFamily: "Jacques Francois",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "24px",
    padding: "10px",
    color: "#FCFCFC",
  },
  header_container: {
    display: "flex",
    width: "100%",
    textTransform: "capitalize",
    fontFamily: "Jacques Francois",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "24px",
    color: "#FCFCFC",
  },
  menu_wrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
    paddingLeft: "20px",
    paddingTop: "5%",
  },

  // customSelect: {
  //   display: 'block',
  //   width: '100%',
  //   height: '40px',
  //   padding: '10px',
  //   fontSize: '16px',
  //   lineHeight: '1.3',
  //   color: '#555',
  //   backgroundColor: 'red',
  //   position: 'relative',
  //   top: '50px',

  //   '&:focus': {
  //     borderColor: '#66afe9',
  //     outline: 0,
  //     boxShadow:
  //       'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)',
  //   },
  // },

  nativeSelect: {
    "& .MuiSelect-root": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      "& option": {
        padding: "10px 4px",
        //backgroundColor: "rgba(0, 0, 0, 0.08) !important", This had strange DOM effects
        "&::before": {
          content: '"\\2610"',
          width: "1.4em",
          textAlign: "center",
          display: "inline-block",
          fontSize: 24,
        },
      },
      "& option:checked": {
        "&::before": {
          content: '"\\2611"',
          fontSize: 24,
        },
      },
    },
  },
  routes: {
    marginBottom: "4%",
    display: "flex",
    marginLeft: "40px",
    alignItems: "center",
    position: "relative",
    top: "120px",

    [theme.breakpoints.down("lg")]: {
      marginLeft: "30px",
    },
    [theme.breakpoints.down(750)]: {
      marginLeft: "30px",
      marginBottom: "25%",
    },
    // [theme.breakpoints.down(400)]: {
    //   marginLeft: "5px",
    //   marginBottom: "30%",
    // },
  },

  icon: {
    marginLeft: "25px",
    marginRight: "25px",
    [theme.breakpoints.down("lg")]: {
      marginLeft: "10px",
      marginRight: "10px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "5px",
      marginRight: "5px",
    },
  },
}));
