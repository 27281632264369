import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  slide: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },

  textWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    rowGap: "33px",
    width: "40%",
  },

  content: {
    width: "100%",
    height: "100%",
    maxWidth: "400px",
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "28px",
    lineHeight: "33px",
    textAlign: "center",
    color: "#1A1C3A",
    textShadow: "0px 4px 1px rgba(0, 0, 0, 0.25)",
    [theme.breakpoints.down("lg")]: {
      fontSize: "22px",
      lineheight: "26px",
      padding: "10px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      lineHeight: "19px",
      width: "300px",
      paddingLeft: "85px",
      textShadow: "unset",
    },
  },

  carouselWrapper: {
    // maxWidth: '600px',
    padding: "0 20px",
  },

  swiper: {
    width: "100%",
  },
  swiper_img: {
    display: "block",
    objectFit: "unset !important",
    width: "100% !important",

    [theme.breakpoints.down("md")]: {
      display: "block",
      width: "100%",
      height: "unset !important",
      objectFit: "cover",
      marginTop: "150px",
    },
  },

  btn: {
    padding: "18px 48px",
    background: "#FCFCFC",
    border: "1px solid #1A1C3A",
    borderRadius: "2px 20px",
    gap: "10px",
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "21px",
    textTransform: "uppercase",
    color: "#1A1C3A",
    cursor: "pointer",

    "&:hover": {
      backgroundImage: `linear-gradient(90deg, rgba(26, 28, 58, 0.9), rgba(252, 252, 252, 0.8))`,
      // background:
      //   "linear-gradient(109.51deg, #1A1C3A 0.56%, rgba(252, 252, 252, 0) 63.08%)",
    },

    [theme.breakpoints.down("lg")]: {
      padding: "16px 36px",
      fontSize: "14px",
      lineHeight: "17px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "10px 12px",
      fontSize: "16px",
      lineHeight: "19px",
    },
  },

  link: {
    textDecoration: "none",
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "21px",
    textTransform: "uppercase",
    color: "#1A1C3A",
    cursor: "pointer",
    "&:hover": {
      color: "black",
    },
  },
}));
