import React, { useState } from "react";
import "./App.css";
import { Outlet } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryFallback from "./ts/generic/ErrorBoundaryFallback";
import Header from "./ts/containers/header/Header";
import { Footer } from "./components";

const App = () => {
  const [path, setPath] = useState(false);

  return (
    <>
      <ErrorBoundary
        FallbackComponent={ErrorBoundaryFallback}
        onReset={() => {
          //
        }}
      >
        <>
          <Header path={path} setPath={setPath} />
          <Outlet />
          <Footer path={path} setPath={setPath} />
        </>
      </ErrorBoundary>
    </>
  );
};

export default App;
