import React, { useContext, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useStyles } from './styles'
import ImgGallery from './ImgGallery'
import Partners from './Partners'
import CarWindow from './CarWindow'
import { Link } from 'react-router-dom'
import ImageCarousel from '../../components/ImageCarousel'
import { slidesData } from '../../mocks/tintedGlass'
import clsx from 'clsx'
import SpecialCars from './SpecialCars'
import { getAppointmentPageUrl } from '../../ts/routing/routingConstants/AppUrls'
import { LocaleContext } from '../../ts/routing/LangRouter'
import RotateImage from './RotateImage'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

const TintedGlass = () => {
  const classes = useStyles()
  const { locale } = useContext(LocaleContext)
  const { t } = useTranslation()
  const [partnerIds, setPartnerIds] = useState([])
  const [rates, setRates] = useState([])
  const [specialCars, setSpecialCars] = useState([])

  const fetchImageById = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}coverbox/public/getPartnerImagesByImageType`,
        {
          params: {
            imageType: 'TINTED',
          },
        },
      )

      const partners = response.data
      localStorage.setItem('tintedPartners', JSON.stringify(partnerIds))
      setPartnerIds(partners)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchImageById()
  }, [rates])

  return (
    <>
      <Box
        className={classes.background}
        style={{
          marginTop: '150px',
        }}
      >
        <ImageCarousel slides={slidesData} />
        <Box sx={{ marginTop: '50px' }}>
          <Box className={classes.titleWrapper}>
            {t('tinted_glass.customize')}
            <span className={classes.highlight}>{t('tinted_glass.your')}</span>
            {t('tinted_glass.tint')}
          </Box>

          <Box>
            <RotateImage />
          </Box>
        </Box>
        <Box className={classes.btn_box}>
          <Link
            to={getAppointmentPageUrl(locale)}
            className={classes.bookAppointment}
          >
            <button className={classes.button}>
              {t('common.book_appointment')}
            </button>
          </Link>
        </Box>
        <Box>
          <Box className={classes.titleWrapper}>
            {t('tinted_glass.benefits_of')}
            <span className={classes.highlight}>
              {t('tinted_glass.tinted')}
            </span>
            {t('tinted_glass.glass')}
          </Box>
          <Box className={classes.serviceContainer}>
            <Box className={clsx(classes.service_card, classes.first_benefit)}>
              <span className={classes.service_text}>
                {t('tinted_glass.benefits.first_benefit')}
              </span>
            </Box>
            <Box className={clsx(classes.service_card, classes.second_benefit)}>
              <span className={classes.service_text}>
                {t('tinted_glass.benefits.second_benefit')}
              </span>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box className={classes.titleWrapper}>
            {t('common.our')}
            <span className={classes.highlight}>{t('tinted_glass.rates')}</span>
          </Box>
          <CarWindow images={rates} setImages={setRates} />
        </Box>

        <Box>
          <Box className={classes.titleWrapper}>
            {t('tinted_glass.special')}
            <span className={classes.highlight}>{t('tinted_glass.cars')}</span>
          </Box>
          <SpecialCars images={specialCars} setImages={setSpecialCars} />
        </Box>

        <Box>
          <ImgGallery />
        </Box>
        <Box
          sx={{
            background:
              'linear-gradient(190.51deg, #1A1C3A 0.56%,rgba(252, 252, 252, 0)  63.08%)',
          }}
        >
          <Box className={classes.partnersTitle}>
            {t('common.our')}
            <span className={classes.highlight}>{t('common.partners')}</span>
          </Box>
          <Box
            sx={{
              height: '100%',
              width: '100%',
              paddingBottom: '40px',
            }}
          >
            <Partners partners={partnerIds} />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default TintedGlass
