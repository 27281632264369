import React, { useContext, useEffect, useState } from "react";
import { RadioButton, Slider } from "../../components";
import wrapping_1 from "../../assets/images/covering/wrapping_1.png";
import wrapping_2 from "../../assets/images/covering/wrapping_2.png";
import car_blue from "../../assets/images/covering/car_blue.png";
import car_red from "../../assets/images/covering/car_red.png";
import car_black from "../../assets/images/covering/car_black.png";
import car_gray from "../../assets/images/covering/car_gray.png";
import "./styles.css";
import { Box } from "@mui/material";
import { useStyles } from "./styles";
import { Link } from "react-router-dom";
import { getContactPageUrl } from "../../ts/routing/routingConstants/AppUrls";
import { LocaleContext } from "../../ts/routing/LangRouter";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ImgGallery from "./ImgGallery";
/* eslint-disable  no-unused-vars, no-debugger */

const Covering = () => {
  const classes = useStyles();
  const [selected, setSelected] = useState("blue");
  const { locale } = useContext(LocaleContext);
  const [partnerIds, setPartnerIds] = useState([]);

  const { t } = useTranslation();

  const radioChangeHandler = (e) => {
    setSelected(e.target.value);
  };

  const fetchImageById = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}coverbox/public/getPartnerImagesByImageType`,
        {
          params: {
            imageType: "COVERING",
          },
        }
      );

      const partners = response.data;
      localStorage.setItem("coveringPartners", JSON.stringify(partners));
      setPartnerIds(partners);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchImageById();
  }, []);

  return (
    <Box className={classes.container}>
      <Box className={classes.background}>
        <Box className={classes.wrapper}>
          <Box className={classes.textWrapper}>
            {/* <p className={classes.title}>Lorem ipsum dolor</p> */}
            <Box className={classes.content}>
              {t("covering.content")}
              {/* Lorem Lorem ipsum dolor Lorem ipsum dolor */}
            </Box>
          </Box>
          <img src={wrapping_1} alt="" className={classes.wrapping_1} />
        </Box>

        <Box className={classes.titleWrapper}>
          {t("covering.full")}
          {/* Full */}
          <span className={classes.highlight}>
            {t("covering.wrapping")}
            {/* Wrapping */}
          </span>
        </Box>

        <Box className={classes.full_wrapping_container}>
          <img src={wrapping_2} alt="" className={classes.wrapping_2} />

          <Box className={classes.textWrapper}>
            <Box className={classes.full_wrapping}>
              {t("covering.full_wrapping_text")}
              {/* Virtual invisibility is just one of the many benefits of this
              durable body protection film. */}
            </Box>

            <Link to={getContactPageUrl(locale)} className={classes.contact}>
              <button className={classes.btn}>Contact</button>
            </Link>
          </Box>
        </Box>
      </Box>

      <Box className={classes.margin}>
        <Box className={classes.titleWrapper}>
          {t("covering.what")}
          {/* What */}
          <span className={classes.highlight}>
            {t("covering.color")}
            {/* color */}
          </span>
          {t("covering.do_you_want")}
          {/* do you want? */}
        </Box>
        <Box className={classes.car_container}>
          <img
            src={
              selected === "blue"
                ? car_blue
                : selected === "red"
                ? car_red
                : selected === "black"
                ? car_black
                : car_gray
            }
            alt=""
            className={classes.car}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
            }}
          >
            <label className="checkbox-circle-blue">
              <RadioButton
                changed={radioChangeHandler}
                id="checkbox-circle-blue"
                isSelected={selected === "blue"}
                value="blue"
              />
            </label>
            <label className="checkbox-circle-red">
              <RadioButton
                changed={radioChangeHandler}
                id="checkbox-circle-red"
                isSelected={selected === "red"}
                value="red"
              />
            </label>
            <label className="checkbox-circle-black">
              <RadioButton
                changed={radioChangeHandler}
                id="checkbox-circle-black"
                isSelected={selected === "black"}
                value="black"
              />
            </label>
            <label className="checkbox-circle-grey">
              <RadioButton
                changed={radioChangeHandler}
                id="checkbox-circle-grey"
                isSelected={selected === "grey"}
                value="grey"
              />
            </label>
            <Box className={classes.moreColors}>+499</Box>
          </Box>
        </Box>
      </Box>
      <ImgGallery />
      {partnerIds.length > 0 && (
        <Box className={classes.partners_container}>
          <Box className={classes.titleWrapper}>
            {t("common.our")}
            <span className={classes.highlight}>{t("common.partners")}</span>
          </Box>
          <Slider partners={partnerIds} />
        </Box>
      )}
    </Box>
  );
};

export default Covering;
