import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const RestrictedWrapper = ({
  requiredPermissions,
  children,
  notPermittedComponent,
}) => {
  const userPermissionsList = useSelector((state) => state)

  if (Array.isArray(requiredPermissions)) {
    for (let i = 0; i < requiredPermissions.length; i++) {
      for (let j = 0; j < userPermissionsList.length; j++) {
        if (requiredPermissions[i] === userPermissionsList[j])
          return <>{children}</>
      }
    }
  }
  if (typeof requiredPermissions === 'string') {
    if (
      userPermissionsList.findIndex(
        (permission) => permission === requiredPermissions,
      ) > -1
    )
      return <>{children}</>
  }
  return <>{notPermittedComponent}</>
}

export default RestrictedWrapper

RestrictedWrapper.propTypes = {
  requiredPermissions: PropTypes.any,
  children: PropTypes.any,
  notPermittedComponent: PropTypes.any,
}
