import mask_1 from '../assets/images/gallery/mask_1.png'
import mask_2 from '../assets/images/gallery/mask_2.png'
import mask_3 from '../assets/images/gallery/mask_3.png'
import mask_4 from '../assets/images/gallery/mask_4.png'
import mask_5 from '../assets/images/gallery/mask_5.png'
import mask_6 from '../assets/images/gallery/mask_6.png'
import slide_1 from '../assets/images/gallery/slide_1.png'
import slide_2 from '../assets/images/gallery/slide_2.png'
import slide_3 from '../assets/images/gallery/slide_3.png'

export const GALLERY_DATA = [
  {
    id: 1,
    image: mask_6,
  },
  {
    id: 2,
    image: mask_1,
  },
  {
    id: 3,
    image: mask_2,
  },
  {
    id: 4,
    image: mask_3,
  },

  {
    id: 5,
    image: mask_4,
  },
  {
    id: 6,
    image: mask_5,
  },
]

export const slidesData = [
  {
    id: '1',
    alt: 'slide_1',
    image: slide_1,
    title: 'Gallery',
  },
  {
    id: '2',
    alt: 'slide_2',
    image: slide_2,
    title: 'Gallery',
  },
  {
    id: '3',
    alt: 'slide_3',
    image: slide_3,
    title: 'Gallery',
  },
]
