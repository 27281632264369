import { makeStyles } from '@mui/styles'
import footer from '../../assets/images/footer.svg'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '280px',
    [theme.breakpoints.down('md')]: {
      height: 'unset',
    },
  },
  navbar: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: '0 auto',
    [theme.breakpoints.down('lg')]: {
      padding: '0 30px 0',
    },
  },

  link_wrapper: {
    fontFamily: 'Jacques Francois',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '21px',
    color: '#FCFCFC',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '800px',
    display: 'flex',
    flexShrink: 0,
    overflowX: 'auto !important',
    overflowY: 'hidden !important',
    height: '100%',
    justifyContent: 'space-between',

    [theme.breakpoints.down('md')]: {
      padding: '20px 0 30px',
      justifyContent: 'center',
      columnGap: '8px',
    },

    [theme.breakpoints.down(600)]: {
      rowGap: '20px',
      columnGap: '15px',
      maxWidth: '440px',
    },
  },

  background: {
    backgroundImage: `url(${footer})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },

  contact: {
    display: 'flex',
    justifyContent: 'space-around',
    alignContent: 'center',
    fontFamily: 'Rubik',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#FCFCFC',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      flexWrap: 'wrap',
      width: '100%',
      rowGap: '11px',
      marginBottom: '20px',
    },
  },
  paddingRight: {
    paddingRight: '48px',
    [theme.breakpoints.down('md')]: {
      paddingRight: 'unset',
    },
  },

  icon_wrapper: {
    width: '62px',
    margin: '0 auto',
  },

  facebook: {
    float: 'left',
    color: 'white',
    marginTop: '20px',
    '&:hover': {
      color: '#E5CC82!important',
    },
  },
  instagram: {
    float: 'right',
    color: 'white',
    marginTop: '20px',
    '&:hover': {
      color: '#E5CC82!important',
    },
  },
  navItems: {
    textDecoration: 'none',
    color: '#FCFCFC',
    fontFamily: 'Jacques Francois',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '24px',
    width: '100%',
    '&:active': {
      color: '#E5CC82!important',
    },
  },

  link: {
    position: 'relative',
    color: '#fcfcfc !important',
    textDecoration: 'none',
    padding: '5px 0',
    // marginLeft: '12px',
    // marginRight: '12px',
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '1px',
      backgroundColor: 'white',
      transform: 'scaleX(1)',
      transition: 'transform 0.2s ease-in-out',
    },
    [theme.breakpoints.down(540)]: {
      padding: '0',
      rowGap: '8px',
    },
  },
  active_link: {
    position: 'relative',
    textDecoration: 'none',
    color: '#e5cc82 !important',
    padding: '5px 0',
    // marginLeft: '12px',
    // marginRight: '12px',
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '1px',
      backgroundColor: '#e5cc82',
      transform: 'scaleX(1)',
      transition: 'transform 0.2s ease-in-out',
    },
    '&:hover::after, &.active::after, &:focus::after': {
      transform: 'scaleX(1)',
    },
    [theme.breakpoints.down(540)]: {
      padding: '0',
      marginLeft: '0',
      marginRight: '0',
      rowGap: '8px',
    },
  },
}))
