import { makeStyles } from "@mui/styles";
import background from "../../assets/images/background/protection.png";

export const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    fontFamily: "Rubik",
    fontWeight: "800",
    fontSize: "32px",
    lineHeight: "38px",
    color: "#1A1C3A",
    margin: "112px 0  50px 0",
    textAlign: "center",

    [theme.breakpoints.down(1280)]: {
      // marginTop: '0px',
      marginBottom: "50px",
      fontweight: "700",
      fontSize: "28px",
      lineHeight: "33px",
      margin: "40px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
      lineHeight: "26px",
    },
  },
  titleWrap: {
    fontFamily: "Rubik",
    fontWeight: "800",
    fontSize: "32px",
    lineHeight: "38px",
    color: "#1A1C3A",
    textAlign: "center",

    [theme.breakpoints.down("lg")]: {
      fontweight: "700",
      fontSize: "28px",
      lineHeight: "33px",
      // margin: '40px',
    },
    [theme.breakpoints.down("md")]: {
      // margin: '40px 0 15.95px 0',
      fontWeight: "700",
      fontSize: "22px",
      lineHeight: "26px",
    },
  },
  margin: {
    paddingTop: "100px",
    [theme.breakpoints.down("lg")]: {
      paddingTop: "unset",
      // marginBottom: "40px",
    },
    [theme.breakpoints.down(1300)]: {
      paddingTop: "60px",
      // marginBottom: "40px",
    },
  },

  highlight: {
    width: "163px",
    // height: '62px',
    background: "#E5CC82",
    borderRadius: "4px",
    padding: "12px",
    gap: "10px",
    marginLeft: "10px",
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "28px",
    lineHeight: "33px",
    color: "#FCFCFC",
    "&:hover": {
      color: "#fff6f6de",
    },
    [theme.breakpoints.down("lg")]: {
      fontweight: "700",
      fontSize: "28px",
      lineHeight: "33px",
    },
    [theme.breakpoints.down("md")]: {
      width: "70px",
      height: "42px",
      padding: "8px",
      fontWeight: "700",
      fontSize: "22px",
      lineHeight: "26px",
    },
  },
  invisible: {
    width: "110px",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90px",
    },
  },
  ultimate: {
    width: "142px",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      width: "129px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "116px",
    },
  },
  quality: {
    width: "97px",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      width: "88px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "79px",
    },
  },
  printer: {
    width: "100%",
    maxWidth: "300px",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "280px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "230px",
    },
  },
  textWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    rowGap: "33px",
    // width: '45%',
    [theme.breakpoints.down("md")]: {
      rowGap: "20px",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      rowGap: "15px",
      width: "100%",
    },
  },
  content: {
    width: "100%",
    height: "100%",
    maxWidth: "370px",
    fontFamily: "Rubik",
    fontWeight: "400",
    fontSize: "20px",
    textAlign: "start",
    color: "#1A1C3A",
    lineHeight: "24px",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
      lineheight: "23.7px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "21.33px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  value_container: {
    height: "100%",
    width: "100%",
    marginBottom: "40px",
    // [theme.breakpoints.down('md')]: {
    //   marginTop: '50px',
    // },
  },
  protective_content: {
    width: "100%",
    height: "100%",
    maxWidth: "382px",
    fontFamily: "Rubik",
    fontWeight: "400",
    fontSize: "20px",
    textAlign: "center",
    color: "#1A1C3A",
    lineHeight: "24px",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
      lineheight: "23.7px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "21.33px",
    },

    [theme.breakpoints.down(1080)]: {
      width: "100%",
      maxWidth: "302px",
    },
  },

  btn: {
    padding: "18px 48px",
    background: "#FCFCFC",
    border: "1px solid #1A1C3A",
    borderRadius: "2px 20px",
    gap: "10px",
    cursor: "pointer",
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "21px",
    textTransform: "uppercase",
    color: "#1A1C3A",
    "&:hover": {
      backgroundImage: `linear-gradient(90deg, rgba(26, 28, 58, 0.9), rgba(252, 252, 252, 0.8))`,
    },

    [theme.breakpoints.down("lg")]: {
      padding: "16px 36px",
      fontSize: "14px",
      lineHeight: "17px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "10px 12px",
      fontSize: "16px",
      lineHeight: "19px",
    },
  },

  contact: {
    textDecoration: "none",
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "21px",
    textTransform: "uppercase",
    color: "#1A1C3A",
    cursor: "pointer",
    "&:hover": {
      color: "black",
    },
  },

  protective_img1: {
    width: "100%",
    maxWidth: "801px",
    height: "376pxpx",

    [theme.breakpoints.down("sm")]: {
      maxWidth: "322px",
      height: "138.57px",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "322px",
      height: "165.51px",
    },
    [theme.breakpoints.down(1080)]: {
      width: "100%",
      maxWidth: "322px",
    },
  },

  protective_img2: {
    width: "100%",
    maxWidth: "800px",
    height: "426px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "322px",
      height: "172px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "322px",
      height: "144px",
      width: "100%",
    },

    [theme.breakpoints.down(1080)]: {
      width: "100%",
      maxWidth: "322px",
    },
  },

  wrapper_first: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  second_wrapper: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
    },
  },
  computer_cut: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "100px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  xpel: {
    width: "205px",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "150px",
      width: "100%",
    },
    [theme.breakpoints.down(500)]: {
      maxWidth: "90px",
      width: "100%",
    },
  },
  hexis: {
    width: "190px",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "150px",
      width: "100%",
    },
    [theme.breakpoints.down(500)]: {
      maxWidth: "83.41px",
      width: "100%",
    },
  },
  dyno: {
    width: "205px",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "150px",
      width: "100%",
    },
    [theme.breakpoints.down(500)]: {
      maxWidth: "90px",
      width: "100%",
    },
  },
  computer_content: {
    maxWidth: "640px",
    width: "100%",
    alignItems: "center",
    display: "flex",
    padding: "20px",
    textAlign: "left",

    [theme.breakpoints.down("md")]: { fontSize: "15px" },
  },

  background: {
    backgroundImage: `url(${background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 2350px",
    backgroundPositionY: "-120px",
    [theme.breakpoints.down(1200)]: {
      backgroundSize: "100% 2000px",
      backgroundPositionY: "-94px",
    },

    [theme.breakpoints.down(600)]: {
      backgroundSize: "100% 2200px",
      backgroundPositionY: "-94px",
    },
  },
  protectionPartners: {
    height: "100%",
    width: "100%",
    paddingBottom: "115px",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "60px",
    },
  },
}));
