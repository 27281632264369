import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    background:
      "linear-gradient(179.51deg, #1A1C3A -30%, rgba(252, 252, 252, 0) 40%)",
  },
  titleWrapper: {
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "47px",
    color: "#1A1C3A",
    textAlign: "center",
    // background: 'rgba(252, 252, 252, 0.3)',
    height: "100%",
    padding: "16px",
    borderRadius: "4px",
    width: "fit-content",
    margin: "0 auto",

    [theme.breakpoints.down("lg")]: {
      // margin: '30px !important',
      fontSize: "28px",
      // lineHeight: '33px',
    },
    [theme.breakpoints.down("md")]: {
      // margin: '30px !important',
      fontSize: "22px",
      // lineHeight: '26px',
    },
  },
  highlight: {
    width: "163px",
    // height: '62px',
    background: "#E5CC82",
    borderRadius: "4px",
    padding: "12px",
    gap: "10px",
    marginLeft: "20px",
    marginRight: "10px",
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "28px",
    lineHeight: "38px",
    color: "#FCFCFC",
    "&:hover": {
      color: "#fff6f6de",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "28px",
      lineHeight: "33px",
      width: "123px",
      height: "57px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
      lineHeight: "26px",
      width: "94px",
      height: "42px",
      padding: "8px",
    },
  },
  seeMore: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "18px 48px",
    gap: "10px",
    background: "#FCFCFC",
    border: "1px solid #1A1C3A",
    borderRadius: "2px 20px",
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "21px",
    textTransform: "uppercase",
    textDecoration: "none",
    color: "#1A1C3A",

    "&:hover": {
      backgroundImage: `linear-gradient(90deg, rgba(26, 28, 58, 0.9), rgba(252, 252, 252, 0.8))`,
    },

    [theme.breakpoints.down("lg")]: {
      padding: "16px 36px",
      fontSize: "16px",
      lineHeight: "19px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "14px 24px",
      fontSize: "14px",
      lineHeight: "17px",
    },
  },
  margin: {
    marginBottom: "90px",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "4%",
    },
    [theme.breakpoints.down(900)]: {
      marginBottom: "5%",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0",
    },
  },
  galleryWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    height: "100%",
    flexWrap: "wrap",
    justifyContent: "center",
    marginTop: "50px",
    //new
    marginBottom: "80px",
    [theme.breakpoints.down("lg")]: {
      marginTop: "20px",
    },
  },
  image: {
    width: "50%",
    // maxWidth: "720px",
    // maxWidth: "720px",
    // maxHeight: "740px",
  },
  btnWrapper: {
    // margin: "60px  0 100px",
    //new
    margin: "60px  0 0px",
    color: "#1A1C3A",

    [theme.breakpoints.down("lg")]: {
      margin: "20px  0 0px",
    },
  },
}));
