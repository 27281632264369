import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    marginTop: '20%',
    marginBottom: '5%',
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down('md')]: { marginTop: '20%', marginBottom: '7%' },
    [theme.breakpoints.down(600)]: { marginTop: '25%' },
  },
  title: {
    margin: '30px',
    fontFamily: 'Rubik',
    fontWeight: '700',
    fontSize: '22px',
    lineHeight: '26px',
    color: '#E5CC82',

    [theme.breakpoints.down('lg')]: {
      fontsize: '20px',
      lineHeight: '24px',
      margin: '5%',
    },
    [theme.breakpoints.down('md')]: {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '19px',
      paddingLeft: '40px',
      margin: '0',
    },
  },

  profile: {
    position: 'absolute',
    left: '8%',
    top: '-33%',
    maxWidth: '170px',
    maxHeight: '170px',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('lg')]: {
      left: '20%',
      top: '-30%',
      maxWidth: '100px',
      maxHeight: '100px',
    },
    [theme.breakpoints.down(1100)]: {
      left: '5%',
    },

    [theme.breakpoints.down('md')]: {
      maxWidth: '70px',
      maxHeight: '70px',
      left: '10%',
      top: '-20%',
    },
    [theme.breakpoints.down(540)]: {
      left: '0%',
      top: '-30%',
    },
  },
  card: {
    width: '318px',
    height: '240px',
    background: '#ffffff',
    border: ' 1px solid #e5cc82',
    borderRadius: '2px 20px',
    overflow: 'auto',
    [theme.breakpoints.down('lg')]: { maxWidth: '265px', maxHeight: '146px' },
    [theme.breakpoints.down('md')]: { maxWidth: '130px', maxHeight: '100px' },
  },

  review: {
    fontFamily: 'Rubik',
    fontWeight: '400',
    fontSize: '24px',
    lineHeight: '28px',
    padding: '5px',
    '&::before': {
      content: '"“ "',
      color: '#E5CC82',
    },

    '&::after': {
      content: '" ”"',
      color: '#E5CC82',
    },
    [theme.breakpoints.down(600)]: {
      fontWeight: '300',
      fontSize: '14px',
      lineHeight: '17px',
      // margin: '0',
      padding: '10px 12px',
    },
  },
}))
