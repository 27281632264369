import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  navWrapper: {
    margin: '0 auto',
  },
  logo: {
    width: '90px',
    height: '54px',
  },
  menuBg: { width: '240px', height: '160px' },
  navItems: {
    textDecoration: 'none',
    color: '#FCFCFC',
    fontFamily: 'Jacques Francois',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '24px',
  },
  close: {
    width: '18.67px',
    height: '18.67px',
    cursor: 'pointer',
  },
  tabContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  tabs: {
    width: '100%',
    borderColor: '#FCFCFC',
    '& .MuiTabs-indicator': {
      backgroundColor: '#E5CC82',
    },
    '& .MuiTab-root': {
      textTransform: 'capitalize',
      fontFamily: 'Jacques Francois',
      fontWeight: '400',
      fontSize: '18px',
      lineHeight: '24px',
      color: '#FCFCFC',
    },
    '& .MuiTab-root.Mui-selected': {
      color: '#E5CC82',
      textTransform: 'capitalize',
      zIndex: '999!important',
    },
    '& .MuiTabs-scroller': {
      marginBottom: '-1px!important',
      zIndex: '999!important',
    },
  },
  tab: {
    zIndex: '99999!important',
    fontFamily: 'Jacques Francois',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '24px',
    color: '#FCFCFC',
    width: '150px',
    // width: '100%',
  },
}))
