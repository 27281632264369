import LanguageDetector from 'i18next-browser-languagedetector'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LocalStorageManager from './ts/managers/LocalStorageManger'
import { LocalStorageKeys } from './ts/constants/Constants'
import translationEn from './translations/en.json'
import translationFr from './translations/fr.json'

const resources = {
  en: {
    translation: translationEn,
  },
  fr: {
    translation: translationFr,
  },
}

const languageDetector = new LanguageDetector()
languageDetector.addDetector({
  name: 'customLocalStorageDetector',

  lookup() {
    return LocalStorageManager.getItem(LocalStorageKeys.I18_NEXT_LNG)
  },
})

i18n
  .use(languageDetector)
  .use(initReactI18next) 
  .init({
    supportedLngs: ['fr', 'fr-FR', 'en', 'en-US'],
    fallbackLng: 'en',
    debug: false,
    interpolation: { escapeValue: false },
    resources,
    detection: {
      order: ['customLocalStorageDetector', 'navigator'],
      caches: [],
    },


    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: false,
    },
  })

export default i18n
