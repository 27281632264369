import React from 'react'
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa'
import { useContext } from 'react'
import { Context } from '../../container/DatePicker'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function YearNav() {
  let { date, setDate } = useContext(Context)

  function getYearFromDate(date) {
    return new Date(date).getFullYear()
  }

  let getYear = getYearFromDate(date.dateTime)

  function handleClick(e) {
    e.stopPropagation()
    let year = date.isYearsList ? false : true

    return setDate({
      dateTime: date.dateTime,
      isYearsList: date.isYearsList ? false : true,
      isMonthsList: false,
      isDaysList: date.isMonthsList == false && year == false ? true : false,
    })
  }

  function setYear(e, param) {
    e.stopPropagation()

    let getNextYear =
      param == 'prev'
        ? date.dateTime.getFullYear() - 1
        : date.dateTime.getFullYear() + 1

    return setDate({
      dateTime: new Date(date.dateTime.setFullYear(getNextYear)),
      isYearsList: false,
      isMonthsList: false,
      isDaysList: true,
    })
  }

  const style = {
    transform: date.isYearsList ? 'rotate(180deg)' : '',
    transition: 'transform 150ms ease', // smooth transition
  }

  return (
    <div className="navWrapper">
      <FaChevronLeft onClick={(e) => setYear(e, 'prev')} />
      <div className="navValue" onClick={handleClick}>
        <span>{getYear}</span>
        <div className="arrow" style={style}>
          <ArrowDropDownIcon />
        </div>
      </div>
      <FaChevronRight onClick={(e) => setYear(e, 'next')} />
    </div>
  )
}

export default YearNav
