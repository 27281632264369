import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    height: "100%",
    [theme.breakpoints.down(900)]: {
      justifyContent: "space-evenly",
      padding: "0 50px",
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-evenly",
      padding: "0",
    },
    [theme.breakpoints.down(500)]: {
      rowGap: "17px",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "0",
    },
  },
  profileWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  wrapper: {
    width: "100%",
    maxWidth: "50%",
    height: "100%",
    textAlign: "left",
    fontFamily: "Rubik",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.005em",
    color: "#1A1C3A",
    alignSelf: "center",
    [theme.breakpoints.down("920")]: {
      width: "100%",
      maxWidth: "424px",
      fontSize: "18px",
      lineHeight: "21px",
      textAlign: "left",
      padding: "0 20px",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "424px",
      fontSize: "18px",
      lineHeight: "21px",
      textAlign: "left",
      padding: "0 20px",
    },
    // [theme.breakpoints.down("md")]: {
    //   width: "100%",
    //   maxWidth: "424px",
    //   fontSize: "16px",
    //   lineHeight: "19px",
    //   textAlign: "left",
    //   padding: "20px",
    // },
  },
  profile: {
    width: "400px",
    height: "400px",
    [theme.breakpoints.down(822)]: {
      width: "200px",
      height: "200px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "141px",
      height: "141px",
    },
  },
  name: {
    textAlign: "center",
    fontFamily: "Rubik",
    fontWeight: "700",
    marginTop: "18px",
    fontSize: "32px",
    color: "#000000",

    [theme.breakpoints.down("900")]: {
      fontSize: "24px",
      lineHeight: "28.44px",
      width: "139px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "127px",
      fontSize: "22px",
      lineHeight: "26.07px",
    },
  },
  titleWrapper: {
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "38px",
    color: "#1A1C3A",
    textAlign: "start",
    [theme.breakpoints.down(1000)]: {
      marginBottom: "5%",
      textAlign: "start",
    },

    [theme.breakpoints.down("md")]: {
      fontSize: "28px",
      textAlign: "start",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
      marginBottom: "5%",
    },
  },
  highlight: {
    width: "163px",
    // height: '62px',
    background: "#E5CC82",
    borderRadius: "4px",
    padding: "12px",
    marginLeft: "20px",
    marginRight: "10px",
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "28px",
    lineHeight: "33px",
    color: "#FCFCFC",
    "&:hover": {
      color: "#fff6f6de",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "70px",
      fontSize: "22px",
    },
  },
  profile_container: {
    maxWidth: "400px",
    maxHeight: "400px",
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    border: "18px solid rgba(26, 28, 58, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(822)]: {
      maxWidth: "200px",
      maxHeight: "200px",
      border: "12px solid rgba(26, 28, 58, 0.7)",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "141px",
      maxHeight: "141px",
      border: "10px solid rgba(26, 28, 58, 0.7)",
    },
  },
  image: {
    maxWidth: "400px",
    maxHeight: "400px",
    width: "100%",
    height: "100%",
    position: "relative",
    borderRadius: "50%",
    [theme.breakpoints.down(822)]: {
      maxWidth: "200px",
      maxHeight: "200px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "141px",
      maxHeight: "141px",
    },
  },
}));
