import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  btn: {
    padding: "18px",
    background: "#FCFCFC",
    border: "1px solid #1A1C3A",
    borderRadius: "2px 20px",
    gap: "10px",
    "&:hover": {
      backgroundImage: `linear-gradient(90deg, rgba(26, 28, 58, 0.9), rgba(252, 252, 252, 0.8))`,
      // background:
      //   "linear-gradient(109.51deg, #1A1C3A 0.56%, rgba(252, 252, 252, 0) 63.08%)",
    },

    [theme.breakpoints.down("lg")]: {
      padding: "16px",
      fontSize: "14px",
      lineHeight: "17px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "10px",
      fontSize: "16px",
      lineHeight: "19px",
    },
  },

  link: {
    textDecoration: "none",
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "21px",
    textTransform: "uppercase",
    color: "#1A1C3A",
    "&:hover": {
      color: "black",
    },
  },
  content: {
    fontFamily: "Rubik",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "24px",
    textAlign: "center",
    color: " #1A1C3A",
    padding: "0 260px",
    [theme.breakpoints.down("lg")]: {
      padding: "0",
    },
  },
  label: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "60px 24px 0",
  },
}));
