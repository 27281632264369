import slide_1 from '../assets/images/protection/slide_1.svg'
import slide_2 from '../assets/images/protection/slide_2.png'
import slide_3 from '../assets/images/protection/slide_3.png'

export const slidesData = [
  {
    id: '1',
    alt: 'slide_1',
    image: slide_1,
    title: 'PPF Film',
  },
  {
    id: '2',
    alt: 'slide_2',
    image: slide_2,
    title: 'PPF Film',
  },
  {
    id: '3',
    alt: 'slide_3',
    image: slide_3,
    title: 'PPF Film',
  },
]
