import { handleActions } from "redux-actions";
import { initialState } from "../initialState";
import { UPDATE_DATE, UPDATE_TIME } from "./actionType";
/* eslint-disable  no-unused-vars, no-debugger */

const initial = initialState.datepicker;

const reducer = handleActions(
  {
    [UPDATE_DATE]: (state, { payload }) => {
      return {
        ...state,
        date: payload.dateTime,
      };
    },
    [UPDATE_TIME]: (state, { payload }) => {
      return {
        ...state,
        time: payload,
      };
    },
  },

  initial
);

export default reducer;
