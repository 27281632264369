import React from 'react'
import { useContext } from "react";
import { Context } from "../../container/DatePicker";

function MonthPicker() {
    const cal_months = ['Jan', 'Feb', 'Mar', 'Apr',
        'May', 'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec']

    let { date, setDate } = useContext(Context);

    const selectedMonth = (e, month) => {

        return setDate(
            {
                dateTime: new Date(date.dateTime.setMonth(month)),
                isDaysList: true,
                isMonthsList: false,
                isYearsList: false
            }
        );
    }
    return (
        <div className="month-grid-container">
            {
                cal_months.map((month, i) => {
                    return <span className="month-grid-item" key={month}
                        style={{
                            'background': month == cal_months[date.dateTime.getMonth()] ? '#1A1C3A' : '',
                            'color': month == cal_months[date.dateTime.getMonth()] ? '#FCFCFC' : '#1A1C3A'
                        }}

                        onClick={((e) => selectedMonth(e, i))}>{month}</span>
                })
            }
        </div>
    );
}

export default MonthPicker;