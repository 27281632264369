import React, { useState, createContext } from "react";
import PropTypes from "prop-types";
import DayPicker from "../calendar/daypicker/DayPicker";
import MonthNav from "../calendar/monthpicker/MonthNav";
import MonthPicker from "../calendar/monthpicker/MonthPicker";
import YearNav from "../calendar/yearpicker/YearNav";
import YearPicker from "../calendar/yearpicker/YearPicker";

export const Context = createContext({
  dateTime: new Date(),
  isDaysList: true,
  isMonthsList: false,
  isYearsList: false,
});

function DatePicker({ handleShowDate, setValue }) {
  let [date, setDate] = useState({
    dateTime: new Date(),
    isDaysList: true,
    isMonthsList: false,
    isYearsList: false,
  });

  function renderListComponent() {
    if (date.isDaysList) {
      return <DayPicker />;
    }
    if (date.isMonthsList) {
      return <MonthPicker />;
    }
    if (date.isYearsList) {
      return <YearPicker />;
    }
  }

  function submit() {
    setValue("date", date.dateTime);
    handleShowDate();
  }
  function cancel() {
    handleShowDate();
  }
  return (
    <Context.Provider value={{ date, setDate }}>
      <div className="datePicker-container">
        <div className="navContainer">
          <MonthNav />
          <YearNav />
        </div>
        <div>{renderListComponent(date)}</div>

        <div className="actions">
          <div className="btnCancel" onClick={cancel}>
            CANCEL
          </div>
          <div className="btnOK" onClick={submit}>
            OK
          </div>
        </div>
      </div>
    </Context.Provider>
  );
}

export default DatePicker;
DatePicker.propTypes = {
  handleShowDate: PropTypes.any,
  setValue: PropTypes.func,
};
