import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
  },
  cars: {
    width: '200px',

    // Height
    // 200px
    height: '100%',
    // [theme.breakpoints.down('md')]: {
    //   width: '150px',
    //   // height: '59.38px',
    // },
    [theme.breakpoints.down('md')]: {
      width: '90px',
      // height: '35.62px',
    },
  },
  clients: {
    // width: '180.02px',
    width: '200px',
    height: '100%',
    // [theme.breakpoints.down('md')]: {
    //   width: '150px',
    // },
    [theme.breakpoints.down('md')]: {
      width: '85px',
    },
  },
  experience: {
    width: '170px',
    height: '100%',
    // [theme.breakpoints.down('md')]: {
    //   width: '131px',
    // },
    [theme.breakpoints.down('md')]: {
      width: '80px',
    },
  },
}))
