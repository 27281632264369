export default {
  primary: {
    main: '#F5F8FA',
    dark: '#061D3A',
  },
  secondary: {
    main: '#42A5F5',
  },

  custom: {
    black: '#000000',
    white: '#FFFFFF',
    gray: 'rgb(235, 235, 235)',
  },
}
