import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
    padding: "35px 0 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  image: {
    width: "135px",
    height: "100%",
    objectFit: "cover",

    [theme.breakpoints.down("md")]: {
      width: "100px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "91px",
    },
  },
}));
