import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "16px",
    marginTop: "auto",
  },
  container: {
    width: "940px",
    background: "#FCFCFC",
    height: "100%",
    padding: "0 120px 20px",

    [theme.breakpoints.down("lg")]: {
      width: "663px",
      padding: "20px",
    },
    [theme.breakpoints.down("md")]: {
      width: "364px",
      padding: "20px",
    },
  },
  button_box: {
    justifyContent: "center",
    display: "flex",
    width: "100%",
    alignItems: "center",
    marginTop: "40px",
    // maxWidth: '179px',
  },
  btn: {
    padding: "18px",
    width: "179px",
    background: "#FCFCFC",
    border: "1px solid #1A1C3A",
    borderRadius: "2px 20px",
    fontFamily: "Rubik",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "21px",
    textTransform: "uppercase",
    color: "#1A1C3A",
    [theme.breakpoints.down("lg")]: {
      fontSize: "18px",
      lineHeight: "21px",
      padding: "18px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      lineHeight: "17px",
      padding: "14px",
      width: "113px",
    },
  },
  wrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  bold_title: {
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "21px",
    textTransform: "uppercase",
    width: "max-content",
    [theme.breakpoints.down("lg")]: {
      fontSize: "18px",
      lineHeight: "21px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      lineHeight: "19px",
    },
  },
  flex: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  additionalServices: {
    width: "240px",
    whiteSpace: "pre-line",
  },
  left: {
    marginLeft: "8px",
    fontWeight: "400",
    [theme.breakpoints.down("lg")]: {
      fontSize: "18px",
      lineHeight: "21px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      lineHeight: "19px",
    },
  },
  service_wrapper: {
    // width: '100%',
    [theme.breakpoints.down("md")]: {
      maxWidth: "220px",
      width: "100%",
    },
  },
  padding: {
    padding: "50px",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  error: {
    color: "red ",
  },
}));
