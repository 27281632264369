import { createTheme } from '@mui/material/styles'
import palette from './palette'
import typography from './typography'
import components from './components'

export const globalTheme = createTheme({
  typography,
  palette,
  components,
  breakpoints: {
    values: {
      xs: 0,
      sm: 375,
      md: 744,
      lg: 1440,
      xl: 1920,
    },
  },

  paddings: {},
  margins: {},
})
