// import React from 'react'
// import { Box } from '@mui/material'
// import xpel from '../../assets/images/protection/xpel.svg'
// import hexis from '../../assets/images/protection/hexis.svg'
// import dyno from '../../assets/images/protection/dyno.svg'
// import { useStyles } from './styles'

// const Partners = () => {
//   const classes = useStyles()
//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'space-around',
//         width: '100%',
//       }}
//     >
//       <img src={xpel} alt="xpel" className={classes.xpel} />
//       <img src={hexis} alt="hexis" className={classes.hexis} />
//       <img src={dyno} alt="dyno" className={classes.dyno} />
//     </Box>
//   )
// }
// export default Partners

import React from 'react'
import { Slide } from 'react-slideshow-image'
import PropTypes from 'prop-types'
import 'react-slideshow-image/dist/styles.css'
import './slider.css'
import { Image } from '../../components'

const Partners = ({ partners }) => {
  let partnersLength = 3
  const shouldShowIndicators = partners.length > 3
  const shouldShowButtons = partners.length > 3
  if (partners.length === 1) {
    partnersLength = 1
  } else if (partners.length === 2) {
    partnersLength = 2
  }

  return (
    <div>
      {partners.length > 0 && (
        <Slide
          slidesToScroll={partnersLength}
          slidesToShow={partnersLength}
          autoplay={true}
          // responsive={responsiveSettings}
          arrows={shouldShowButtons}
          indicators={
            shouldShowIndicators
              ? () => <div className="indicator"></div>
              : null
          }
          scale={1.4}
        >
          {partners.map((partner) => {
            return <Image key={partner} partnerId={partner} />
          })}
        </Slide>
      )}
    </div>
  )
}

export default Partners
Partners.propTypes = {
  partners: PropTypes.any,
}
