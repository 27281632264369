// import palette from './palette'

export default {
  MuiButton: {
    styleOverrides: {
      root: {
        // boxShadow: 'none',
        // padding: '8px 16px',
        // background: palette.custom.lightBlue,
        // color: palette.primary.main,
        // fontWeight: 600,
        // lineHeight: '20px',
        // display: 'flex',
        // alignItems: 'center',
        // columnGap: 5,
        // fontSize: 14,
        // '&:hover': {
        //   background: palette.custom.blue,
        // },
      },
    },
  },

  MuiTypography: {
    // styleOverrides: {
    //   root: {
    //     margin: 0,
    //   },
    // },
  },
}
