import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import "./style.css";
import { Box } from "@mui/material";
import { useStyles } from "./styles";
import { Link } from "react-router-dom";
import { LocaleContext } from "../../ts/routing/LangRouter";
import { getContactPageUrl } from "../../ts/routing/routingConstants/AppUrls";
import { useTranslation } from "react-i18next";

const customAnimation = keyframes`
from {
  opacity: 0;
  transform: translate3d(100px,100px,0, 0);
  visibility: hidden;
  transition: visibility 0s linear 300ms, opacity 300ms;
}

to {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  visibility: visible;
  transition: visibility 0s linear 0s, opacity 300ms;
}
`;

function AnimatedCarousel({ data, btn_name }) {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(0);
  const { locale } = useContext(LocaleContext);
  const { t } = useTranslation();

  const contentArray = [
    t("carousel.first"),
    t("carousel.second"),
    t("carousel.third"),
  ];

  useEffect(() => {
    setCurrentIndex(0);
  }, []);

  function handleChange(index) {
    setCurrentIndex(index);
  }

  const renderSlides = data.map((image) => (
    <div key={image.id} className="elementToFadeInAndOut">
      <Reveal keyframes={customAnimation}>
        <img
          src={image.url}
          alt={image.alt}
          className="image"
          style={{ maxHeight: "800px", width: "90%", display: "flex" }}
        />
      </Reveal>
    </div>
  ));

  return (
    <Box className={classes.container}>
      <Box className={classes.textWrapper}>
        <Box className={classes.content}>{contentArray[currentIndex]}</Box>
        <Link to={getContactPageUrl(locale)} className={classes.link}>
          <button className={classes.btn}>{btn_name}</button>
        </Link>
      </Box>
      <Box
        sx={{
          paddingTop: "5%",
          width: "60%",
          height: "100% !important",
        }}
      >
        <Carousel
          showThumbs={false}
          autoPlay={true}
          infiniteLoop
          selectedItem={currentIndex}
          onChange={handleChange}
          className="carousel-container"
        >
          {renderSlides}
        </Carousel>
      </Box>
    </Box>
  );
}

export default AnimatedCarousel;

AnimatedCarousel.propTypes = {
  data: PropTypes.array.isRequired,
  btn_name: PropTypes.string.isRequired,
  // content: PropTypes.array.isRequired,
};
