import React from 'react'
import PropTypes from 'prop-types'
import RestrictedWrapper from './RestrictedWrapper'

const RestrictedSection = ({ requiredPermissions, children }) => (
  <RestrictedWrapper requiredPermissions={requiredPermissions}>
    {children}
  </RestrictedWrapper>
)

export default RestrictedSection

RestrictedSection.propTypes = {
  requiredPermissions: PropTypes.any,
  children: PropTypes.any,
}
