export function calculateYearsList(param, year) {
  let prevBtn = false;
  let nextBtn = false;
  let yearsList = [];

  let currentYear = year;

  generateYear(param);

  function generateYearsList() {
    for (let i = currentYear - 4; i < currentYear; i++) {
      yearsList.push(i);
    }
    for (let i = currentYear; i < currentYear + 5; i++) {
      yearsList.push(i);
    }
    return yearsList;

    // if (yearsList.includes(parseInt(this.datePickerObj.dateSettings['maxYear']))) {
    //     nextBtn = false;
    // }
    // if (yearsList.includes(parseInt(this.datePickerObj.dateSettings['minYear']))) {
    //     prevBtn = false;
    // }
  }

  function generateYear(param) {
    if (param == "next") {
      if (!nextBtn) {
        return;
      } else {
        currentYear = currentYear + 9;
        // if (currentYear >= parseInt(this.datePickerObj.dateSettings['maxYear']) &&
        //     parseInt(this.datePickerObj.dateSettings['maxYear']) > 0) {
        //     return
        // } else {
        //     currentYear = currentYear + 9;
        // }
      }
    } else {
      if (prevBtn) {
        currentYear = currentYear - 9;
      }
    }
    return generateYearsList();
  }

  return yearsList;
}
